import { Selection, drag, select, xml } from "d3";
import { IBtnTypePain } from "../../APPValDiscapacidad/interfaces/Pain";
import { tlistPainType } from "../../APPValDiscapacidad/Model/General";
import { Button } from "antd";
import { RollbackOutlined } from "@ant-design/icons";

var gSideHuman: Selection<SVGGElement, any, any, any>;
var btnColorChose: IBtnTypePain;

function AddDocImg(
    addArrows: boolean, objRefArrowsList: any, ID_IMG: string, isEnabled: boolean, imgSvgPath: string
) {
    const svgSectionFront = select("#svg_body_container" + ID_IMG);
    if (svgSectionFront.select(".img-body-human").size() > 0) {
        return
    }

    const wthParent = 800, hgtParent = 400;
    var imgBodyFront = _AddUseImage(svgSectionFront, imgSvgPath, { w: wthParent, h: hgtParent });///Corregir
    imgBodyFront.style("pointer-events", "all").classed("img-body-human", true);

    gSideHuman = svgSectionFront.select(".g-arrows");
    if (gSideHuman.size() == 0) {
        gSideHuman = svgSectionFront.append("g").classed("g-arrows", true)
    }

    if (isEnabled)
        DrawTouchEventLines(imgBodyFront, objRefArrowsList, ID_IMG);

    // Add arrow
    // defs
    let defsFront = svgSectionFront.select("defs");
    defsFront.selectAll("*").remove();
    AddMarkerArrow(defsFront, addArrows);

    // Add if contains value...
    gSideHuman.selectAll("*").remove();
    objRefArrowsList[ID_IMG].forEach(item => gSideHuman.append(() => item.node()))
}

export function AddMarkerArrow(defsFront: Selection<any, unknown, HTMLElement, any>, addAllArrows: boolean) {
    const maxIt = addAllArrows ? tlistPainType.length : 1;//o agregar solo el primero.
    for (let i = 0; i < maxIt; i++) {
        const btnRes = tlistPainType[i];
        defsFront.append("marker")
            .attr("id", btnRes.idStr)
            .attr("markerUnits", "strokeWidth")
            .attr("markerWidth", "5")
            .attr("markerHeight", "5")
            .attr("viewBox", "0 0 12 12")
            .attr("refX", "6")
            .attr("refY", "6")
            .attr("orient", "auto")
            .append("path")
            .attr("d", "M3,0,6,6A5.18,5.18,0,0,0,3.047,4.624,5.609,5.609,0,0,0,0,6Z")
            .attr("transform", "translate(10 3) rotate(89)")
            .attr("stroke-linecap", "round")
            .attr("stroke-linejoin", "round")
            .attr("stroke-width", "1")
            .attr("fill", btnRes.color)
            .attr("stroke", btnRes.color);
    }
}

function AddDefsImage(pathImg: string, identifier: string, gContent, _OnLoaded?) {
    if (gContent.select("defs").select("#" + identifier).size() > 0) {
        setTimeout(_OnLoaded, 10);
        return;
    }

    xml(pathImg)
        .then(data => {
            let vbox = data.documentElement.getAttribute('viewBox');

            let dfs = gContent.select("defs");

            dfs.append("symbol")
                .attr("viewBox", vbox)
                .attr("overflow", "visible")
                .attr("preserveAspectRatio", "xMidYMid meet")
                .attr("id", identifier)
                .append(() => data.documentElement);

            if (_OnLoaded) _OnLoaded()
        }).catch(e => console.log(e));
}

/**
 * Functions for manage SVG using D3js
 * 
 */
function _AddUseImage(gContent: Selection<any, any, any, any>, pathImg: string, bounds: { w: number, h: number, x?: number, y?: number }, fill?: string, createImg?: boolean) {
    bounds.x = bounds.x || 0;
    bounds.y = bounds.y || 0;

    let identifier = pathImg.replace(/\//g, '_').replace(/\./g, '_')
    AddDefsImage(pathImg, identifier, gContent, () => {
        const wRes = gImage.node().getBoundingClientRect().width || bounds.w;
        const wParent = gContent.node().getBoundingClientRect().width;
        gImage.attr("width", wRes);
        gImage.attr("x", (wParent - wRes) / 2);
    });

    if (createImg === false) return null;

    let gImage = gContent.select(".g-use-container").append("use")
        .attr("width", bounds.w)
        .attr("height", bounds.h)
        .attr("x", bounds.x)
        .attr("y", bounds.y)
        .attr("xlink:href", "#" + identifier);


    if (fill) gImage.attr("fill", fill);

    return gImage;
}

function DrawTouchEventLines(elemContainer, objRefArrowsList: any, ID_IMG) {
    var line, isDragging;

    var _drag = drag()
        .on("start", dragstart)
        .on("drag", dragmove)
        .on("end", dragend);

    function dragstart(d) {
        var x = d.x,
            y = d.y;
        isDragging = false;

        line = gSideHuman.append("line")
            .attr("x1", x)
            .attr("y1", y)
            .attr("x2", x)
            .attr("y2", y)
            .attr("stroke", btnColorChose.color)
            .attr("marker-end", "url(#" + btnColorChose.idStr + ")")
            .classed("line-pain", true)
            .on("click", click);
    }

    function dragmove(d) {
        isDragging = true;
        line.attr("x2", d.x)
            .attr("y2", d.y);
    }

    function dragend(_) {
        if (!isDragging) line.attr("marker-end", null);
        objRefArrowsList[ID_IMG].push(line);
    }

    elemContainer.call(_drag);


    function click() {
        if (event.defaultPrevented) return event.stopPropagation();
    }
}


const SvgBodyPain = (
    { imgPath, imgIdentifier, btnColor, objRefArrowsList, isEnabled }
        : { imgPath: string, imgIdentifier: string, btnColor?: IBtnTypePain, objRefArrowsList: any, isEnabled: boolean }
) => {

    btnColorChose = btnColor || { id: 0, color: "#f65451", idStr: "arrow-pain-1", label: '' };


    setTimeout(() => {
        AddDocImg(!!btnColor, objRefArrowsList, imgIdentifier, isEnabled, imgPath);//si hay un valor definido de botones, agregar todos los colores.
    }, 500);

    const OnUndo = () => {
        let lastPoint = objRefArrowsList[imgIdentifier].pop();
        if (lastPoint) {
            lastPoint.remove();
        }
    }

    return (
        <div style={{ width: '100%', height: '100%' }}>
            {isEnabled && <Button
                shape="circle"
                icon={<RollbackOutlined />}
                onClick={() => OnUndo()} />}
            <svg id={"svg_body_container" + imgIdentifier} pointerEvents="none" width="100%" height="100%" fill="#ffffff" preserveAspectRatio="xMidYMid meet">
                <defs>
                </defs>
                <g className="g-use-container"></g>
            </svg>
        </div>
    )
}

export default SvgBodyPain;


export function SvgStringToSvgArrows(val: string) {//: Array<Selection<SVGLineElement, any, any, any>>  {
    let gArrows = select(document.createElementNS("http://www.w3.org/2000/svg", "g"));
    gArrows.html(val);

    return gArrows.selectAll("line").nodes();
}

export function SvgElementToString(elems: Selection<SVGElement, any, any, any>[]) {
    if (!elems || elems.length == 0) return null;

    const ress = elems.map(item => item.node().outerHTML);
    return ress.join('');
}