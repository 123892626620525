import { Divider, Form } from "antd"
import FormItem from "antd/es/form/FormItem";
import OpenNotification from "../../../pages/Toast";
import { IPainMapValues } from "../../interfaces/Pain";
import TextArea from "antd/es/input/TextArea";
import { useForm } from "antd/es/form/Form";

const SOAPView = (
    { handlerCompletion, objPainValues }:
        { handlerCompletion: (_fnct: () => boolean) => void, objPainValues: IPainMapValues }
) => {

    handlerCompletion((): boolean => {
        const subj = form.getFieldValue('subjetivos');
        const objApr = form.getFieldValue('objaprec');
        const planes = form.getFieldValue('planes');

        if (!subj && !objApr && !planes) {
            OpenNotification('Datos incompletos', 'Llena al menos un campo de las cajas de texto', 'warn');
            return false;
        }

        objPainValues.subjetivos = subj;
        objPainValues.objapreciativo = objApr;
        objPainValues.planes = planes;

        return true;
    });

    //  SVG PAIN
    const [form] = useForm();

    return <Form layout='vertical' form={form}>
        <div className='row'>
            <div className='col-md-8 col-sm-12'>
                <FormItem name='subjetivos' label='Subjetivos'>
                    <TextArea rows={5} />
                </FormItem>
            </div>

            <div className='col-md-8 col-sm-12'>
                <FormItem name='objaprec' label='Objetivos apreciativos'>
                    <TextArea rows={5} />
                </FormItem>
            </div>
            <div className='col-md-8 col-sm-12'>
                <FormItem name='planes' label='Planes'>
                    <TextArea rows={5} />
                </FormItem>
            </div>
        </div>

        <Divider />

    </Form>
}

export default SOAPView;