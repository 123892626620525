
import { Route, Routes, Navigate } from 'react-router-dom';
import ConfigProvider from 'antd/es/config-provider';
import NotFound from '../pages/sessions/404';
import '../App.scss';
import { GetRoutesDiscapacidad } from './disc-routes';
import { SetUserInfo, USER_INFO } from '../data/General';
import ValDiscSignIn from './ui/Sign-in';
import VerticalLayout from '../layout/vertical/Vertical';

const LayoutRoutes = ({ routes }) => {
    return (<Routes>
        {routes.map((route, index) => (
            <Route key={index} path={`/${route.path}`} element={<route.component />} />
        ))}

        <Route path='*' element={<Navigate replace to='/public/page-404' />} />
    </Routes>);
}

const DefaultRoutes = () => <LayoutRoutes routes={GetRoutesDiscapacidad()} />;

const AppDiscapacidad = () => {

    document.title = 'Valoracion Discapacidad';
    const link = document.querySelector('link[rel="icon"]');
    link.setAttribute('href', 'icon-valoracion.png');

    if (!USER_INFO) {
        let strUser = localStorage.getItem("userInfo");
        if (strUser) SetUserInfo(JSON.parse(strUser));
    }

    if (!USER_INFO) {//Validar inicio de sesión
        return (
            <ValDiscSignIn />
        );
    }

    return (
        <ConfigProvider
            theme={{
                token: { fontFamily: "'Lato', sans-serif" },
            }}
        >
            <Routes>

                <Route path='/' element={<Navigate replace={true} to='/vertical/home' />} />
                <Route path='/public/*' element={<DefaultRoutes />} />
                <Route path='/vertical/*' element={<VerticalLayout><DefaultRoutes /></VerticalLayout>} />
                <Route path='*' element={<NotFound />} />

            </Routes>
        </ConfigProvider>
    );
};

export default AppDiscapacidad;
