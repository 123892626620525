import { ColumnProps } from 'antd/es/table';
import { Avatar, Table, Button, Badge } from 'antd';
import { IStudent } from '../../interfaces/Student';

type Props = {
  users: IStudent[];
  onEditUser?: (user: IStudent) => void;
  onViewProfile?: (user: IStudent) => void;
  onViewPatients?: (user: IStudent) => void;
  onViewForms?: (user: IStudent) => void;
  showOptions?: boolean
};


const StudentsTable = ({
  users,
  onEditUser = () => null,
  onViewProfile = () => null,
  onViewPatients = () => null,
  onViewForms = () => null,
  showOptions = true
}: Props) => {

  const handleViewProfile = (user: IStudent) => {
    onViewProfile(user);
  }


  const actions = (user: IStudent) => (
    <div className='buttons-list nowrap'>
      <Button onClick={() => onViewPatients(user)} shape='circle' >
        <span className='icofont icofont-paralysis-disability' />
      </Button>
      <Button onClick={() => onViewForms(user)} shape='circle' >
        <span className='icofont icofont-sub-listing' />
      </Button>
      <Button onClick={() => onEditUser(user)} shape='circle' type='primary'>
        <span className='icofont icofont-edit-alt' />
      </Button>
    </div>
  );

  const columns: ColumnProps<IStudent>[] = [
    {
      key: 'img',
      title: ' ',
      dataIndex: 'img',
      render: (img, obj) => <Avatar size={40} src={img} onClick={handleViewProfile.bind({}, obj)} />,
    },
    {
      key: 'matricula',
      dataIndex: 'matricula',
      title: 'Matricula',
      sorter: (a, b) => (a.matricula > b.matricula ? 1 : -1),
      render: (name) => <strong>{name}</strong>
    },
    {
      key: 'name',
      dataIndex: 'name',
      title: 'Nombre completo',
      sorter: (a, b) => (a.name > b.name ? 1 : -1),
      render: (name, item) => {
        return (<><Badge color={item.gender == 1 ? "hwb(205 6% 9%)" : 'pink'} text=" " /><strong>{name + " " + (item.lastname ?? "")}</strong></>)
      }
    },
    {
      key: 'email',
      dataIndex: 'email',
      title: 'Correo electrónico',
      sorter: (a, b) => (a.email > b.email ? 1 : -1),
      render: (email) => email
    },
    {
      key: 'age',
      dataIndex: 'age',
      title: 'Edad',
      sorter: (a, b) => a.age - b.age,
      render: (age) => (
        <span className='nowrap' style={{ color: '#a5a5a5' }}>
          {age}
        </span>
      )
    },
    {
      key: 'phone',
      dataIndex: 'phone',
      title: 'Teléfono',
      render: (phone) => (
        <span className='d-flex align-baseline nowrap' style={{ color: '#336cfb' }}>
          <span className='icofont icofont-ui-cell-phone mr-1' style={{ fontSize: 16 }} />
          {phone}
        </span>
      )
    },
    {
      key: 'createdAt',
      dataIndex: 'createdAt',
      title: 'Fecha de creación',
      responsive: ['xxl'],
      render: (visit) => (
        <span className='nowrap' style={{ color: '#a5a5a5' }}>
          {visit}
        </span>
      )
    },
  ];

  if (showOptions) {
    columns.push({
      key: 'actions',
      title: 'Opciones',
      render: actions
    });
  }

  const pagination = users.length <= 10 ? false : {};

  return (
    <>
      <Table
        pagination={pagination}
        className='accent-header'
        rowKey='id'
        dataSource={users}
        columns={columns} />
    </>
  );
};

export default StudentsTable;
