import { Checkbox, Input, Radio, Space } from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useState } from "react";
import ScalePain from "../../APPValDiscapacidad/ui/pain-patient/scale-pain";

export type EQuestion = {
    id: number;
    label?: string;
    hintText?: string;
    type?: 'SHORT' | 'LARGE' | 'TEXT_AREA' | 'RADIO' | 'SCALE_ICON' | 'CHECK';
    options?: EOptionsCheck[];
    value?: any;
    inputType?: 'text' | 'number' | "email" | "date" | "time" | "datetime-local" | "password" | "tel";
    disabled?: boolean;
}
export type EQuestionGroup = {
    title?: string,
    questions: EQuestion[],
    descriptionValue?: string
}
export type EOptionsCheck = {
    id: any, tag: string
}

export const AddCardRowItem = ({
    itemGRow,
    idCard,
    OnUpdateText,
}: { itemGRow: EQuestionGroup, idCard: string, OnUpdateText?: (itemRow: EQuestion, parentRow: EQuestionGroup) => boolean }) => {
    const [objGRow, SetRows] = useState(itemGRow)

    const tempOnUpdateText = (itemRow: EQuestion, parentRow: EQuestionGroup): boolean => {
        SetRows(parentRow)

        if (OnUpdateText) return OnUpdateText(itemRow, parentRow);
    }

    return (<React.Fragment key={"frg-mainrow-" + idCard}>
        {objGRow.title && (<h5 key={"title-h-" + idCard}>{objGRow.title}</h5>)}
        <AddRowCardInfo2 itemRow={objGRow} idCard={idCard} key={idCard + '0009'} OnUpdateText={tempOnUpdateText} />
        {objGRow.descriptionValue && (<h5 key={"description-value-h-" + idCard} className="description-row-value">{objGRow.descriptionValue}</h5>)}
    </React.Fragment>)
}


const AddRowCardInfo2 = ({
    itemRow,
    idCard,
    OnUpdateText
}: { itemRow: EQuestionGroup, idCard: string, OnUpdateText?: (itemRow: EQuestion, parentRow: EQuestionGroup) => boolean }) => {

    const [objRow, SetRows] = useState(itemRow)

    if (objRow.questions) {
        //Agrupar por space
        const grps: { list: EQuestion[], isGroup: boolean }[] = []
        let isAgrouped = false;

        for (let i = 0; i < objRow.questions.length; i++) {
            const item = objRow.questions[i]
            const isForAgroup = item.type == "SHORT" || item.type == "RADIO"

            if (isForAgroup && !isAgrouped) {
                isAgrouped = true;
                grps.push({ list: [], isGroup: true });
            } else if (!isForAgroup) {
                isAgrouped = false;
            }

            if (!isAgrouped)
                grps.push({ list: [], isGroup: false });

            grps[grps.length - 1].list.push(item);
            grps[grps.length - 1].isGroup = isAgrouped;
        }

        const OnInputChange = (ev, itm) => {
            itm.value = ev.target.value;

            if (OnUpdateText)
                OnUpdateText(itm, objRow);

            SetRows(Object.assign({}, objRow))
        }

        let cntI = 0;
        const items = (item: EQuestion): any => {
            cntI++
            let idRow = "-" + idCard + "-" + cntI;

            if (item.type == 'SHORT') {
                idRow = "fragment-shrt" + idRow;
                return AddInputRowitem(item, idRow, OnInputChange)
            }

            if (item.type == 'LARGE') {
                idRow = "lrg" + idRow;
                return AddInputRowitem(item, idRow, OnInputChange);
                // return <AddLargeRowitem idRow={idRow} item={item} key={idRow + "-LARGE"} />
            }

            if (item.type == 'RADIO') {
                idRow = "space-row-opts" + idRow;
                return (<AddOptionsRowitem idRow={idRow} item={item} key={idRow + "-OPTS"} OnInputChange={OnInputChange} />)
            }

            if (item.type == 'CHECK') {
                idRow = "space-row-chk" + idRow;
                return (<AddChecksRowitem idRow={idRow} item={item} key={idRow + "-CHK"} OnInputChange={OnInputChange} />)
            }

            if (item.type == 'TEXT_AREA') {
                idRow = "tx-area" + idRow;
                return <AddTextAreaRowitem idRow={idRow} item={item} key={idRow + "-TXTAREA"} OnInputChange={OnInputChange} />
            }

            if (item.type == 'SCALE_ICON') {
                idRow = "icon-pain" + idRow;
                return <AddChooseIconPain idRow={idRow} item={item} key={idRow + "-TXTAREA"} OnInputChange={OnInputChange} />
            }

            return <></>
        }

        return <>{
            grps.map((objMain, i) => {
                idCard = idCard + "_" + i;

                if (objMain.isGroup) {
                    return (<Space key={"space-rowinputs-" + idCard}>{
                        objMain.list.map(lstObj => items(lstObj))
                    }</Space>)
                } else {
                    return (<React.Fragment key={"frg-rowinputs-" + idCard}>{
                        objMain.list.map(lstObj => items(lstObj))
                    }</React.Fragment>)
                }
            })
        }</>
    }

    <></>
}

const AddInputRowitem = (item: EQuestion, idRow: string, OnInputChange: (ev, itm) => void) => {
    const inpType = item.inputType || 'text';

    return (<React.Fragment key={idRow}>
        {item.label && <span key={"span-row-" + idRow}>{item.label}</span>}
        <Input name='name'
            key={"input-row-" + idRow}
            placeholder={item.hintText}
            onChange={(ev) => OnInputChange(ev, item)}
            type={inpType}
            value={item.value}
            disabled={item.disabled}
            required />
    </React.Fragment>)
}

// const AddLargeRowitem = ({
//     item,
//     idRow
// }: { item: EQuestion, idRow: string }) => {
//     return (
//         <React.Fragment key={"fragment-" + idRow}>
//             {item.label && <span key={"span-row-" + idRow}>{item.label}</span>}
//             <Input name='name'
//                 key={"input-row-" + idRow}
//                 placeholder={item.hintText}
//                 required />
//         </React.Fragment>)
// }

const AddOptionsRowitem = ({
    item,
    idRow,
    OnInputChange
}: { item: EQuestion, idRow: string, OnInputChange: (ev, itm) => void }) => {
    return (<React.Fragment key={"fragment-" + idRow}>
        {item.label && <span key={"span-row-" + idRow}>{item.label}</span>}
        <Radio.Group buttonStyle="solid" key={"rad-grp-" + idRow} defaultValue={item.value} disabled={item.disabled}>
            {
                item.options.map((itemOpts, j) => {
                    return (
                        <Radio.Button value={itemOpts.id} key={"rad-btn-" + idRow + "-" + j} onChange={(ev) => OnInputChange(ev, item)}>{itemOpts.tag}</Radio.Button>
                    );
                })
            }
        </Radio.Group>
    </React.Fragment>)
}

const AddChecksRowitem = ({
    item,
    idRow,
    OnInputChange
}: { item: EQuestion, idRow: string, OnInputChange: (ev, itm) => void }) => {
    const __options = item.options.map((item) => { return { label: item.tag, value: item.id } })

    const onChg2 = (ev: any[]) => {
        const valRes = ev.length == 0 ? null : ev.join(',');
        OnInputChange({ target: { value: valRes } }, item);
    }

    const sValSelected: number[] = item.value ? item.value.split(',') : [];
    const valSelected = [];
    sValSelected.map(o => valSelected.push(+o));

    return (<React.Fragment key={"rad-grp-" + idRow}>
        <Checkbox.Group options={__options} defaultValue={valSelected} onChange={onChg2} disabled={item.disabled} />
    </React.Fragment>)
}

const AddTextAreaRowitem = ({
    item,
    idRow,
    OnInputChange
}: { item: EQuestion, idRow: string, OnInputChange: (ev, itm) => void }) => {
    // const [itemRow, SetItemRow] = useState(item);

    return (<React.Fragment key={"fragment-" + idRow}>
        {item.label && <span key={"span-row-" + idRow}>{item.label}</span>}
        <TextArea
            style={{ display: 'inline-flex' }}
            placeholder={item.hintText}
            key={"textarea-row-" + idRow}
            onChange={(ev) => OnInputChange(ev, item)}
            maxLength={150}
            disabled={item.disabled}
            value={item.value} />
    </React.Fragment>)

}

const AddChooseIconPain = ({
    item,
    idRow,
    OnInputChange
}: { item: EQuestion, idRow: string, OnInputChange: (ev, itm) => void }) => {

    const _ics: { id: number, ic: string, active: boolean }[] = [];
    for (let i = 0; i < 6; i++) {
        const id = i + 1;
        _ics.push({ id: id, ic: "content/icons/ic_eva_" + id + ".svg", active: id == item.value })
    }

    // const [ics, setIcSel] = useState(_ics);
    const OnChangeSldr = (v: number) => {
        OnInputChange({ target: { value: v } }, item)
    }

    return (<React.Fragment key={"space-row-opts-" + idRow}>
        {item.label && (<span key={"span-row-" + idRow}>{item.label}</span>)}
        <ScalePain objRef={{ 'scalePain': item.value }} isEnabled={true} OnChangeSldr={OnChangeSldr} />
    </React.Fragment >)
}

 //     {
    //         <div>
    //             {item.label && (<span key={"span-row-" + idRow}>{item.label}</span>)}
    //             <div className="icos-eva">
    //                 {
    //                     ics.map((o, i) => <img src={o.ic} alt="icon scale eva" className={o.active ? "active" : ""} key={"dv-ic-eva-" + i}
    //                         onClick={() => {
    //                             if (item.disabled) return;

    //                             ics.map(obj => obj.active = o.ic === obj.ic)
    //                             const temp = Array.from(ics)
    //                             setIcSel(temp)

    //                             OnInputChange({target: {value: o.id } }, item)
    //                         }} />)
    //                 }
    //             </div>
    //             <div>
    //                 <img src="content/icons/img_scala_eva_lines.svg" alt="icon scale eva" />
    //             </div>
    //         </div>
    //     }