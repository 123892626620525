import { Divider, Radio, Space } from "antd";
import { GetQuestionByCategory } from "../../Model/Question";
import { IPainMapValues, IQuestion } from "../../interfaces/Pain";
import { RadioChangeEvent } from "antd/es/radio";
import OpenNotification from "../../../pages/Toast";
import { SetScrollToTop } from "../../../pages/history/ExpUtils";

const QuestionPain = (
    { category, handlerCompletion, objRef }: { category: number, handlerCompletion: (_fnct: () => boolean) => void, objRef: IPainMapValues }
) => {
    SetScrollToTop();

    const questions = GetQuestionByCategory(category);
    if (!objRef.questions) objRef.questions = [];
    questions.forEach(item => {
        item.selectedValue = undefined;
        const objTemp = objRef.questions.find(o => o.id == item.id)
        if (!objTemp)
            objRef.questions.push({ id: item.id })
        else item.selectedValue = objTemp.value;
    })

    const OnChangeRadio = (objSelected: IQuestion, radSelected: RadioChangeEvent) => {
        objRef.questions.find(o => o.id == objSelected.id).value = radSelected.target.value;
        questions.find(o => o.id == objSelected.id).selectedValue = radSelected.target.value;
    }

    handlerCompletion((): boolean => {
        const allAnswered = questions.findIndex(o => o.selectedValue === undefined) == -1;
        if (!allAnswered)
            OpenNotification('Datos incompletos', 'Completa todos los cuestionarios', 'warn')

        return allAnswered;
    });

    return (<>
        <div className='row'>
            <h4>Cuestionario dolor de espalda</h4>
            <h6>Este cuestionario fue diseñado para tener una idea de cuánto interfiere el dolor en su desempeño durante las actividades de la vida diaria. Por favor, conteste cada sección marcando solo un casillero. Sabemos que probablemente encuentre más de una respuesta que se ajuste a su condición, pero por favor marque la que más refleje su problema.</h6>
        </div>

        <Divider />

        <div className='row'>
            {
                questions.map(item => {
                    return (
                        <div className='col-md-6 col-sm-12' key={'div-radgrp-' + item.id}>
                            <h6>{item.title}</h6>
                            <Radio.Group onChange={(ev) => OnChangeRadio(item, ev)} className="rad-grp-inside" defaultValue={item.selectedValue}>
                                <Space direction="vertical">
                                    {
                                        item.answers.map((subItem, i) => {
                                            return (
                                                <Radio value={i} key={'radio-' + item.id + '-' + i}>{subItem}</Radio>
                                            );
                                        })
                                    }
                                </Space>
                            </Radio.Group>
                        </div>
                    )
                })
            }
        </div>
    </>)
}

export default QuestionPain;
