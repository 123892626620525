import { Button, Divider, Form, Radio, RadioChangeEvent, Select } from "antd"
import { IPatient } from "../../../interfaces/patient";
import FormItem from "antd/es/form/FormItem";
import OpenNotification from "../../../pages/Toast";
import { EArrPainTempName, IPainMapValues } from "../../interfaces/Pain";
import { tlistPainType } from "../../Model/General";
import SvgBodyPain, { SvgElementToString, SvgStringToSvgArrows } from "../../../pages/history/svgBodyPain";
import { useState } from "react";

const InfoPatientPain = (
    { patients, handlerCompletion, OnCheckPainType, OnChangePatient, objPainValues }:
        { patients: IPatient[], handlerCompletion: (_fnct: () => boolean) => void, OnCheckPainType: (opt: number) => void, OnChangePatient: (ptnt: IPatient) => void, objPainValues: IPainMapValues, }
) => {

    // const [showPainType, setShowPainType] = useState(!objPainValues.patient || !objPainValues.patient.hasForm)
    const showPainType = !objPainValues.patient || !objPainValues.patient.hasForm

    const onCmbChangePatient = (value: number) => {
        const patient = patients.find(o => o.id == value);
        OnChangePatient(patient);
        // setShowPainType(!objPainValues.patient.hasForm);
    };

    const listSelect = [];
    patients.forEach(item => {
        listSelect.push({ value: item.id, label: item.email + ' (' + item.name + ' ' + (item.lastname ? item.lastname : '') + ')', obj: item });
    });

    const filterPatientList = (input: string, option?: { label: string; value: string, obj: IPatient }) => {
        const filtVal = input.toLowerCase();

        return (option?.label ?? '').toLowerCase().includes(filtVal);
    }

    const onChange4 = ({ target: { value } }: RadioChangeEvent) => {
        objPainValues.typePain = +value;
        OnCheckPainType(objPainValues.typePain);
    };

    const optionsTypePain = [
        { label: 'Espalda', value: 1 },
        { label: 'Cuello', value: 2 },
        { label: 'Ambos', value: 3 },
    ];


    handlerCompletion((): boolean => {
        if (!objPainValues.patient) {
            OpenNotification('Datos incompletos', 'Selecciona un paciente en la lista', 'warn');
            return false;
        }

        if (showPainType && !objPainValues.typePain) {
            OpenNotification('Datos incompletos', 'Selecciona el tipo de dolor para el paciente', 'warn');
            return false;
        }

        // const strResArrows = select("#svg_body_container").select(".g-arrows").html();
        objPainValues.svgPainFront = SvgElementToString(objPainValues._svgPainFront);
        objPainValues.svgPainBack = SvgElementToString(objPainValues._svgPainBack);

        if (!objPainValues.svgPainFront && !objPainValues.svgPainBack) {
            OpenNotification('Datos incompletos', 'Dibuja los tipos de dolor en la imagen del cuerpo', 'warn');
            return false;
        }


        return true;
    });

    //  SVG PAIN
    const [btnColorArrow, setBtnColorArrow] = useState(tlistPainType[0]);
    const [isFrontSvg, setFrontSvg] = useState(true);

    const OnChoseBtnColor = (obj: RadioChangeEvent) => {
        setBtnColorArrow(obj.target.value);
    }

    const OnChangeDraw = () => {
        setFrontSvg(!isFrontSvg);
    }



    if (isFrontSvg && !objPainValues._svgPainFront)
        objPainValues._svgPainFront = objPainValues.svgPainFront ? SvgStringToSvgArrows(objPainValues.svgPainFront) : [];
    if (!isFrontSvg && !objPainValues._svgPainBack)
        objPainValues._svgPainBack = objPainValues.svgPainBack ? SvgStringToSvgArrows(objPainValues.svgPainBack) : [];

    return <Form layout='vertical'>
        <div className='row'>
            <div className='col-md-6 col-sm-12'>
                <FormItem
                    name='patient'
                    label='Paciente'
                    rules={[{ required: true, message: 'Selecciona un paciente' }]}
                    initialValue={objPainValues.patient ? objPainValues.patient.id : null}>
                    <Select
                        showSearch
                        placeholder="Buscar correo paciente"
                        onChange={onCmbChangePatient}
                        filterOption={filterPatientList}
                        // defaultValue={objPainValues.patient ? objPainValues.patient.id : null}
                        options={listSelect} />
                </FormItem>
            </div>

            <div className='col-md-6 col-sm-12'>
                {showPainType && <FormItem
                    name='typePain'
                    label='Tipo de dolor'
                    rules={[{ required: false, message: 'Seleccionar el tipo de dolor' }]} initialValue={objPainValues.typePain}>
                    <Radio.Group
                        options={optionsTypePain}
                        onChange={onChange4}
                        optionType="button"
                        disabled={!objPainValues.patient}
                        buttonStyle="solid" />
                </FormItem>}
            </div>
        </div>

        <Divider />

        <div className='row'>
            <div className='col-md-3 col-sm-12'>
                <Radio.Group defaultValue={tlistPainType[0]} buttonStyle="solid" className="vertical-radio" onChange={OnChoseBtnColor}>
                    {tlistPainType.map(item => {
                        return <Radio.Button value={item} key={'t-lst-pain' + item.id} className={"btn-color-arrow btn-rad-" + item.id} >{item.label}</Radio.Button>
                    })}
                </Radio.Group>
            </div>
            <div className='col-md-6 col-sm-12 pain-drawing'>
                {isFrontSvg && <SvgBodyPain imgPath={'content/body_front.svg'}
                    btnColor={btnColorArrow} imgIdentifier={isFrontSvg ? EArrPainTempName.SVG_PAIN_FRONT : EArrPainTempName.SVG_PAIN_BACK}
                    objRefArrowsList={objPainValues} isEnabled={true} />}
                {!isFrontSvg && <SvgBodyPain imgPath={'content/body_back.svg'}
                    btnColor={btnColorArrow} imgIdentifier={isFrontSvg ? EArrPainTempName.SVG_PAIN_FRONT : EArrPainTempName.SVG_PAIN_BACK}
                    objRefArrowsList={objPainValues} isEnabled={true} />}
            </div>
            <div className='col-md-3 col-sm-12'>
                <h5>{isFrontSvg ? 'De frente' : 'De espalda'}</h5>
                <h6>Seleccione una opción y dibuje el area de las molestias.</h6>
                <Button style={{ margin: '0 8px' }} onClick={() => OnChangeDraw()}>
                    Cambiar
                </Button>
            </div>
        </div>

    </Form>
}

export default InfoPatientPain;