import { IRoute } from '../interfaces/routing';
import ValoracionMedular from '../pages/history/public-web/valoracion-medular';

import NotFound from '../pages/sessions/404';
import InternalError from '../pages/sessions/500';

export const publicWebRoutes: IRoute[] = [
    {
        path: 'page-404',
        component: NotFound
    },
    {
        path: 'page-500',
        component: InternalError
    },
    {
        path: 'home',
        component: ValoracionMedular
    }
];
