import { Button } from 'antd';

import './form-neurokogia.scss';
import './ExpNeurologia.scss';
import './Neurologia/SvgStyle.scss';
import { SvgNeurologyBody } from './SvgBodyImg';
import BtnChooseOptionNeuro, { EInputValue } from './Neurologia/BtnChoose';
import { useEffect, useState } from 'react';
import OpenNotification from '../Toast';
import ISNCSCI, { Exam } from 'isncsci';
import { IExpediente, IValueISNC } from '../../interfaces/expediente';
import { ERowTableNeuro, ILevelNeuroResult, eScores, rowsLeft, rowsRight, scoreMotors, scoreSensory } from './Neurologia/NeurologiaDataConfig';
import { SaveNeurologiaPDF } from './Neurologia/PrintNeurologia';
import { DownloadOutlined } from '@ant-design/icons';
import { IsHistoryDisabled } from '../../data/General';

/**
 * API & Algorithm
 * https://isncscialgorithm.com/
 */


var _expedienteInfo: IExpediente;
var _totalsRightLeft: any[][] = [];
var _resultLevelNeuro: ILevelNeuroResult = {} as any;

const headsData: { label: string, colSpan: number, rowSpan: number, cls?: string }[][] = [
    [{ label: "Derecho", cls: "side-label", rowSpan: 2, colSpan: 1 }, { label: "Motor", cls: "measurement-technique", rowSpan: 1, colSpan: 1 }, { label: "Sensorial", cls: "measurement-technique", rowSpan: 1, colSpan: 2 }],
    [{ label: "Musculos<br>clave", cls: "measurement-technique", rowSpan: 1, colSpan: 1 }, { label: "Clave sensorial<br>puntos", cls: "measurement-technique", rowSpan: 1, colSpan: 2 }],
    [{ label: "", rowSpan: 1, colSpan: 2 }, { label: "Toque Ligero (LT)", cls: "measurement-technique", rowSpan: 1, colSpan: 1 }, { label: "Pinchazo (PP)", cls: "measurement-technique", rowSpan: 1, colSpan: 1 }],
]

const THeadRgt = ({
    IS_RIGHT
}: { IS_RIGHT: boolean }) => {
    return (<thead>
        {
            headsData.map((item, i) => {
                const _posVal = IS_RIGHT ? "Derecho" : "Izquierdo";
                if (i == 0) item[0].label = _posVal;

                return (<tr key={_posVal + "-" + i}>{
                    item.map((_, index) => {
                        const pos = IS_RIGHT ? index : item.length - 1 - index;
                        const subitem = item[pos]
                        const subs = subitem.label.split("<br>")
                        const lbl = subs[0];
                        const lbl2 = subs.length > 1 ? subs[1] : null;

                        return <th colSpan={subitem.colSpan} rowSpan={subitem.rowSpan} className={subitem.cls} key={"thead-" + _posVal + "-" + i + "_" + index}>
                            {lbl}
                            {lbl2 && (<br></br>)}
                            {lbl2 && ("" + lbl2)}
                        </th>
                    })
                }</tr>)
            })
        }

    </thead>)
}

const foodsData: { label: string, dataName?: string, cls: string }[][] = [
    [{ label: "Totales", cls: "total-label" }, { label: "", cls: "total", dataName: "RightMotorTotal" }, { label: "", cls: "total", dataName: "RightTouchTotal" }, { label: "", cls: "total", dataName: "RightPrickTotal" }],
    [{ label: "(Máximo)", cls: "maximum-label" }, { label: "(50)", cls: "maximum" }, { label: "(56)", cls: "maximum" }, { label: "(56)", cls: "maximum" }]
]

const TFoodRgt = ({
    POS
}: { POS: 'Right' | 'Left' }) => {
    return (<tfoot>{
        foodsData.map((item, ii) => {
            item[0].label = item[0].label.replace("derecho", "").replace("izquierdo", "");
            if (item[0].label == 'Totales')
                item[0].label = item[0].label + " " + (POS == 'Right' ? "derecho" : "izquierdo");

            return (<tr key={"tr-food-" + POS + "-" + ii}>{
                item.map((_, index) => {
                    const pos = POS == 'Right' ? index : item.length - 1 - index;
                    const subitem = item[pos];
                    if (subitem.dataName) {
                        subitem.dataName = subitem.dataName.replace("Right", "").replace("Left", "");
                        subitem.dataName = POS + subitem.dataName;
                    }

                    const _id = subitem.dataName ? subitem.dataName : "th-food-" + POS + "-" + ii + "_" + index;

                    return <th className={subitem.cls} data-name={subitem.dataName} key={"th-food-" + POS + "-" + ii + "_" + index} id={_id}>{subitem.label}</th>
                })
            }</tr>)
        })
    }</tfoot>)
}

const OnChooseBtnRow = (rws: ERowTableNeuro[], fnCall: (itms: ERowTableNeuro[]) => void, opt: EInputValue, nInput: number, identifier: number) => {
    rws.map(item => {
        if (item.id <= identifier) return;

        if (nInput == 1)
            item.Inp1.value = opt.value
        else if (nInput == 2)
            item.Inp2.value = opt.value
        else if (nInput == 3 && item.Inp3)
            item.Inp3.value = opt.value

        if (nInput == 3) return
        const elm = document.querySelector("path[data-name='" + item.dataId + "']");
        const vcls = ("" + opt.value).replace('*', '-plus')
        const clsNow = (elm.className as any).baseVal;

        elm.classList.remove(clsNow);
        elm.classList.add("val-" + vcls);

    });

    const temp = Array.from(rws)
    fnCall(temp)
}

const ComboDefault = (
    { item, OnChange, posJ }: { item: ERowTableNeuro, OnChange: (ev, item: ERowTableNeuro) => void, posJ: number }
) => {
    const cls = item.c6.class;
    const value = item.c6.value;

    return <select className={cls} id={cls} name={cls} data-name={cls} value={value} onChange={(ev) => OnChange(ev, item)} disabled={item.disabled}>
        {
            item.c6.options.map((valItem, i) => {
                return <option value={valItem.id} key={"cmb-row-" + posJ + "-" + i}>{valItem.value}</option>
            })
        }
    </select>
}

const TblBodyRight = ({
    rows,
    OnClickBtn
}: { rows: ERowTableNeuro[], OnClickBtn: (opt: EInputValue, nInput: number, identifier: number) => void }) => {
    const [_rows, setRowsInfo] = useState(rows);

    const OnChangeCmb = (event, item: ERowTableNeuro) => {
        item.c6.value = event.target.value;
        setRowsInfo(Object.assign([], rows));
    }

    return (<tbody>{
        _rows.map((item, j) => {
            return (<tr key={"row-" + j}>
                <td className="level-name" colSpan={!item.Inp3 ? 2 : 1}>
                    <div className='description-cell'>
                        {item.c6 && item.c6.label && <div className={item.c6.class}>{item.c6.label}</div>}
                        {item.c6 && item.c6.type === 'COMBOBOX' && <ComboDefault item={item} OnChange={OnChangeCmb} posJ={j} />}
                        {item.c6 && item.c6.type == 'TEXT_AREA' && (
                            <textarea cols={1} rows={1} data-name="Comments" data-controller="NeurologyTest" className={item.c6.class}
                                onChange={(ev) => OnChangeCmb(ev, item)} value={item.c6.value} disabled={item.disabled}></textarea>
                        )}
                        <div className='cell-sub-right'>{item.CLabel}</div>
                    </div>
                </td>

                {item.Inp3 && <td className="smart-cell sensory touch right"><BtnChooseOptionNeuro btnOpts={item.Inp3} OnChooseBtn={OnClickBtn} nInput={3} identifier={item.id} disabled={item.disabled} /></td>}
                <td className="smart-cell sensory touch right"><BtnChooseOptionNeuro btnOpts={item.Inp2} OnChooseBtn={OnClickBtn} nInput={2} identifier={item.id} disabled={item.disabled} /></td>
                <td className="smart-cell sensory prick right"><BtnChooseOptionNeuro btnOpts={item.Inp1} OnChooseBtn={OnClickBtn} nInput={1} identifier={item.id} disabled={item.disabled} /></td>
            </tr>)
        })
    }</tbody>)
}

const TblBodyLeft = ({
    rows,
    OnClickBtn
}: { rows: ERowTableNeuro[], OnClickBtn: (opt: EInputValue, nInput: number, identifier: number) => void }) => {
    const OnChangeCmb = (event, item: ERowTableNeuro) => {
        item.c6.value = event.target.value;
    }

    return <tbody>{
        rows.map((item, j) => {
            return (<tr key={"left-row-" + j}>
                <td className="smart-cell sensory touch left"><BtnChooseOptionNeuro btnOpts={item.Inp1} OnChooseBtn={OnClickBtn} nInput={1} identifier={item.id} disabled={item.disabled} /></td>
                <td className="smart-cell sensory prick left"><BtnChooseOptionNeuro btnOpts={item.Inp2} OnChooseBtn={OnClickBtn} nInput={2} identifier={item.id} disabled={item.disabled} /></td>
                {item.Inp3 && <td className="smart-cell sensory touch left"><BtnChooseOptionNeuro btnOpts={item.Inp3} OnChooseBtn={OnClickBtn} nInput={3} identifier={item.id} disabled={item.disabled} /></td>}

                <td className="level-name" colSpan={!item.Inp3 ? 2 : 1}>
                    <div className='description-cell'>
                        <div className='cell-sub-right'>{item.CLabel}</div>
                        {item.c6 && item.c6.type === 'COMBOBOX' && <ComboDefault item={item} OnChange={OnChangeCmb} posJ={j} />}
                        {item.c6 && item.c6.label && <div className={item.c6.class}>{item.c6.label}</div>}
                    </div>
                </td>

            </tr>)
        })
    }</tbody>
}

const TableRightLeft = ({
    cls,
    IS_RIGHT
}: { cls: string, IS_RIGHT: boolean }): JSX.Element => {

    const [_rows, setRows] = useState(IS_RIGHT ? rowsRight : rowsLeft);

    const OnClickBtn = (opt: EInputValue, nInput: number, identifier: number) => {
        OnChooseBtnRow(_rows, setRows, opt, nInput, identifier);
    };

    return (<div className={cls}>
        <table>
            <THeadRgt IS_RIGHT={IS_RIGHT} />
            <TFoodRgt POS={IS_RIGHT ? 'Right' : 'Left'} />
            {IS_RIGHT && <TblBodyRight rows={_rows} OnClickBtn={OnClickBtn} />}
            {!IS_RIGHT && <TblBodyLeft rows={_rows} OnClickBtn={OnClickBtn} />}
        </table>
    </div >)
}

const FullTableImage = () => {
    return <div className="main-content">
        <TableRightLeft cls='right-column' IS_RIGHT={true} />
        <div className="dermatome-diagram-column">
            <SvgNeurologyBody></SvgNeurologyBody>
        </div>
        <TableRightLeft cls='left-column' IS_RIGHT={false} />
    </div>
}

const FooterTotals = ({
    score,
    title
}: { score: eScores[], title: string }) => {
    return (<div className={title.toLowerCase()} >
        <div className="title">Subpuntuaciones {title}</div>
        <ul id="footer-container-ul">{
            score.map((item, i) => {
                return (<li key={"li-footer-" + i}>
                    <div className="item">
                        <span>{item.label}</span>
                        <span className="total" data-name="RightUpperMotorTotal" data-controller="IsncsciTotals">&nbsp;{item.value}</span>
                    </div>
                    <div className="helper"><span style={{ "textTransform": "uppercase" }}>{item.tag}</span></div>
                </li>)
            })
        }</ul>
    </div>)
}

function SetRowsWidthData(disabled: boolean) {
    _expedienteInfo = _expedienteInfo || {} as any;
    _expedienteInfo.ISNCSCI = _expedienteInfo.ISNCSCI || [];

    const valuesRight: IValueISNC[] = _expedienteInfo.ISNCSCI[0] || [];
    const valuesLeft: IValueISNC[] = _expedienteInfo.ISNCSCI[1] || [];

    const fnSetRightLeftValue = (isRight: boolean, item: IValueISNC) => {
        let arrTemp = isRight ? rowsRight : rowsLeft
        const fnd = arrTemp.find(o => o.id == item.id);
        fnd.Inp1.value = item.c1;
        fnd.Inp2.value = item.c2;
        if (fnd.Inp3) fnd.Inp3.value = item.c3;
        if (fnd.c6 && item.c6) fnd.c6.value = item.c6;

        fnd.disabled = disabled;
    }

    valuesRight.forEach(item => fnSetRightLeftValue(true, item));
    valuesLeft.forEach(item => fnSetRightLeftValue(false, item));

}

function OnCalculate(setScores: React.Dispatch<React.SetStateAction<eScores[][]>>) {
    const saveRight: IValueISNC[] = [];
    const saveLeft: IValueISNC[] = [];
    _resultLevelNeuro = {} as any;

    //==============================
    //======= create exam ==========
    //==============================
    let resExam: Exam = {
        right: {
            motor: {} as any,
            lightTouch: {} as any,
            pinPrick: {} as any
        },
        left: {
            motor: {} as any,
            lightTouch: {} as any,
            pinPrick: {} as any
        }
    } as any

    scoreMotors.map(o => o.value = 0)
    scoreSensory.map(o => o.value = 0)
    const setInputValue = (_find: eScores, item: EInputValue) => {
        const _value = item.value.replace('*', '');

        if (typeof _find.value === 'number' && isNaN(_value) == false)
            _find.value += (+_value);
        else _find.value = 'ND';
    }

    const SaveListRightLeft = (isRight: boolean, item: ERowTableNeuro) => {
        const objToSave: IValueISNC = { id: item.id, c1: item.Inp1.value, c2: item.Inp2.value };
        if (item.Inp3) objToSave.c3 = item.Inp3.value;
        if (item.c6 && item.c6.value) objToSave.c6 = item.c6.value;

        if (isRight)
            saveRight.push(objToSave);
        else
            saveLeft.push(objToSave);
    }

    for (let i = 0; i < rowsRight.length; i++) {
        const item = rowsRight[i];
        if (!item.Inp1.value || !item.Inp2.value || (item.Inp3 && !item.Inp3.value)) {
            OpenNotification('¡Error!', "No es posible calcular, rellena todos los campos", 'error');
            return;
        }

        resExam.right.pinPrick[item.identifier] = item.Inp1.value;
        resExam.right.lightTouch[item.identifier] = item.Inp2.value;
        if (item.Inp3)
            resExam.right.motor[item.identifier] = item.Inp3.value;

        if (item.identifier == 'T5' && item.c6.value)
            resExam.right.lowestNonKeyMuscleWithMotorFunction = item.c6.value;

        if (item.identifier == 'T6' && item.c6.value)//Para el izquierdo
            resExam.left.lowestNonKeyMuscleWithMotorFunction = item.c6.value;

        // =================
        // Motor subscores
        // =================
        if (item.id >= 4 && item.id <= 8) {
            const _find = scoreMotors.find(o => o.id == 'UER');
            setInputValue(_find, item.Inp3)
        }

        if (item.id >= 21 && item.id <= 25) {
            const _find = scoreMotors.find(o => o.id == 'LER');
            setInputValue(_find, item.Inp3)
        }

        const fndLTR = scoreSensory.find(o => o.id == 'LTR');
        setInputValue(fndLTR, item.Inp2)

        const fndPPR = scoreSensory.find(o => o.id == 'PPR');
        setInputValue(fndPPR, item.Inp1)

        // ===== Save Result =====
        SaveListRightLeft(true, item);
    }

    for (let i = 0; i < rowsLeft.length; i++) {
        const item = rowsLeft[i];
        if (!item.Inp1.value || !item.Inp2.value || (item.Inp3 && !item.Inp3.value)) {
            OpenNotification('¡Error!', "No es posible calcular, rellena todos los campos", 'error');
            return;
        }

        resExam.left.pinPrick[item.identifier] = item.Inp1.value;
        resExam.left.lightTouch[item.identifier] = item.Inp2.value;
        if (item.Inp3)
            resExam.left.motor[item.identifier] = item.Inp3.value;

        // =================
        // Motor subscores
        // =================
        if (item.id >= 4 && item.id <= 8) {
            const _find = scoreMotors.find(o => o.id == 'UEL');
            setInputValue(_find, item.Inp3)
        }

        if (item.id >= 21 && item.id <= 25) {
            const _find = scoreMotors.find(o => o.id == 'LEL');
            setInputValue(_find, item.Inp3)
        }

        const fndLTL = scoreSensory.find(o => o.id == 'LTL');
        setInputValue(fndLTL, item.Inp2)

        const fndPPL = scoreSensory.find(o => o.id == 'PPL');
        setInputValue(fndPPL, item.Inp2)

        // ===== Save Result =====
        SaveListRightLeft(false, item);
    }

    const fndRgt = rowsRight.find(o => o.identifier == 'S4_5');
    if (!fndRgt.c6.value || fndRgt.c6.value == 'None') {
        OpenNotification('¡Error!', "No es posible calcular, favor de especificar un valor para: '" + fndRgt.c6.label + "'", 'error');
        return
    }

    const fndLft = rowsLeft.find(o => o.identifier == 'S4_5');
    if (!fndLft.c6.value || fndLft.c6.value == 'None') {
        OpenNotification('¡Error!', "No es posible calcular, favor de especificar un valor para: '" + fndLft.c6.label + "'", 'error');
        return
    }

    resExam.deepAnalPressure = fndLft.c6.value;
    resExam.voluntaryAnalContraction = fndRgt.c6.value;

    // get result
    let result = new ISNCSCI(resExam);
    // console.log(result)
    _totalsRightLeft = [
        [result.totals.right.motor, result.totals.right.lightTouch, result.totals.right.pinPrick],
        [result.totals.left.motor, result.totals.left.lightTouch, result.totals.left.pinPrick]
    ];

    const _totalRghMotor = document.getElementById("RightMotorTotal");
    const _totalRghTouch = document.getElementById("RightTouchTotal");
    const _totalRghPrick = document.getElementById("RightPrickTotal");
    // console.log(_totalRghMotor)
    _totalRghMotor.innerText = _totalsRightLeft[0][0];
    _totalRghTouch.innerText = _totalsRightLeft[0][1];
    _totalRghPrick.innerText = _totalsRightLeft[0][2];

    const _totalLftMotor = document.getElementById("LeftMotorTotal");
    const _totalLftTouch = document.getElementById("LeftTouchTotal");
    const _totalLftPrick = document.getElementById("LeftPrickTotal");
    _totalLftMotor.innerText = _totalsRightLeft[1][0];
    _totalLftTouch.innerText = _totalsRightLeft[1][1];
    _totalLftPrick.innerText = _totalsRightLeft[1][2];

    //total 2
    const CheckVals = (valStrng: string): string => {
        let _valtxt = valStrng;
        if (_valtxt.includes(',')) {
            const arrsSnsRght = _valtxt.split(',');
            const last = arrsSnsRght.pop();
            _valtxt = arrsSnsRght[0] + (arrsSnsRght.length > 1 ? ("-" + arrsSnsRght[arrsSnsRght.length - 1]) : "") + "," + last;
        }

        return _valtxt;
    }

    const _resRightSensory = document.getElementById("ResRightSensory");
    const _resLeftSensory = document.getElementById("ResLeftSensory");
    const _resRightMotor = document.getElementById("ResRightMotor");
    const _resLeftMotor = document.getElementById("ResLeftMotor");
    _resultLevelNeuro.resRightSensory = CheckVals(result.classification.neurologicalLevels.sensoryRight);
    _resultLevelNeuro.resLeftSensory = CheckVals(result.classification.neurologicalLevels.sensoryLeft);
    _resultLevelNeuro.resRightMotor = CheckVals(result.classification.neurologicalLevels.motorRight);
    _resultLevelNeuro.resLeftMotor = CheckVals(result.classification.neurologicalLevels.motorLeft);
    _resRightSensory.innerText = _resultLevelNeuro.resRightSensory;
    _resLeftSensory.innerText = _resultLevelNeuro.resLeftSensory;
    _resRightMotor.innerText = _resultLevelNeuro.resRightMotor;
    _resLeftMotor.innerText = _resultLevelNeuro.resLeftMotor;

    const _neurologicalLevelOfInjury = document.getElementById("NeurologicalLevelOfInjury");
    _resultLevelNeuro.neurologicalLevelOfInjury = CheckVals(result.classification.neurologicalLevelOfInjury);
    _neurologicalLevelOfInjury.innerText = _resultLevelNeuro.neurologicalLevelOfInjury;
    const _completeIncomplete = document.getElementById("completeIncomplete");
    const _AsiaImpairmentScale = document.getElementById("AsiaImpairmentScale");
    const _RightSensoryZpp = document.getElementById("RightSensoryZpp");
    const _LeftSensoryZpp = document.getElementById("LeftSensoryZpp");
    const _RightMotorZpp = document.getElementById("RightMotorZpp");
    const _LeftMotorZpp = document.getElementById("LeftMotorZpp");
    _resultLevelNeuro.completeIncomplete = result.classification.injuryComplete;
    _resultLevelNeuro.AsiaImpairmentScale = result.classification.ASIAImpairmentScale;
    _resultLevelNeuro.RightSensoryZpp = result.classification.zoneOfPartialPreservations.sensoryRight;
    _resultLevelNeuro.LeftSensoryZpp = result.classification.zoneOfPartialPreservations.sensoryLeft;
    _resultLevelNeuro.RightMotorZpp = result.classification.zoneOfPartialPreservations.motorRight;
    _resultLevelNeuro.LeftMotorZpp = result.classification.zoneOfPartialPreservations.motorLeft;
    _completeIncomplete.innerText = _resultLevelNeuro.completeIncomplete;
    _AsiaImpairmentScale.innerText = _resultLevelNeuro.AsiaImpairmentScale;
    _RightSensoryZpp.innerText = _resultLevelNeuro.RightSensoryZpp;
    _LeftSensoryZpp.innerText = _resultLevelNeuro.LeftSensoryZpp;
    _RightMotorZpp.innerText = _resultLevelNeuro.RightMotorZpp;
    _LeftMotorZpp.innerText = _resultLevelNeuro.LeftMotorZpp;

    // =================
    // Motor & Sensory subscores
    // =================
    const _UER = scoreMotors.find(o => o.id == 'UER');
    const _UEL = scoreMotors.find(o => o.id == 'UEL');
    const _UEMS = scoreMotors.find(o => o.id == 'UEMS');
    const _LER = scoreMotors.find(o => o.id == 'LER');
    const _LEL = scoreMotors.find(o => o.id == 'LEL');
    const _LEMS = scoreMotors.find(o => o.id == 'LEMS');

    _UEMS.value = !isNaN(_UER.value) && !isNaN(_UEL.value) ? (_UER.value + _UEL.value) : 'ND';
    _LEMS.value = !isNaN(_LER.value) && !isNaN(_LEL.value) ? (_LER.value + _LEL.value) : 'ND';

    const _LTR = scoreSensory.find(o => o.id == 'LTR');
    const _LTL = scoreSensory.find(o => o.id == 'LTL');
    const _LTT = scoreSensory.find(o => o.id == 'LTT');
    const _PPR = scoreSensory.find(o => o.id == 'PPR');
    const _PPL = scoreSensory.find(o => o.id == 'PPL');
    const _PPT = scoreSensory.find(o => o.id == 'PPT');
    _LTT.value = !isNaN(_LTR.value) && !isNaN(_LTL.value) ? (_LTR.value + _LTL.value) : 'ND';
    _PPT.value = !isNaN(_PPR.value) && !isNaN(_PPL.value) ? (_PPR.value + _PPL.value) : 'ND';

    const tempArrs = Array.from([scoreMotors, scoreSensory])
    setScores(tempArrs)

    _expedienteInfo.ISNCSCI = [saveRight, saveLeft];
}

async function OnPrint() {
    SaveNeurologiaPDF(_totalsRightLeft, _resultLevelNeuro);
}

const ResetValuesOnStart = () => {
    // console.log("Restarting....")
    //Resetear text
    const fncReset = (rws: ERowTableNeuro[]) => {
        rws.forEach(fnd => {
            fnd.Inp1.value = undefined;
            fnd.Inp2.value = undefined;
            if (fnd.Inp3) fnd.Inp3.value = undefined;
            if (fnd.c6) fnd.c6.value = undefined;
        });
    }

    fncReset(rowsRight);
    fncReset(rowsLeft);

    const fncSetScors = (srcs: eScores[]) => {
        srcs.forEach(item => {
            item.value = 0;
        });
    }
    fncSetScors(scoreMotors);
    fncSetScors(scoreSensory);
}

const ExpedienteNeurologia = ({
    _expediente,
    OnSave,
    isPrintable
}: { _expediente?: IExpediente, OnSave?: (expInfo: IExpediente) => void, isPrintable?: boolean }) => {
    _expedienteInfo = _expediente || null;
    const _disabledEdition = IsHistoryDisabled();

    const [_scores, setScores] = useState([scoreMotors, scoreSensory])

    SetRowsWidthData(_disabledEdition);

    useEffect(ResetValuesOnStart, []);

    return (
        <div className='neurologia-content container-table'>
            <div className='elem-list justify-content-end'>
                <Button type="primary" onClick={() => OnCalculate(setScores)} style={{ marginRight: '15px' }}>Calcular</Button>
                {isPrintable && <Button type="primary" shape="circle" icon={<DownloadOutlined />} size='large' danger onClick={() => OnPrint()} />}
            </div>

            <FullTableImage />

            <div className='main-content'>
                <div className="subscores">
                    <FooterTotals score={_scores[0]} title='Motor' />
                    <FooterTotals score={_scores[1]} title='Sensoriales' />
                </div>
            </div>

            <div className="main-content">
                <div id="TestTotals" data-name="TestTotals" data-controller="IsncsciTotals">
                    <div className="test-totals">
                        <div className="block instructions">
                            <div className="title">Niveles<br></br>neurológicos</div>
                            <div className="helper">Pasos 1-6 para<br></br>clasificación</div>
                        </div>
                        <div className="block">
                            <table>
                                <thead>
                                    <tr>
                                        <th>&nbsp;</th>
                                        <th>R</th>
                                        <th>L</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th style={{ "textAlign": "right" }}>1. Sensorial</th>
                                        <td><div className="total" data-name="RightSensory" id="ResRightSensory" data-controller="IsncsciTotals" data-type="Total" data-value="">&nbsp;</div></td>
                                        <td><div className="total" data-name="LeftSensory" id="ResLeftSensory" data-controller="IsncsciTotals" data-type="Total" data-value="">&nbsp;</div></td>
                                    </tr>
                                    <tr>
                                        <th style={{ "textAlign": "right" }}>2. Motor</th>
                                        <td><div className="total" data-name="RightMotor" id="ResRightMotor" data-controller="IsncsciTotals" data-type="Total" data-value="">&nbsp;</div></td>
                                        <td><div className="total" data-name="LeftMotor" id="ResLeftMotor" data-controller="IsncsciTotals" data-type="Total" data-value="">&nbsp;</div></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="block">
                            <table>
                                <tbody><tr>
                                    <th>3. Nivel neurológico<br></br>de lesión<br></br>(NLI)</th>
                                    <td><div className="total" style={{ "width": "50px" }} data-name="NeurologicalLevelOfInjury" id='NeurologicalLevelOfInjury' data-controller="IsncsciTotals" data-type="Total" data-value="">&nbsp;</div></td>
                                </tr>
                                </tbody></table>
                        </div>
                        <div className="block">
                            <table>
                                <tbody>
                                    <tr>
                                        <td style={{ "textAlign": "right" }}>
                                            <div className="title">4. Completo o incompleto?</div>
                                            <div className="helper">Incompleto = Cualquier función sensorial o motora en S4-5</div>
                                        </td>
                                        <td><div className="total" style={{ "width": "50px" }} data-name="Completeness" id='completeIncomplete' data-controller="IsncsciTotals" data-type="Total" data-value="">&nbsp;</div></td>
                                    </tr>
                                    <tr>
                                        <th style={{ "textAlign": 'right' }}>5. Escala de deterioro de Asia (AIS)</th>
                                        <td><div className="total" style={{ "width": "50px" }} data-name="AsiaImpairmentScale" id='AsiaImpairmentScale' data-controller="IsncsciTotals" data-type="Total" data-value="">&nbsp;</div></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="block">
                            <div className="helper">(En lesiones con ausencia de función motora o sensorial solo en S4-5)</div>
                            <div className="zpp">
                                <div style={{ "textAlign": 'center' }}>
                                    <div className="title margin-top">6. Zona de conservación<br></br>parcial</div>
                                    <div className="helper">Nivel más caudal con cualquier<br></br>inervación</div>
                                </div>
                                <div>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>&nbsp;</th>
                                                <th>R</th>
                                                <th>L</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th style={{ "textAlign": 'right' }}>Sensorial</th>
                                                <td><div className="total" data-name="RightSensoryZpp" id='RightSensoryZpp' data-controller="IsncsciTotals" data-type="Total" data-value="">&nbsp;</div></td>
                                                <td><div className="total" data-name="LeftSensoryZpp" id='LeftSensoryZpp' data-controller="IsncsciTotals" data-type="Total" data-value="">&nbsp;</div></td>
                                            </tr>
                                            <tr>
                                                <th style={{ "textAlign": 'right' }}>Motor</th>
                                                <td><div className="total" data-name="RightMotorZpp" id='RightMotorZpp' data-controller="IsncsciTotals" data-type="Total" data-value="">&nbsp;</div></td>
                                                <td><div className="total" data-name="LeftMotorZpp" id='LeftMotorZpp' data-controller="IsncsciTotals" data-type="Total" data-value="">&nbsp;</div></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="show-all" data-name="ShowAll" data-controller="IsncsciTotals"></div>
                <ul className="all-totals" data-name="AllTotals" data-controller="IsncsciTotals"></ul>
                <div className="busy-indicator-container" data-name="BusyIndicator" data-controller="NeurologyTest">
                    <div id="CalcIndicator">
                        <div id="CalcIndicatorCalculating">
                            <p>Calculating, please wait</p>
                        </div>
                    </div>
                </div>
                <div className="range-dialog" data-controller="IsncsciTotals" data-name="RangeDialog">
                    <div className="frame">
                        <div className="content" data-controller="IsncsciTotals" data-name="RangeDialogContent"></div>
                    </div>
                </div>

                <script type="text/html" data-name="TotalsTemplate" data-controller="IsncsciTotals">
                    <div className="test-totals">
                        <div className="block instructions">
                            <div className="title">Neurological<br />levels</div>
                            <div className="helper">Steps 1-6 for<br />classification</div>
                        </div>
                        <div className="block">
                            <table>
                                <thead>
                                    <tr>
                                        <th>&nbsp;</th>
                                        <th>R</th>
                                        <th>L</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th style={{ "textAlign": "right" }}>1. Sensory</th>
                                        <td><div className="total" data-name="RightSensory" data-controller="IsncsciTotals" data-type="Total" data-value="">&nbsp;</div></td>
                                        <td><div className="total" data-name="LeftSensory" data-controller="IsncsciTotals" data-type="Total" data-value="">&nbsp;</div></td>
                                    </tr>
                                    <tr>
                                        <th style={{ "textAlign": "right" }}>2. Motor</th>
                                        <td><div className="total" data-name="RightMotor" data-controller="IsncsciTotals" data-type="Total" data-value="">&nbsp;</div></td>
                                        <td><div className="total" data-name="LeftMotor" data-controller="IsncsciTotals" data-type="Total" data-value="">&nbsp;</div></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="block">
                            <table>
                                <tbody>
                                    <tr>
                                        <th>3. Neurological<br />Level of Injury<br />(NLI)</th>
                                        <td><div className="total" style={{ "width": "50px" }} data-name="NeurologicalLevelOfInjury" data-controller="IsncsciTotals" data-type="Total" data-value="">&nbsp;</div></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="block">
                            <table>
                                <tbody>
                                    <tr>
                                        <td style={{ "textAlign": "right" }}>
                                            <div className="title">4. Complete or incomplete?</div>
                                            <div className="helper">Incomplete = Any sensory or motor funtion in S4-5</div>
                                        </td>
                                        <td><div className="total" style={{ "width": "50px" }} data-name="Completeness" data-controller="IsncsciTotals" data-type="Total" data-value="">&nbsp;</div></td>
                                    </tr>
                                    <tr>
                                        <th style={{ "textAlign": "right" }}>5. Asia Impairment Scale (AIS)</th>
                                        <td><div className="total" style={{ "width": "50px" }} data-name="AsiaImpairmentScale" data-controller="IsncsciTotals" data-type="Total" data-value="">&nbsp;</div></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="block">
                            <div className="helper">(In injuries with absent motor OR sensory function in S4-5 only)</div>
                            <div className="zpp">
                                <div style={{ "textAlign": 'center' }}>
                                    <div className="title margin-top">6. Zone of partial<br />preservation</div>
                                    <div className="helper">Most caudal level with any<br />innervation</div>
                                </div>
                                <div>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>&nbsp;</th>
                                                <th>R</th>
                                                <th>L</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th style={{ "textAlign": "right" }}>Sensory</th>
                                                <td><div className="total" data-name="RightSensoryZpp" data-controller="IsncsciTotals" data-type="Total" data-value="">&nbsp;</div></td>
                                                <td><div className="total" data-name="LeftSensoryZpp" data-controller="IsncsciTotals" data-type="Total" data-value="">&nbsp;</div></td>
                                            </tr>
                                            <tr>
                                                <th style={{ "textAlign": "right" }}>Motor</th>
                                                <td><div className="total" data-name="RightMotorZpp" data-controller="IsncsciTotals" data-type="Total" data-value="">&nbsp;</div></td>
                                                <td><div className="total" data-name="LeftMotorZpp" data-controller="IsncsciTotals" data-type="Total" data-value="">&nbsp;</div></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </script>
                <div style={{ display: "none" }} data-name="ExportFormContainer" data-controller="NeurologyTest"></div>
            </div>


            {OnSave && !_disabledEdition && <div className='elem-list justify-content-between'>
                <Button type='primary' className='ml-auto' onClick={() => OnSave(_expediente)}>
                    GUARDAR
                </Button>
            </div>}
        </div>
    )
}

export default ExpedienteNeurologia;