import { USER_INFO } from '../../../data/General';

import './Actions.scss';

// import Notifications from './Notifications';
import SettingsDropdown from './SettingsDropdown';

const Actions = () => (
  <div className='actions'>
    {/* <Notifications /> */}
    <span style={{ fontSize: '18px', cursor: 'pointer', color: "#336cfb" }}>{USER_INFO.name ? USER_INFO.name.split(' ')[0] : ''}</span>
    <SettingsDropdown />
  </div>
);

export default Actions;
