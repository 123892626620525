import App_pubMedular from "./APPMedular/App_pubMedular";
import AppDiscapacidad from "./APPValDiscapacidad/App_disc";
import App from "./App";
import { SetDBName } from "./data/DB";
import { _DATABASE_NAME } from "./data/General";
import { useHideLoader } from "./hooks/useHideLoader";
import { APP_IDENTIFIER } from "./interfaces/app-state";

const APPResult = () => {
    useHideLoader();

    SetDBName(_DATABASE_NAME);

    const appId = import.meta.env.APP_ID;
    if (appId == APP_IDENTIFIER.APP_MEDULAR) {
        return <App_pubMedular />
    } else if (appId == APP_IDENTIFIER.APP_DISCAPACIDAD) {
        return <AppDiscapacidad></AppDiscapacidad>
    } else {
        return <App></App>
    }
}

export default APPResult;