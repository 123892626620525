import { Avatar, Dropdown } from 'antd';
import { NavLink } from 'react-router-dom';
import { USER_INFO, SetProfileImage, _APP_ID, accountDrop } from '../../../data/General';
import { APP_IDENTIFIER } from '../../../interfaces/app-state';
import { confDropMenu } from '../../../APPValDiscapacidad/Model/General';

const SettingsDropdown = () => {
  const settDrop = _APP_ID == APP_IDENTIFIER.APP_DISCAPACIDAD ? confDropMenu() : accountDrop;

  const accountMenu = () => (
    <ul className='actions-menu' style={{ minWidth: '180px' }}>
      {settDrop.map((item, index) => (
        <li className='action-item' key={index}>
          <NavLink className='d-flex w-100' to={item.route} state={USER_INFO} replace onClick={() => {
            if (item.id == -100) {//LogOut
              localStorage.clear();
              location.reload();
            }
          }
          }>
            <span className={`icon mr-3 ${item.icon}`} />
            <span className='text'>{item.text}</span>
          </NavLink>
        </li>
      ))}
    </ul>
  );

  return (
    <Dropdown dropdownRender={accountMenu} trigger={['click']} placement='bottomRight'>
      <div className='item'>
        <Avatar
          size={40}
          className='mr-1'
          src={SetProfileImage(USER_INFO.img as string)}
        />
        <span className='icofont-simple-down' />
      </div>
    </Dropdown>
  );
};

export default SettingsDropdown;
