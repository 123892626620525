import { LoadAppointmentsV2, ValidAndSetAppointments, mapAppointments } from "../../data/Appointment";
import { USER_INFO, _API } from "../../data/General";
import { LoadPatients } from "../../data/Patient";
import { RequestData } from "../../data/RequestData";
import { ValidAndSetUsers, mapUsers } from "../../data/User";
import { IResultRequest } from "../../interfaces/Entities";
import { IPatient } from "../../interfaces/patient";
import { IStudent } from "../interfaces/Student";
import { useFetchRequestData } from "./DispatchRequest";

export const SetStudentData = (student: IStudent, toUpdate: boolean, completion: (result: number) => void) => {
    let params = {
        idUser: USER_INFO.id,
        matricula: +student.matricula,
        name: student.name,
        lastname: student.lastname,
        birthDate: student.birthDate,
        gender: student.gender,
        phone: student.phone,
        address: student.address,
        email: student.email
    }

    if (toUpdate) params['id'] = student.id;

    let urlReq = `${_API}student/SetStudent`;

    new RequestData(urlReq, 'POST')
        .SetParams(params)
        .SetCallback(response => {
            let success = response ? response.success : -100;
            completion(success);
        }).Request();
}

export const _SetStudentInfo = (student: IStudent) => {
    mapUsers.set(student.id, student);
}

export function GetPatientsForm(idUser: number): [Array<IPatient>, (data: Array<IPatient>) => void] {
    const [listAppointment, __] = LoadAppointmentsV2();
    const [listPats, _] = LoadPatients();

    var resultList = [];
    if (listAppointment.length > 0 && listPats.length > 0) {
        const tempLst = listAppointment.filter(o => o.createdBy == idUser);
        listPats.forEach(item => {
            if (tempLst.findIndex(o => o.idPatient == item.id) > -1)
                resultList.push(item);
        });
    }

    return [resultList, null]
}

export const GetStudents = (): [Array<IStudent>, (data: Array<IStudent>) => void] => {
    let students = Array.from(mapUsers.values());
    students.sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime());

    let maxUpdated = null;
    if (students.length > 0) maxUpdated = students[0].updatedAt;

    let urlReq = `${_API}student/GetStudents?lastUpdated=${maxUpdated}`;

    const [_, SetData] = useFetchRequestData<IResultRequest<IStudent[]>>(urlReq, { data: students as any, success: 1, message: null }, (reqResult) => {
        if (reqResult && reqResult.success > 0 && reqResult.data.length > 0) {
            ValidAndSetUsers(reqResult.data);
            //Actualizar lista pacientes.
            ValidAndSetAppointments(Array.from(mapAppointments.values()));
        }
    });

    const fnc = (resDta: Array<IStudent>): void => {
        SetData({ success: 1, message: "", data: resDta });
    }

    let listResult = Array.from(mapUsers.values()) as IStudent[];
    listResult = listResult.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());

    return [listResult, fnc];
}