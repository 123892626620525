export interface IUser {
  id: number;
  name: string;
  lastname: string;
  email: string;
  phone: string;
  gender: number;
  birthDate: string;
  description?: string;
  address: string;
  idPhoto: number;
  typeUser: ETypeUser;
  inUse: boolean;
  pass: string;
  createdBy: number;
  updatedBy: number;
  createdAt: number;
  updatedAt: number;

  age?: number;
  img?: string;
}

export interface IUserLink {
  icon: string;
  link: string;
}

export enum ETypeUser {
  ROOT = 1,
  ADMIN = 2,
  PATIENT = 3,
  STUDENT = 200
}

export const mapTypeUsers: Map<ETypeUser, string> = new Map([
  [ETypeUser.ROOT, "Root"],
  [ETypeUser.ADMIN, "Admin"],
  [ETypeUser.PATIENT, "Patient"],
])
