import { Button, Form, Input, Switch } from 'antd';
import { LoginOutlined } from '@ant-design/icons/lib';

import { Link } from 'react-router-dom';
import { useForm } from 'antd/es/form/Form';
import { useNavigateHome } from '../../utils/use-navigate-home';
import { IsValidEmail } from '../../data/General';
import { IUser } from '../../interfaces/user';
import PublicLayout from '../../layout/public/Public';
import OpenNotification from '../../pages/Toast';

import './login.css'
import { LogginUser, RecoverPass } from '../../data/User';
import { useState } from 'react';

const { Item } = Form;

const ValDiscSignIn = () => {
  //Borrar datos de sesion
  // localStorage.clear();

  const [form] = useForm();
  const navigateHome = useNavigateHome();
  const [enableSendEmail, setEnableSendEmail] = useState(true);

  const LogginCompletion = (succes: number, _: IUser) => {
    if (succes === 1) {
      navigateHome();
      window.location.reload();//VALIDAR:....

    } else if (succes === -3) {
      OpenNotification('Problemas con el acceso', "Usuario y/o contraseña incorrecto", 'error');
    } else {
      OpenNotification('Problemas con el acceso', "¡Ocurrió un error, intentalo de nuevo!", 'error');
    }
  }

  const login = () => {
    form
      .validateFields()
      .then((e) => {
        if (!IsValidEmail(e.user)) return;

        LogginUser(e.user, e.password, LogginCompletion);
      })
      .catch((e) => console.error("erros", e));
  };

  const OnRecoverPass = () => {
    const email = form.getFieldValue('user');

    if (!email || !IsValidEmail(email)) {
      OpenNotification('Recuperar contraseña', 'Introduce un correo válido en el campo de usuario', 'warn');
      return;
    }

    if (!enableSendEmail) return;

    setEnableSendEmail(false);

    RecoverPass(email, (res) => {
      if (res > 0) {
        OpenNotification('Recuperar contraseña', 'Se ha enviádo un correo con la informacion de la cuenta', 'success');
      } else {
        OpenNotification('Recuperar contraseña', 'Ocurrió un error al enviar información en el correo.', 'error');
      }

      setTimeout(() => setEnableSendEmail(true), 5000);
    });
  }

  return (
    // <PublicLayout bgImg={`${window.origin}/content/login_background.png`}>
    <PublicLayout bgImg={`${window.origin}/content/back-val-disc.jpg`}>
      <div className='new-img'></div>
      <h4 className='mt-0 mb-1'>Iniciar Sesión</h4>

      <p className='text-color-200'>Captura tus datos de usuario</p>

      <Form form={form} layout='vertical' className='mb-4'>
        <Item name='user' rules={[{ required: true, message: <></> }]}>
          <Input placeholder='Usuario' type='email' />
        </Item>
        <Item name='password' rules={[{ required: true, message: <></> }]}>
          <Input placeholder='Contraseña' type='password' />
        </Item>

        <div className='d-flex align-items-center mb-4'>
          <Switch defaultChecked /> <span className='ml-2'>Recordarme</span>
        </div>

        <Button
          block={false}
          type='primary'
          onClick={login}
          htmlType='submit'
          icon={<LoginOutlined style={{ fontSize: '1.3rem' }} />} >
          Iniciar sesion
        </Button>
      </Form>
      <br />
      <p className='mb-2'>
        <a href='/#' onClick={() => OnRecoverPass()}>Recuperar contraseña!.</a>
      </p>

      <p>
        No tienes cuenta? <Link to='https://drpedroreyes.com/'>Contactanos!</Link>
      </p>
    </PublicLayout>
  );
};

export default ValDiscSignIn;
