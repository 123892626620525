import { useState } from 'react';

import PageAction from '../../layout/components/page-action/PageAction';

import { usePageData } from '../../hooks/usePage';

import { IUser } from '../../interfaces/user';
import { IPageData } from '../../interfaces/page';
import { GetUsers } from '../../data/User';
import { useLocation, useNavigate } from 'react-router-dom';
import UsersTable from './UsersTable';

const pageData: IPageData = {
  title: 'Usuarios',
  fulFilled: false,
  // OnChangeSearch: OnSearchResult,
  breadcrumbs: [
    {
      title: 'Principal',
      route: 'default-dashboard'
    },
    {
      title: 'Usuarios'
    }
  ]
};


const UsersPage = () => {
  usePageData(pageData);
  const history = useNavigate();
  const location = useLocation();/// New v6

  const [users] = GetUsers();
  const [searchValue, setSearchValue] = useState('');

  // const [searchData, setSearchData] = useState(users);
  var filterList: IUser[];
  if (searchValue === "") filterList = users;
  else filterList = users.filter(o => o.name.toLowerCase().includes(searchValue.toLowerCase()) || (o.lastname && o.lastname.toLowerCase().includes(searchValue.toLowerCase())));

  pageData.OnChangeSearch = (res) => {
    setSearchValue(res);
  }

  const layout = location.pathname.split('/')[1];
  const OnAddNew = () => history(`/${layout}/edit-account`);


  const handleGoToProfile = (user: IUser) => {
    const layout = location.pathname.split('/')[1];
    history(`/${layout}/user-profile`, { state: user });
  };

  const handleGoToEdit = (user: IUser) => {
    const layout = location.pathname.split('/')[1];
    history(`/${layout}/edit-account`, { state: user });
  }

  return (
    <>
      <UsersTable
        onViewProfile={handleGoToProfile}
        onEditUser={handleGoToEdit}
        users={filterList} />

      <PageAction onClick={OnAddNew} icon='icofont-contact-add' />

    </>
  );
};

export default UsersPage;
