import { Card } from 'antd';

import ReactEcharts from 'echarts-for-react';

import {
  patientsGenderOptions,
  departmentsOptions,
  patientsAgeOptions
} from '../../pages/dashboards/charts/patients-options';

import { useFetchPageData, usePageData } from '../../hooks/usePage';

import { IPageData } from '../../interfaces/page';
import { _API } from '../../data/General';
import { fetchPatients } from '../../redux/patients/actions';
import { LoadPatients } from '../../data/Patient';
import { IAppointment, IPatient } from '../../interfaces/patient';
import { LoadAppointmentsV2 } from '../../data/Appointment';
import { useNavigate } from 'react-router-dom';
import { IDashboardInfo } from '../interfaces/Student';
import FormularioTable from './formulario/FormularioTable';
import { GetStudents } from '../Model/Student';


const pageData: IPageData = {
  fulFilled: false,
  breadcrumbs: [
    {
      title: 'Dashboards',
      route: 'home'
    },
    {
      title: 'Default'
    }
  ]
};



const OnSummaryResult = (summary: { data: IDashboardInfo }, patients: IPatient[]): IDashboardInfo => {
  var _summary: IDashboardInfo = summary ? summary.data : { students: 0, users: 0, expedientes: 0, cuello: 0, espalda: 0, ambos: 0, patients_female: -1, patients_male: -1 };
  _summary.patients = _summary.patients_female + _summary.patients_male;
  _summary.formularios = _summary.cuello + _summary.espalda + _summary.ambos;

  patientsGenderOptions.series[0].data = [];
  if (_summary.patients_female > -1 && _summary.patients_male > -1) {
    patientsGenderOptions.series[0].data = [
      { value: _summary.patients_female, name: 'Mujeres' },
      { value: _summary.patients_male, name: 'Hombres' }
    ];
  }

  departmentsOptions.series[0].data = [];
  if (_summary.users > 0) {
    departmentsOptions.series[0].data = [
      { value: _summary.espalda, name: 'Espalda' },
      { value: _summary.cuello, name: 'Cuello' },
      { value: _summary.ambos, name: 'Ambos' }
    ];

  }

  patientsAgeOptions.series[0].data = [];
  if (patients && patients.length > 0) {
    patientsAgeOptions.series[0].data = [
      { value: 0, name: "0-10" },
      { value: 0, name: "10-20" },
      { value: 0, name: "20-30" },
      { value: 0, name: "30-40" },
      { value: 0, name: "40+" }
    ]

    patients.forEach(item => {
      let agePos = Math.floor(item.age / 10);
      if (agePos > 4) agePos = 4;

      patientsAgeOptions.series[0].data[agePos].value += 1;
    });
  }

  return _summary;
}

const CardTotal = (
  { title, value, icon }: { title: string, value: number, icon: string }
) => {
  return (
    <div className='col-12 col-md-6 col-xl-3'>
      <Card style={{ background: 'rgba(251, 251, 251)' }} className='animated with-shadow'>
        <div className='row'>
          <div className='col-5'>
            <span
              className={'icofont ' + icon}
              style={{ fontSize: 48, color: 'rgba(51, 108, 251, 0.5)' }}
            />
          </div>

          <div className='col-7'>
            <h6 className='mt-0 mb-1'>{title}</h6>
            <div className='count' style={{ fontSize: 20, color: '#336cfb', lineHeight: 1.4 }}>
              {value}
            </div>
          </div>
        </div>
      </Card>
    </div>
  )
}

fetchPatients()

const DashboardDisc = () => {
  usePageData(pageData);

  GetStudents();
  const [patientsResult] = LoadPatients();
  const [appointments] = LoadAppointmentsV2();
  const [summary] = useFetchPageData<any>(_API + "user/DataSummaryStudent");

  const history = useNavigate();

  const patients = appointments.slice(0, 5);

  var _summary = OnSummaryResult(summary, patientsResult);

  const OnRowSelected = (appointment: IAppointment) => {
    history(`/vertical/formulario-detail`, { state: appointment });
  }

  return (
    <>
      <div className='row'>

        <CardTotal title='Alumnos' value={_summary.students} icon='icofont-student' />
        <CardTotal title='Pacientes' value={_summary.patients} icon='icofont-ambulance' />
        <CardTotal title='Formularios' value={_summary.formularios} icon='icofont-ui-note' />
        <CardTotal title='Expedientes' value={_summary.expedientes} icon='icofont-blood' />

      </div>


      <div className='row'>
        <div className='col-12 col-md-4'>
          <Card title={'Edades de pacientes'}>
            {patientsAgeOptions.series[0].data.length > 0 &&
              <ReactEcharts className='chart-container container-h-300' option={patientsAgeOptions} lazyUpdate />
            }
          </Card>
        </div>

        <div className='col-12 col-md-4'>
          <Card title={'Generos'}>
            {patientsGenderOptions.series[0].data.length > 0 &&
              <ReactEcharts className='chart-container container-h-300' option={patientsGenderOptions} />
            }
          </Card>
        </div>
      </div>

      <Card title='Ultimos formularios' className='mb-0'>
        <FormularioTable data={patients} OnRowSelected={OnRowSelected} />
      </Card>
    </>
  );
};

export default DashboardDisc;
