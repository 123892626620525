import { _API } from "../../data/General";
import { useFetchPageData } from "../../hooks/usePage";
import { IResultRequest } from "../../interfaces/Entities";
import { IQuestion } from "../interfaces/Pain";

const mapQuestions: Map<number, IQuestion> = new Map();

export function LoadQuestion(): [Array<IQuestion>, (data: Array<IQuestion>) => void] {
    let _pts = Array.from(mapQuestions.values());
    _pts.sort((a, b) => new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime());

    let maxUpdated = null;
    if (_pts.length > 0) maxUpdated = _pts[0].updated_at;

    let urlReq = `${_API}question/GetQuestions?lastUpdated=${maxUpdated}`;
    const [dataResult, SetDataTemp] = useFetchPageData<IResultRequest<IQuestion[]>>(urlReq);

    if (dataResult && dataResult.success == 1) {
        ValidAndSetQuestions(dataResult.data);
    }

    const fnc = (resDta: Array<IQuestion>): void => {
        SetDataTemp({ success: 1, message: "", data: resDta });
    }

    let listResult = Array.from(mapQuestions.values());
    listResult = listResult.sort((a, b) => new Date(b.id).getTime() - new Date(a.id).getTime());
    return [listResult, fnc];
}

const ValidAndSetQuestions = (data: IQuestion[]) => {
    data.forEach(item => {
        if (!item.in_use)
            mapQuestions.delete(item.id)
        else {

            if (typeof item.answers === 'string')
                item.answers = JSON.parse(item.answers as any);
            mapQuestions.set(item.id, item);
        }
    });
}

export function GetQuestionByCategory(category: number): IQuestion[] {
    const listQ = Array.from(mapQuestions.values());
    const temp = listQ.filter(o => o.category == category);
    temp.map(o => o.selectedValue = null);
    return temp;
}