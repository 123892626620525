import { useState } from 'react';
import { Button, Modal } from 'antd';
import { IPageData } from '../../../interfaces/page';
import { usePageData } from '../../../hooks/usePage';
import { LoadPatients } from '../../../data/Patient';
import { LoadAppointmentsV2, RemoveAppointment } from '../../../data/Appointment';
import { IAppointment } from '../../../interfaces/patient';
import { useLocation, useNavigate } from 'react-router';
import OpenNotification from '../../../pages/Toast';
import FormularioTable from './FormularioTable';
import { GetStudents } from '../../Model/Student';
import { GetExpedienteByIdFormulario, LoadExpedientes } from '../../../data/Expediente';
import { IStudent } from '../../interfaces/Student';

const pageData: IPageData = {
  title: 'Formularios',
  fulFilled: false,
  breadcrumbs: [
    {
      title: 'Principal',
      route: 'home'
    },
    {
      title: 'Formularios'
    }
  ]
};


const FormularioPage = () => {
  usePageData(pageData);
  LoadPatients();
  GetStudents();
  LoadExpedientes();

  const location = useLocation();
  const studentInfo = location.state as IStudent;
  const idStudent = studentInfo?.id || 0;

  const [appointments, setAppointments] = LoadAppointmentsV2(idStudent);
  const [searchValue, setSearchValue] = useState('');

  var filterList: IAppointment[];
  if (searchValue === "") filterList = appointments;
  else filterList = appointments.filter(o => {
    return (o._patient && o._patient.name.toLowerCase().includes(searchValue.toLowerCase()) || (o._patient && o._patient.lastname && o._patient.lastname.toLowerCase().includes(searchValue.toLowerCase())));
  });

  pageData.OnChangeSearch = (res) => {
    setSearchValue(res);
  }

  const history = useNavigate();

  const handleDelete = async (ev: React.MouseEvent<HTMLElement, MouseEvent>, appointment: IAppointment) => {
    ev.stopPropagation();

    const config = GetConfigModal(appointment)

    const confirmed = await _modal.confirm(config);
    if (confirmed) {
      RemoveAppointment(appointment, (result) => {
        if (result > 0) {
          appointment.inUse = 0;
          appointments.find((el) => el.id == appointment.id).inUse = 0;
          setAppointments(appointments);

          OpenNotification('Eliminado!', "Se eliminó la cita");
        } else {
          OpenNotification('¡Error!', "Ocurrió un error al guardar", 'error');
        }
      });
    }
  };

  const historyDetail = async (ev: React.MouseEvent<HTMLElement, MouseEvent>, appointment: IAppointment) => {
    ev.stopPropagation();

    const expediente = GetExpedienteByIdFormulario(appointment.id);

    if (!expediente) {
      OpenNotification('Expediente no encontrado', 'Este formulario no tiene un expediente valido', 'warn');
      return;
    }

    if (expediente.formComplete)
      history(`/vertical/history-expedientepage`, { state: [appointment._patient, expediente] });
    else
      history(`/vertical/history-neurologia`, { state: { patient: appointment._patient, expediente: expediente } });
  };

  const appointmentsActions = (appointment: IAppointment) => (
    <div className='buttons-list nowrap'>
      <Button shape='circle' onClick={(_) => OnRowSelected(appointment)}>
        <span className='icofont icofont-file-text' />
      </Button>
      <Button shape='circle' onClick={(ev) => historyDetail(ev, appointment)}>
        <span className='icofont icofont-chart-histogram' />
      </Button>
      <Button onClick={(ev) => handleDelete(ev, appointment)} shape='circle' danger>
        <span className='icofont icofont-ui-delete' />
      </Button>
    </div >
  );

  const OnRowSelected = (appointment: IAppointment) => {
    history("/vertical/formulario-detail", { state: appointment });
  }

  //Modal config
  const [_modal, contextHolder] = Modal.useModal();

  const GetConfigModal = (appt: IAppointment) => {
    return {
      title: 'Eliminar formulario!',
      content: (
        <>
          {`Deseas eliminar el formulario de: ${appt._patient.name + ' ' + (appt._patient.lastname ? appt._patient.lastname : '')}, de la fecha: ${appt.createdAt.split(' ')[0]} ?`}
        </>
      ),
    };
  }


  return (
    <>
      <FormularioTable data={filterList} actions={appointmentsActions} />
      {contextHolder}
    </>
  );
};

export default FormularioPage;
