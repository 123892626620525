import { USER_INFO } from "../../data/General";
import { ETypeUser } from "../../interfaces/user";
import { IBtnTypePain } from "../interfaces/Pain";

const accountDropDiscapacidad = [
    // { id: 1, text: 'Editar cuenta', icon: 'icofont-ui-home', route: '/vertical/edit-student' },
    { id: 2, text: 'Perfil', icon: 'icofont-ui-user', route: '/vertical/user-profile' },
    { id: -100, text: 'Cerrar sesión', icon: 'icofont-logout', route: 'home' }
];

export const confDropMenu = () => {
    if (!USER_INFO) return [];

    if (USER_INFO.typeUser == ETypeUser.STUDENT)
        return accountDropDiscapacidad;
    else {
        if (accountDropDiscapacidad.findIndex(o => o.id == 1) == -1)
            accountDropDiscapacidad.unshift({ id: 1, text: 'Editar cuenta', icon: 'icofont-ui-home', route: '/vertical/edit-student' });
        return accountDropDiscapacidad;
    }
}

export const tlistPainType: IBtnTypePain[] = [
    { id: 1, idStr: 'arrow-pain-1', label: 'Dolor', color: '#f65451' },
    { id: 2, idStr: 'arrow-pain-2', label: 'Entumecimiento', color: '#49729b' },
    { id: 3, idStr: 'arrow-pain-3', label: 'Pines(Piquetes) y alfileres', color: '#5f8e8e' },
    { id: 4, idStr: 'arrow-pain-4', label: 'Ardiente', color: '#14aac3' },
    { id: 5, idStr: 'arrow-pain-5', label: 'Puñalada', color: '#2fcb4b' },
    { id: 6, idStr: 'arrow-pain-6', label: 'Palpitante', color: '#9d724f' },
]