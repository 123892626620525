import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { IPageData } from '../../../interfaces/page';
import UserPreviewHeader from '../ExpedienteHeaderPatient';
import { IPatient } from '../../../interfaces/patient';
import { usePageData } from '../../../hooks/usePage';
import { IExpediente } from '../../../interfaces/expediente';
import { useHideLoader } from '../../../hooks/useHideLoader';
import Table, { ColumnProps } from 'antd/es/table';

// import './History.scss';
import './expList.scss';
import { LoadExpedientes } from '../../../data/Expediente';
import { GetDescriptiveDate, HistoryRedirect, IsHistoryDisabled } from '../../../data/General';


const pageData: IPageData = {
    title: 'Expedientes',
    fulFilled: true,
    hasBack: true,
    breadcrumbs: [
        {
            title: 'Principal',
            route: 'default-dashboard'
        },
        {
            title: 'Historial',
            route: 'historial'
        },
        {
            title: 'Lista de expedientes'
        }
    ]
};


const UserPreview = ({
    patient
}: { patient: IPatient }) => {
    usePageData(pageData);

    const _disabledEdition = IsHistoryDisabled();

    return (
        <>
            <UserPreviewHeader patient={patient} enableBtnAdd={!_disabledEdition} />
        </>
    )
};

const ListDataTable = ({
    exps,
    patient
}: { exps: IExpediente[], patient: IPatient }) => {

    useHideLoader()
    const history = useNavigate();

    const handleRowSelected = (exp: IExpediente) => {
        const layout = location.hash.split('/')[1];
        history(`/${layout}/history-expedientepage`, { state: [patient, exp] });
    };

    const columns: ColumnProps<IExpediente>[] = [
        {
            key: 'id',
            dataIndex: 'id',
            title: '#',
            render: (_, __, i) => <strong>{i + 1}</strong>
        },
        {
            key: 'CreatedAt',
            dataIndex: 'CreatedAt',
            title: 'Fecha de creación',
            render: (visit) => (
                <span className='nowrap' style={{ color: '#a5a5a5' }}>
                    {GetDescriptiveDate(visit)}
                </span>
            )
        },
        {
            key: 'questionPage1',
            dataIndex: 'questionPage1',
            title: 'Cuestionario Pag1',
            render: (qst: any[]) => (
                <span className={'icon ' + (qst.length == 0 ? 'icofont-spinner-alt-4' : 'icofont-check-circled')} />
            )
        },
        {
            key: 'questionPage2',
            dataIndex: 'questionPage2',
            title: 'Cuestionario Pag2',
            render: (qst: any[]) => (
                <span className={'icon ' + (qst.length == 0 ? 'icofont-spinner-alt-4' : 'icofont-check-circled')} />
            )
        },
        {
            key: 'ISNCSCI',
            dataIndex: 'ISNCSCI',
            title: 'ISNCSCI',
            render: (qst: any[]) => (
                <span className={'icon ' + (qst.length == 0 ? 'icofont-spinner-alt-4' : 'icofont-check-circled')} />
            )
        },
        {
            key: 'UpdatedAt',
            dataIndex: 'UpdatedAt',
            title: 'Última actualización',
            render: (visit) => (
                <span className='nowrap' style={{ color: '#a5a5a5' }}>
                    {visit}
                </span>
            )
        }
    ];

    const pagination = !exps || exps.length <= 10 ? false : {};

    return (
        <>
            <Table
                pagination={pagination}
                className='accent-header tbl-exp-list'
                rowKey='id'
                dataSource={exps}
                onRow={(record, _) => {
                    return {
                        onClick: _ => handleRowSelected(record)
                    };
                }}
                columns={columns} />
        </>
    );

}

const ExpedienteList = () => {
    const history = useNavigate();
    const location = useLocation();
    const patient: IPatient = location.state

    if (!patient || (Object.keys(patient).length === 0 && patient.constructor === Object)) {
        history(HistoryRedirect());
        return null;
    }

    const [_listExp] = LoadExpedientes({ idPatient: patient.id });


    return (
        <>
            <UserPreview patient={patient} />
            <ListDataTable exps={_listExp} patient={patient} />
        </>
    )
}

export default ExpedienteList;