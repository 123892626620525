import { EChooseNumNeuro, EInputValue } from "./BtnChoose"

type EOptsCmb = {
    value: string,
    id: string
}

export type eScores = {
    id: string,
    label: string,
    value?: any,
    tag: string
}

export const scoreMotors: eScores[] = [
    { id: 'UER', label: "ESD", tag: "Max (25)", value: 0 },
    { id: 'UEL', label: "+ ESI", tag: "(25)", value: 0 },
    { id: 'UEMS', label: "= UEMS Total", tag: "(50)", value: 0 },
    { id: 'LER', label: "EID", tag: "Max (25)", value: 0 },
    { id: 'LEL', label: "+ EII", tag: "(25)", value: 0 },
    { id: 'LEMS', label: "= LEMS Total", tag: "(50)", value: 0 },
]

export const scoreSensory: eScores[] = [
    { id: 'LTR', label: "LTR", tag: "Max (56)", value: 0 },
    { id: 'LTL', label: "+ LTL", tag: "(56)", value: 0 },
    { id: 'LTT', label: "= LT Total", tag: "(112)", value: 0 },
    { id: 'PPR', label: "PPR", tag: "Max (56)", value: 0 },
    { id: 'PPL', label: "+ PPL", tag: "(56)", value: 0 },
    { id: 'PPT', label: "= PP Total", tag: "(112)", value: 0 },
]

export type ERowTableNeuro = {
    id: number,
    identifier: string,
    dataId: string,
    Inp1: EInputValue,
    Inp2: EInputValue,
    Inp3?: EInputValue,
    CLabel: string,
    c5?: string,
    c6?: { label?: string, type: 'SPAN' | 'COMBOBOX' | 'TEXT_AREA', options?: EOptsCmb[], class?: string, value?: any },
    disabled?: boolean;
}

const valuesMotrRightLeft: EOptsCmb[] = [
    { id: "0", value: "" },
    { id: "4", value: "C5 - Shoulder: Flexion, extension, abduction, adduction, internal and external rotation - Elbow: Supination" },
    { id: "5", value: "C6 - Elbow: Pronation - Wrist: Flexion" },
    { id: "6", value: "C7 - Finger: Flexion at proximal joint, extension. Thumb: Flexion, extension and abduction in plane of thumb" },
    { id: "7", value: "C8 - Finger: Flexion at MCP joint Thumb: Opposition, adduction and abduction perpendicular to palm" },
    { id: "8", value: "T1 - Finger: Abduction of the index finger" },
    { id: "21", value: "L2 - Hip: Adduction" },
    { id: "22", value: "L3 - Hip: External rotation" },
    { id: "23", value: "L4 - Hip: Extension, abduction, internal rotation - Knee: Flexion - Ankle: Inversion and eversion - Toe: MP and IP extension" },
    { id: "24", value: "L5 - Hallux and Toe: DIP and PIP flexion and abduction" },
    { id: "25", value: "S1 - Hallux: Adduction" }
]

const valuesVocAnlContr: EOptsCmb[] = [
    { value: "", id: "None" },
    { value: "Yes", id: "Yes" },
    { value: "No", id: "No" },
    { value: "NT", id: "Nt" },
]

export const rowsRight: ERowTableNeuro[] = [
    { id: 1, identifier: "C2", dataId: 'C2_Right', Inp1: { option: EChooseNumNeuro.FIRST }, Inp2: { option: EChooseNumNeuro.FIRST }, CLabel: "C2" },
    { id: 2, identifier: "C3", dataId: 'C3_Right', Inp1: { option: EChooseNumNeuro.FIRST }, Inp2: { option: EChooseNumNeuro.FIRST }, CLabel: "C3" },
    { id: 3, identifier: "C4", dataId: 'C4_Right', Inp1: { option: EChooseNumNeuro.FIRST }, Inp2: { option: EChooseNumNeuro.FIRST }, CLabel: "C4" },
    { id: 4, identifier: "C5", dataId: 'C5_Right', Inp1: { option: EChooseNumNeuro.FIRST }, Inp2: { option: EChooseNumNeuro.FIRST }, Inp3: { option: EChooseNumNeuro.ALL }, CLabel: "Flexores del codo C5" },
    { id: 5, identifier: "C6", dataId: 'C6_Right', Inp1: { option: EChooseNumNeuro.FIRST }, Inp2: { option: EChooseNumNeuro.FIRST }, Inp3: { option: EChooseNumNeuro.ALL }, CLabel: "Extensores de muñeca C6", c6: { label: 'ESD', type: 'SPAN', class: "location-title" } },
    { id: 6, identifier: "C7", dataId: 'C7_Right', Inp1: { option: EChooseNumNeuro.FIRST }, Inp2: { option: EChooseNumNeuro.FIRST }, Inp3: { option: EChooseNumNeuro.ALL }, CLabel: "Extensores del codo C7", c6: { label: '(Extremidad Superior', type: 'SPAN', class: "location-title" } },
    { id: 7, identifier: "C8", dataId: 'C8_Right', Inp1: { option: EChooseNumNeuro.FIRST }, Inp2: { option: EChooseNumNeuro.FIRST }, Inp3: { option: EChooseNumNeuro.ALL }, CLabel: "Flexores de los dedos C8", c6: { label: 'Derecha)', type: 'SPAN', class: "location-title" } },
    { id: 8, identifier: "T1", dataId: 'T1_Right', Inp1: { option: EChooseNumNeuro.FIRST }, Inp2: { option: EChooseNumNeuro.FIRST }, Inp3: { option: EChooseNumNeuro.ALL }, CLabel: "Abductores de los dedos (dedo meñique) T1" },
    { id: 9, identifier: "T2", dataId: 'T2_Right', Inp1: { option: EChooseNumNeuro.FIRST }, Inp2: { option: EChooseNumNeuro.FIRST }, CLabel: "T2", c6: { label: "Musculos inferiores", type: 'SPAN', class: "bold-title" } },
    { id: 10, identifier: "T3", dataId: 'T3_Right', Inp1: { option: EChooseNumNeuro.FIRST }, Inp2: { option: EChooseNumNeuro.FIRST }, CLabel: "T3", c6: { label: "no claves con", type: 'SPAN', class: "bold-title" } },
    { id: 11, identifier: "T4", dataId: 'T4_Right', Inp1: { option: EChooseNumNeuro.FIRST }, Inp2: { option: EChooseNumNeuro.FIRST }, CLabel: "T4", c6: { label: "función motora:", type: 'SPAN', class: "bold-title" } },
    { id: 12, identifier: "T5", dataId: 'T5_Right', Inp1: { option: EChooseNumNeuro.FIRST }, Inp2: { option: EChooseNumNeuro.FIRST }, CLabel: "T5", c6: { label: "Derecha:", type: 'COMBOBOX', options: valuesMotrRightLeft, class: "cmb-opts-neuro-right" } },
    { id: 13, identifier: "T6", dataId: 'T6_Right', Inp1: { option: EChooseNumNeuro.FIRST }, Inp2: { option: EChooseNumNeuro.FIRST }, CLabel: "T6", c6: { label: "Izquierda:", type: 'COMBOBOX', options: valuesMotrRightLeft, class: "cmb-opts-neuro-left" } },
    { id: 14, identifier: "T7", dataId: 'T7_Right', Inp1: { option: EChooseNumNeuro.FIRST }, Inp2: { option: EChooseNumNeuro.FIRST }, CLabel: "T7" },
    { id: 15, identifier: "T8", dataId: 'T8_Right', Inp1: { option: EChooseNumNeuro.FIRST }, Inp2: { option: EChooseNumNeuro.FIRST }, CLabel: "T8", c6: { label: "Comentarios", type: 'SPAN', class: "bold-title" } },
    { id: 16, identifier: "T9", dataId: 'T9_Right', Inp1: { option: EChooseNumNeuro.FIRST }, Inp2: { option: EChooseNumNeuro.FIRST }, CLabel: "T9", c6: { type: 'TEXT_AREA', class: "txt-area-comments" } },
    { id: 17, identifier: "T10", dataId: 'T10_Right', Inp1: { option: EChooseNumNeuro.FIRST }, Inp2: { option: EChooseNumNeuro.FIRST }, CLabel: "T10" },
    { id: 18, identifier: "T11", dataId: 'T11_Right', Inp1: { option: EChooseNumNeuro.FIRST }, Inp2: { option: EChooseNumNeuro.FIRST }, CLabel: "T11" },
    { id: 19, identifier: "T12", dataId: 'T12_Right', Inp1: { option: EChooseNumNeuro.FIRST }, Inp2: { option: EChooseNumNeuro.FIRST }, CLabel: "T12" },
    { id: 20, identifier: "L1", dataId: 'L1_Right', Inp1: { option: EChooseNumNeuro.FIRST }, Inp2: { option: EChooseNumNeuro.FIRST }, CLabel: "L1" },
    { id: 21, identifier: "L2", dataId: 'L2_Right', Inp1: { option: EChooseNumNeuro.FIRST }, Inp2: { option: EChooseNumNeuro.FIRST }, Inp3: { option: EChooseNumNeuro.ALL }, CLabel: "Flexores de cadera L2", c5: "" },
    { id: 22, identifier: "L3", dataId: 'L3_Right', Inp1: { option: EChooseNumNeuro.FIRST }, Inp2: { option: EChooseNumNeuro.FIRST }, Inp3: { option: EChooseNumNeuro.ALL }, CLabel: "Extensores de rodilla L3", c5: "" },
    { id: 23, identifier: "L4", dataId: 'L4_Right', Inp1: { option: EChooseNumNeuro.FIRST }, Inp2: { option: EChooseNumNeuro.FIRST }, Inp3: { option: EChooseNumNeuro.ALL }, CLabel: "Disflexores de tobillo L4", c6: { label: "EID", type: 'SPAN', class: "location-title" } },
    { id: 24, identifier: "L5", dataId: 'L5_Right', Inp1: { option: EChooseNumNeuro.FIRST }, Inp2: { option: EChooseNumNeuro.FIRST }, Inp3: { option: EChooseNumNeuro.ALL }, CLabel: "Extensores largos de los dedos L5", c6: { label: "(Extremidad Inferior", type: 'SPAN', class: "location-title" } },
    { id: 25, identifier: "S1", dataId: 'S1_Right', Inp1: { option: EChooseNumNeuro.FIRST }, Inp2: { option: EChooseNumNeuro.FIRST }, Inp3: { option: EChooseNumNeuro.ALL }, CLabel: "Flexores planta del tobillo S1", c6: { label: "Derecho)", type: 'SPAN', class: "location-title" } },
    { id: 26, identifier: "S2", dataId: 'S2_Right', Inp1: { option: EChooseNumNeuro.FIRST }, Inp2: { option: EChooseNumNeuro.FIRST }, CLabel: "S2" },
    { id: 27, identifier: "S3", dataId: 'S3_Right', Inp1: { option: EChooseNumNeuro.FIRST }, Inp2: { option: EChooseNumNeuro.FIRST }, CLabel: "S3" },
    { id: 28, identifier: "S4_5", dataId: 'S45_Right', Inp1: { option: EChooseNumNeuro.FIRST }, Inp2: { option: EChooseNumNeuro.FIRST }, CLabel: "S4-5", c6: { label: "(CAV) Contracción anal voluntaria:", type: 'COMBOBOX', options: valuesVocAnlContr, class: "cmb-opts-neuro-anl" } },
];

export const rowsLeft: ERowTableNeuro[] = [
    { id: 1, identifier: "C2", dataId: "C2_Left", Inp1: { option: EChooseNumNeuro.FIRST }, Inp2: { option: EChooseNumNeuro.FIRST }, CLabel: "C2" },
    { id: 2, identifier: "C3", dataId: "C3_Left", Inp1: { option: EChooseNumNeuro.FIRST }, Inp2: { option: EChooseNumNeuro.FIRST }, CLabel: "C3" },
    { id: 3, identifier: "C4", dataId: "C4_Left", Inp1: { option: EChooseNumNeuro.FIRST }, Inp2: { option: EChooseNumNeuro.FIRST }, CLabel: "C4" },
    { id: 4, identifier: "C5", dataId: "C5_Left", Inp1: { option: EChooseNumNeuro.FIRST }, Inp2: { option: EChooseNumNeuro.FIRST }, Inp3: { option: EChooseNumNeuro.ALL }, CLabel: "C5 Flexores del codo" },
    { id: 5, identifier: "C6", dataId: "C6_Left", Inp1: { option: EChooseNumNeuro.FIRST }, Inp2: { option: EChooseNumNeuro.FIRST }, Inp3: { option: EChooseNumNeuro.ALL }, CLabel: "C6 Extensores de muñeca", c6: { label: 'ESI', type: 'SPAN', class: "location-title" } },
    { id: 6, identifier: "C7", dataId: "C7_Left", Inp1: { option: EChooseNumNeuro.FIRST }, Inp2: { option: EChooseNumNeuro.FIRST }, Inp3: { option: EChooseNumNeuro.ALL }, CLabel: "C7 Extensores del codo", c6: { label: '(Extremidad Superior', type: 'SPAN', class: "location-title" } },
    { id: 7, identifier: "C8", dataId: "C8_Left", Inp1: { option: EChooseNumNeuro.FIRST }, Inp2: { option: EChooseNumNeuro.FIRST }, Inp3: { option: EChooseNumNeuro.ALL }, CLabel: "C8 Flexores de los dedos", c6: { label: 'Izquierda)', type: 'SPAN', class: "location-title" } },
    { id: 8, identifier: "T1", dataId: "T1_Left", Inp1: { option: EChooseNumNeuro.FIRST }, Inp2: { option: EChooseNumNeuro.FIRST }, Inp3: { option: EChooseNumNeuro.ALL }, CLabel: "T1 Abductores de los dedos (dedo meñique)" },
    { id: 9, identifier: "T2", dataId: "T2_Left", Inp1: { option: EChooseNumNeuro.FIRST }, Inp2: { option: EChooseNumNeuro.FIRST }, CLabel: "T2" },
    { id: 10, identifier: "T3", dataId: "T3_Left", Inp1: { option: EChooseNumNeuro.FIRST }, Inp2: { option: EChooseNumNeuro.FIRST }, CLabel: "T3" },
    { id: 11, identifier: "T4", dataId: "T4_Left", Inp1: { option: EChooseNumNeuro.FIRST }, Inp2: { option: EChooseNumNeuro.FIRST }, CLabel: "T4" },
    { id: 12, identifier: "T5", dataId: "T5_Left", Inp1: { option: EChooseNumNeuro.FIRST }, Inp2: { option: EChooseNumNeuro.FIRST }, CLabel: "T5" },
    { id: 13, identifier: "T6", dataId: "T6_Left", Inp1: { option: EChooseNumNeuro.FIRST }, Inp2: { option: EChooseNumNeuro.FIRST }, CLabel: "T6" },
    { id: 14, identifier: "T7", dataId: "T7_Left", Inp1: { option: EChooseNumNeuro.FIRST }, Inp2: { option: EChooseNumNeuro.FIRST }, CLabel: "T7" },
    { id: 15, identifier: "T8", dataId: "T8_Left", Inp1: { option: EChooseNumNeuro.FIRST }, Inp2: { option: EChooseNumNeuro.FIRST }, CLabel: "T8" },
    { id: 16, identifier: "T9", dataId: "T9_Left", Inp1: { option: EChooseNumNeuro.FIRST }, Inp2: { option: EChooseNumNeuro.FIRST }, CLabel: "T9" },
    { id: 17, identifier: "T10", dataId: "T10_Left", Inp1: { option: EChooseNumNeuro.FIRST }, Inp2: { option: EChooseNumNeuro.FIRST }, CLabel: "T10" },
    { id: 18, identifier: "T11", dataId: "T11_Left", Inp1: { option: EChooseNumNeuro.FIRST }, Inp2: { option: EChooseNumNeuro.FIRST }, CLabel: "T11" },
    { id: 19, identifier: "T12", dataId: "T12_Left", Inp1: { option: EChooseNumNeuro.FIRST }, Inp2: { option: EChooseNumNeuro.FIRST }, CLabel: "T12" },
    { id: 20, identifier: "L1", dataId: "L1_Left", Inp1: { option: EChooseNumNeuro.FIRST }, Inp2: { option: EChooseNumNeuro.FIRST }, CLabel: "L1" },
    { id: 21, identifier: "L2", dataId: "L2_Left", Inp1: { option: EChooseNumNeuro.FIRST }, Inp2: { option: EChooseNumNeuro.FIRST }, Inp3: { option: EChooseNumNeuro.ALL }, CLabel: "L2 Flexores de cadera", c5: "" },
    { id: 22, identifier: "L3", dataId: "L3_Left", Inp1: { option: EChooseNumNeuro.FIRST }, Inp2: { option: EChooseNumNeuro.FIRST }, Inp3: { option: EChooseNumNeuro.ALL }, CLabel: "L3 Extensores de rodilla", c5: "" },
    { id: 23, identifier: "L4", dataId: "L4_Left", Inp1: { option: EChooseNumNeuro.FIRST }, Inp2: { option: EChooseNumNeuro.FIRST }, Inp3: { option: EChooseNumNeuro.ALL }, CLabel: "L4 Disflexores de tobillo", c6: { label: "EII", type: 'SPAN', class: "location-title" } },
    { id: 24, identifier: "L5", dataId: "L5_Left", Inp1: { option: EChooseNumNeuro.FIRST }, Inp2: { option: EChooseNumNeuro.FIRST }, Inp3: { option: EChooseNumNeuro.ALL }, CLabel: "L5 Extensores largos de los dedos", c6: { label: "(Extremidad Inferior", type: 'SPAN', class: "location-title" } },
    { id: 25, identifier: "S1", dataId: "S1_Left", Inp1: { option: EChooseNumNeuro.FIRST }, Inp2: { option: EChooseNumNeuro.FIRST }, Inp3: { option: EChooseNumNeuro.ALL }, CLabel: "S1 Flexores planta del tobillo", c6: { label: "Izquierda)", type: 'SPAN', class: "location-title" } },
    { id: 26, identifier: "S2", dataId: "S2_Left", Inp1: { option: EChooseNumNeuro.FIRST }, Inp2: { option: EChooseNumNeuro.FIRST }, CLabel: "S2" },
    { id: 27, identifier: "S3", dataId: "S3_Left", Inp1: { option: EChooseNumNeuro.FIRST }, Inp2: { option: EChooseNumNeuro.FIRST }, CLabel: "S3" },
    { id: 28, identifier: "S4_5", dataId: "S45_Left", Inp1: { option: EChooseNumNeuro.FIRST }, Inp2: { option: EChooseNumNeuro.FIRST }, CLabel: "S4-5", c6: { label: "(PAP) Presión anal profunda", type: 'COMBOBOX', options: valuesVocAnlContr, class: "cmb-opts-neuro-anl" } },
];

export interface ILevelNeuroResult {
    resRightSensory: string;
    resLeftSensory: string;
    resRightMotor: string;
    resLeftMotor: string;
    neurologicalLevelOfInjury: string;
    completeIncomplete: string;
    AsiaImpairmentScale: string;
    RightSensoryZpp: string;
    LeftSensoryZpp: string;
    RightMotorZpp: string;
    LeftMotorZpp: string;
}