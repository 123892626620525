import { Button, Card, Form, Input, Timeline } from 'antd';

import { useFormik } from 'formik';

import { IPageData } from '../../interfaces/page';

import { usePageData } from '../../hooks/usePage';
import { IAppointment, IPatient, mapPainType } from '../../interfaces/patient';
import { useNavigate, useLocation } from 'react-router-dom';

import './Appointments.scss';
import DisabilityTable from './DisabilityTable';
import { mapPatients } from '../../data/Patient';
import { SavePDF } from '../../data/CreatePDF';

type Props = {
  appointment: IAppointment;
};

type PropsPatient = {
  patient: IPatient;
};

type PropsQuestion = {
  question: Array<{ q: string, v: string, score: number }>
  type: 'CUELLO' | 'ESPALDA'
}

const pageData: IPageData = {
  title: 'Detalle de cita',
  fulFilled: true,
  hasBack: true,
  breadcrumbs: [
    {
      title: 'Principal',
      route: 'default-dashboard'
    },
    {
      title: 'Citas',
      route: 'appointments'
    },
    {
      title: 'Detalle cita'
    }
  ]
};

const FormItem = Form.Item;

const ProfileForm = ({ patient }: PropsPatient) => {
  const { values } = useFormik({
    initialValues: { ...patient },
    onSubmit: () => null
  });

  let fullname = values.name + " " + (values.lastname ? values.lastname : "");

  return (
    <Form layout='vertical'>
      <FormItem label='Nombre completo'>
        <Input defaultValue={fullname} placeholder='nombre completo' disabled className={'ant-input-disabled'} />
      </FormItem>

      <FormItem label='Correo electrónico'>
        <Input defaultValue={values.email} placeholder='email' disabled />
      </FormItem>

      <div className='row'>
        <div className='col-md-6 col-sm-12'>
          <FormItem label='Edad'>
            <Input defaultValue={values.age} placeholder='Edad' disabled />
          </FormItem>
        </div>
        <div className='col-md-6 col-sm-12'>
          <FormItem label='Genero'>
            <Input defaultValue={values.gender === 1 ? 'Masculino' : (values.gender === 0 ? 'Femenino' : null)} placeholder='Genero' disabled />
          </FormItem>
        </div>
      </div>

      <FormItem label='Telefono'>
        <Input defaultValue={values.phone} placeholder='Telefono' disabled />
      </FormItem>

    </Form>
  );
};


const AppointmentInfo = ({ appointment }: Props) => {
  const { values } = useFormik({
    initialValues: { ...appointment },
    onSubmit: () => null
  });

  return (
    <Form layout='vertical'>
      <FormItem label='Fecha de la cita'>
        <Input defaultValue={values.forDate} placeholder='Fecha de la cita' disabled />
      </FormItem>

      <FormItem label='Fecha de creación'>
        <Input defaultValue={values.createdAt} placeholder='Fecha de creación' disabled />
      </FormItem>

      <FormItem label='Tipo de dolor'>
        <Input defaultValue={mapPainType.get(values.painType)} placeholder='Tipo de dolor' disabled />
      </FormItem>

      <FormItem label='Descripción'>
        <Input defaultValue={values.description} placeholder='Descripción' disabled />
      </FormItem>

    </Form>
  );
};

const PainMapContainer = ({ svg }: { svg: string }) => {
  function createMarkup() {
    return { __html: svg };
  }

  return (
    <svg dangerouslySetInnerHTML={createMarkup()} width="100%" height="100%" fill="#ffffff" preserveAspectRatio="xMidYMid meet"></svg>
  );
}

const QuestionList = ({ question, type }: PropsQuestion) => {

  let totalPoint = 0;
  question.map(item => {
    totalPoint += (item.score || 0);
  });

  const timeQuestions = (<Timeline>
    {
      question.map((item, i) => (
        <Timeline.Item color='gray' key={i}>
          <div style={{ opacity: 0.8 }} className='date w-100 mb-2'>
            {item.q}
          </div>
          <span className='primary-text mb-4'>
            <strong>{item.v}</strong>
          </span>
        </Timeline.Item>
      ))
    }
  </Timeline>);

  return <>
    {timeQuestions}
    <h5 style={{ 'marginBottom': '0px' }}>El valor total es de {totalPoint}, su nivel de capacidad es "{DisabilityLevel(totalPoint)}".</h5>
    {type == 'ESPALDA' && (
      <h5 style={{ 'marginTop': '0px' }}>El porcentaje de discapacidad es {Math.round(totalPoint / 50 * 100)}%.</h5>
    )}
  </>
}

const DisabilityLevel = (score: number) => {
  if (score >= 0 && score <= 4)
    return 'Ninguna incapacidad';

  if (score >= 5 && score <= 14)
    return 'Incapacidad leve';

  if (score >= 15 && score <= 24)
    return 'Incapacidad moderada';

  if (score >= 25 && score <= 34)
    return 'Incapacidad severa';

  if (score >= 35 && score <= 50)
    return 'Incapacidad total';

  return '';
}

const AppointmentDetail = () => {
  usePageData(pageData);

  const location = useLocation();
  const appointment = location.state as IAppointment;

  let history = useNavigate();

  if (!appointment) {
    history(-1);
    return (<></>);
  }

  const questionBack: Array<any> = appointment.questionBack ? JSON.parse(appointment.questionBack) : [];
  const questionNeck = appointment.questionNeck ? JSON.parse(appointment.questionNeck) : [];

  if (!appointment._patient) appointment._patient = mapPatients.get(appointment.idPatient);

  const OnPDFSave = () => {
    SavePDF(appointment);
  }

  return (
    <>
      <div className='row mb-4'>
        <div className='col-md-6 col-sm-12'>
          <div className='info stack'>
            <ProfileForm patient={appointment._patient} />
          </div>
        </div>

        <div className='col-md-6 col-sm-12'>
          <div className='info stack'>
            <AppointmentInfo appointment={appointment} />
          </div>
        </div>
      </div>

      <div className='row mb-4'>
        <div className='col-md-6 col-sm-12'>
          {appointment.svgPainMap1 && (
            <Card title='Mapa de dolor de frente' className='mb-0 svg-pain-map' >
              <PainMapContainer svg={appointment.svgPainMap1} />
            </Card>
          )}
        </div>

        <div className='col-md-6 col-sm-12'>
          {appointment.svgPainMap2 && (
            <Card title='Mapa de dolor de espalda' className='mb-0 svg-pain-map' >
              <PainMapContainer svg={appointment.svgPainMap2} />
            </Card>
          )}
        </div>
      </div>

      {
        questionBack.length > 0 && (
          <Card title='Cuestionario dolor de espalda' className='mb-20'>
            <QuestionList question={questionBack} type='ESPALDA' />
            <DisabilityTable type='BACK' />
          </Card>
        )
      }

      {
        questionNeck.length > 0 && (
          <Card title='Cuestionario dolor de cuello' className='mb-0'>
            <QuestionList question={questionNeck} type='CUELLO' />
            <DisabilityTable type='NECK' />
          </Card>)
      }

      {
        <div className='elem-list justify-content-between' style={{ 'marginTop': "15px" }}>
          <Button ghost danger className='ml-' onClick={OnPDFSave}>
            Imprimir
          </Button>
        </div>
      }

    </>
  );
};

export default AppointmentDetail;
