import { useEffect } from 'react';

export function useHideLoader() {
  useEffect(() => {
    const overlay = document.querySelector('.loader-overlay');
    const loader = document.querySelector('.main-loader');

    setTimeout(() => {
      if (loader)
        loader.remove();

      if (overlay)
        overlay.remove();

    }, 300)
  }, []);
}

export function ShowLoading(dismissible: boolean = false) {
  let loader = document.querySelector('.main-loader-2nd');
  if (!loader) {
    loader = divLoader();
    document.body.appendChild(loader)
  }

  loader.classList.remove('disable');

  if (dismissible) {
    setTimeout(() => loader.classList.add('disable'), 3000)
  }
}

export function DismissLoading() {
  let loader = document.querySelector('.main-loader-2nd');
  if (loader)
    loader.classList.add('disable')
}

const divLoader = (): Element => {
  const elmStr = `<div class="app-loader main-loader-2nd">
    <div class="loader-box">
      <div class="bounceball"></div>
    </div>
    <div class="separator-box"></div>
    <i class='icofont-spinner-alt-4 rotate' />
  </div>`

  var div = document.createElement('div');
  div.innerHTML = elmStr.trim();

  return div.firstElementChild;
}