import jsPDF, { TextOptionsLight } from "jspdf";
import { ILevelNeuroResult, rowsLeft, rowsRight, scoreMotors, scoreSensory } from "./NeurologiaDataConfig";

const _margin = 15;
var _height = 450;
var _width = 380;
var _widthArea = _width;
var _posInY = _margin;
const _fontSize = 8;
const _smallfontSize = 6;
const _xxsmallfontSize = 4.5;
const _logoWidth = 160;
const _logoHeight = 24;
const _colorFont = "#000";

var wthCell: number = 20;
const hgtCell: number = 10;
var maxSectColumn1 = 50;
var minSectColumn2 = 50;


export const BlobPDFNeurologia = async (data: any[][], resultLevel: ILevelNeuroResult) => {
    let pdf = await fnPDFCreate(data, resultLevel)
    let uri = pdf.output('blob')

    return uri;
}

export const SaveNeurologiaPDF = async (data: any[][], resultLevel: ILevelNeuroResult) => {
    let pdf = await fnPDFCreate(data, resultLevel);
    pdf.save("Neurologia_" + new Date().toISOString().split('T')[0]);
}

async function fnPDFCreate(dataNeuro: any[][], resultLevel: ILevelNeuroResult) {
    const METADATA = {
        title: 'Neurologia form',
        subject: 'Informacion mapa de dolor',
        author: 'Tino 710',
        keywords: 'Dr. pedro reyes, cita de paciente',
        creator: 'Dr. Admin'
    };

    const pdf = new jsPDF({
        orientation: "landscape",
        unit: "px",
        format: "letter",
    });

    _width = pdf.internal.pageSize.getWidth();
    _height = pdf.internal.pageSize.getHeight();

    _widthArea = (_width - _margin * 2)
    _posInY = _margin;


    // >> METADATA
    pdf.setProperties(METADATA);
    pdf.setFontSize(_fontSize);

    //Datos de encabezado
    AddHeaderPdf(pdf);

    _posInY += _logoHeight - 14;

    // >> MARGIN LINES
    pdf.setDrawColor("#E0E0E0");
    // AddLinePdf(pdf, { x: _margin, y: _posInY, x2: _width - _margin })

    _posInY += 5;


    AddHeaderTblSide(pdf, true)
    AddHeaderTblSide(pdf, false)
    _posInY += 26;
    const wthImgBody = 120;
    const proporcion = 2.31;

    // >> Cuestions.....
    AddRowTblSide(pdf, true, dataNeuro);
    //Add IMAGE
    pdf.addImage("/body-report-neuro.png", 'PNG', maxSectColumn1, _posInY + 10, wthImgBody, wthImgBody * proporcion);
    _posInY = AddRowTblSide(pdf, false, dataNeuro);

    //AddFooterResult
    AddFooterResult(pdf, resultLevel);

    return pdf;
}

function AddHeaderPdf(pdf: jsPDF) {
    pdf.addImage("/logo_main.png", 'PNG', _margin, _posInY - 5, _logoWidth, _logoHeight);

    AddTitleRow(pdf, "Nombre del paciente", "Fecha");
    _posInY += 14;
    AddTitleRow(pdf, "Nombre del examinador", "Firma");
}

function AddTitleRow(pdf: jsPDF, label1: string, label2: string) {
    var posX = _width * 0.4;
    AddTextPDF(pdf, label1, posX, _posInY);

    var wthLbl = pdf.getTextWidth(label1);
    AddLinePdf(pdf, { x: posX += (wthLbl + 5), y: _posInY, x2: posX = _width - 150 });

    AddTextPDF(pdf, label2, posX += 10, _posInY);

    AddLinePdf(pdf, { x: posX += 20, y: _posInY, x2: _width - _margin });
}

function AddLinePdf(pdf: jsPDF, rect: { x: number, y: number, x2?: number, y2?: number }) {
    rect.x2 = rect.x2 || rect.x;
    rect.y2 = rect.y2 || rect.y;

    pdf.line(rect.x, rect.y, rect.x2, rect.y2);
}

function AddTextPDF(pdf: jsPDF, value: string, posX: number, posY: number, opts?: { width?: number, align?: 'left' | 'right' | 'center' | 'justify', isBold?: boolean, fontSize?: number }) {
    opts = opts || {};
    opts.width = opts.width || _width;
    const cnfgText: TextOptionsLight = { maxWidth: opts.width }
    if (opts.align) cnfgText.align = opts.align;
    if (opts.isBold) pdf.setFont(undefined, 'bold')

    if (opts.fontSize) pdf.setFontSize(opts.fontSize)

    pdf.setTextColor(_colorFont);
    pdf.text(value, posX, posY, cnfgText);

    if (_posInY >= _height - _margin) {
        AddNewPage(pdf);  // Restart height position
    }

    if (opts.isBold)
        pdf.setFont(undefined, 'normal')
}

function AddHeaderTblSide(pdf: jsPDF, IS_RIGHT: boolean) {
    const sect10 = _width / 10;
    maxSectColumn1 = sect10 * 4;
    minSectColumn2 = sect10 * 6;
    wthCell = maxSectColumn1 / 7;
    const mrgnText = 6;
    var posY = _posInY

    // //DEMOOOOO
    // ///LINESSS
    // const xLine1 = IS_RIGHT ? maxSectColumn1 : _width - maxSectColumn1;
    // AddLinePdf(pdf, { x: xLine1, y: posY, y2: _height });
    // ///LINESSS

    // ///ANOTHER LINESSSS
    // for (let i = 1; i < 7; i++) {
    //     const xLine2 = IS_RIGHT ? maxSectColumn1 - (wthCell * i) : _width - maxSectColumn1 + (wthCell * i);
    //     AddLinePdf(pdf, { x: xLine2, y: posY, y2: _height - 150 });
    // }

    //Add Title Section
    pdf.setFontSize(15);
    const lblTitle = IS_RIGHT ? "DERECHO" : "IZQUIERDO";
    const posLblTtleX = IS_RIGHT ? wthCell : (minSectColumn2 + wthCell * 6);
    AddTextPDF(pdf, lblTitle, posLblTtleX, posY + 10, { width: wthCell * 2, align: IS_RIGHT ? 'left' : 'right' });
    pdf.setFontSize(_smallfontSize);


    const xCol1 = IS_RIGHT ? maxSectColumn1 - wthCell : minSectColumn2 + wthCell;
    const xCol2 = IS_RIGHT ? maxSectColumn1 - wthCell / 2 : minSectColumn2 + wthCell / 2;
    const xCol3 = IS_RIGHT ? maxSectColumn1 - (wthCell * 1.5) : (minSectColumn2 + (wthCell * 1.5));
    const xCol4 = IS_RIGHT ? maxSectColumn1 - (wthCell * 2.5) : (minSectColumn2 + (wthCell * 2.5));

    AddTextPDF(pdf, "Sensorial", xCol1, posY, { width: wthCell * 2, align: 'center' });
    AddTextPDF(pdf, "Motor", xCol4, posY, { width: wthCell, align: 'center' });

    AddTextPDF(pdf, "Clave sensorial", xCol1, posY += mrgnText, { width: wthCell * 2, align: 'center' });
    AddTextPDF(pdf, "Musculos", xCol4, posY, { width: wthCell, align: 'center' });

    AddTextPDF(pdf, "puntos", xCol1, posY += mrgnText, { width: wthCell * 2, align: 'center' });
    AddTextPDF(pdf, "clave", xCol4, posY, { width: wthCell, align: 'center' });

    AddTextPDF(pdf, "Pinchazo", xCol2, posY += mrgnText, { width: wthCell * 2, align: 'center' });
    AddTextPDF(pdf, "ToqueLigero", xCol3, posY, { width: wthCell, align: 'center' });

    AddTextPDF(pdf, "(PP)", xCol2, posY += mrgnText, { width: wthCell * 2, align: 'center' });
    AddTextPDF(pdf, "(LT)", xCol3, posY, { width: wthCell, align: 'center' });
}

function AddRowTblSide(pdf: jsPDF, IS_RIGHT: boolean, dataTotal: any[][]): number {
    var tempPosInY = _posInY;
    const sectPosition = IS_RIGHT ? maxSectColumn1 : minSectColumn2;
    const c1_x = wthCell * (IS_RIGHT ? -1 : 0);
    const c2_x = wthCell * (IS_RIGHT ? -2 : 1);
    const c3_x = wthCell * (IS_RIGHT ? -3 : 2);
    const iSde = (IS_RIGHT ? -1 : 1);
    const resWthCell = wthCell - 4;

    const listRows = IS_RIGHT ? rowsRight : rowsLeft;

    listRows.map((item) => {
        var posXClabel = sectPosition + (wthCell * 3 * iSde);

        var xInput = sectPosition + c1_x + 2;
        pdf.rect(xInput, tempPosInY, resWthCell, hgtCell - 1);
        if (item.Inp1.value) AddTextPDF(pdf, item.Inp1.value, xInput + resWthCell / 2, tempPosInY + 6, { width: resWthCell, align: 'center' });

        xInput = sectPosition + c2_x + 2;
        pdf.rect(xInput, tempPosInY, resWthCell, hgtCell - 1);
        if (item.Inp2.value) AddTextPDF(pdf, item.Inp2.value, xInput + resWthCell / 2, tempPosInY + 6, { width: resWthCell, align: 'center' });

        if (item.Inp3) {
            xInput = sectPosition + c3_x + 2
            pdf.rect(xInput, tempPosInY, resWthCell, hgtCell - 1);
            if (item.Inp3.value) AddTextPDF(pdf, item.Inp3.value, xInput + resWthCell / 2, tempPosInY + 6, { width: resWthCell, align: 'center' });
        } else {
            posXClabel = sectPosition + (wthCell * 2 * iSde);
        }

        AddTextPDF(pdf, item.CLabel, posXClabel, tempPosInY + 6, { width: wthCell * 2, align: IS_RIGHT ? 'right' : 'left' });

        if (item.c6) {
            if (item.c6.type == 'TEXT_AREA') {
                pdf.rect(wthCell / 2, tempPosInY, wthCell * 3, hgtCell * 4);
                if (item.c6.value)
                    AddTextPDF(pdf, item.c6.value, wthCell / 2 + 2, tempPosInY + 6, { width: wthCell * 3 - 4, align: 'left' });
            } else {
                const isTtle = item.c6.class == 'bold-title';
                const isLeft = isTtle || item.c6.type == 'COMBOBOX';
                var xPosTtle: number;
                if (IS_RIGHT)
                    xPosTtle = isLeft ? wthCell / 2 : wthCell * 1.5;
                else
                    xPosTtle = isLeft ? sectPosition + wthCell * 3 : _width - (wthCell * 1.5);

                var lblValue = (item.c6.value ? item.c6.value : "")
                var lbelIt6 = item.c6.label;
                if (item.c6.type == 'COMBOBOX' && item.c6.value) {
                    lblValue = item.c6.options.find(o => o.id == item.c6.value).value;
                    lbelIt6 = (lbelIt6.endsWith(':') ? lbelIt6 : lbelIt6 + ':')
                }

                lblValue = lbelIt6 + " " + lblValue;

                AddTextPDF(pdf, lblValue, xPosTtle, tempPosInY + 6, { width: wthCell * 4, align: isLeft ? 'left' : 'center', isBold: isTtle });
            }
        }

        tempPosInY += hgtCell
    });

    //Totales
    const listDataTotals = IS_RIGHT ? dataTotal[0] ?? [] : dataTotal[1] ?? [];
    tempPosInY += 2;
    var xTotRect = sectPosition + c1_x + 2;
    const yTotLbl = tempPosInY + 8;
    pdf.rect(xTotRect, tempPosInY, resWthCell, hgtCell + 2);
    if (listDataTotals.length > 2) AddTextPDF(pdf, listDataTotals[2], xTotRect + wthCell * 0.5, yTotLbl, { width: wthCell, align: 'center' });
    xTotRect = sectPosition + c2_x + 2;
    pdf.rect(xTotRect, tempPosInY, resWthCell, hgtCell + 2);
    if (listDataTotals.length > 1) AddTextPDF(pdf, listDataTotals[1], xTotRect + wthCell * 0.5, yTotLbl, { width: wthCell, align: 'center' });
    xTotRect = sectPosition + c3_x + 2;
    pdf.rect(xTotRect, tempPosInY, resWthCell, hgtCell + 2);
    if (listDataTotals.length > 0) AddTextPDF(pdf, listDataTotals[0], xTotRect + wthCell * 0.5, yTotLbl, { width: wthCell, align: 'center' });
    AddTextPDF(pdf, "Totales", sectPosition + (wthCell * 3 * iSde), yTotLbl, { width: wthCell * 2, align: IS_RIGHT ? 'right' : 'left' });

    //Totales - description
    tempPosInY += hgtCell + 8;
    AddTextPDF(pdf, "(56)", sectPosition + c1_x + wthCell * 0.5, tempPosInY, { width: wthCell, align: 'center' });
    AddTextPDF(pdf, "(56)", sectPosition + c2_x + wthCell * 0.5, tempPosInY, { width: wthCell, align: 'center' });
    AddTextPDF(pdf, "(50)", sectPosition + c3_x + wthCell * 0.5, tempPosInY, { width: wthCell, align: 'center' });
    AddTextPDF(pdf, "(MÁXIMO)", sectPosition + (wthCell * 3 * iSde), tempPosInY, { width: wthCell * 2, align: IS_RIGHT ? 'right' : 'left' });

    // footer tbl
    tempPosInY += 8;
    const ttleSecFooter = IS_RIGHT ? "SUBPUNTUACIONES MOTOR" : "SUBPUNTUACIONES SENSORIALES";
    var posXFoot = IS_RIGHT ? wthCell / 2 : _width / 2 + 5;
    AddTextPDF(pdf, ttleSecFooter, posXFoot, tempPosInY, { width: wthCell * 4, align: 'left', isBold: true });
    tempPosInY += 4;

    //Iterate footer values
    const listValFooter = IS_RIGHT ? scoreMotors : scoreSensory;
    listValFooter.forEach(item => {
        AddTextPDF(pdf, item.label, posXFoot, tempPosInY + 8, { width: wthCell, align: 'left', isBold: true });
        const wthTextFoot = pdf.getTextWidth(item.label);

        const nWthCell = wthCell - 12;
        pdf.rect(posXFoot += wthTextFoot + 2, tempPosInY, nWthCell, hgtCell + 2);
        if (item.value) AddTextPDF(pdf, '' + item.value, posXFoot + nWthCell / 2, tempPosInY + 8, { width: wthCell, align: 'center' });
        posXFoot += nWthCell + 2;

        AddTextPDF(pdf, item.tag, posXFoot - 4, tempPosInY + 18, { width: wthCell, align: 'right' });
        posXFoot += 2;
    });

    return tempPosInY + 24;
}

function AddFooterResult(pdf: jsPDF, resultLevel: ILevelNeuroResult) {
    pdf.rect(_margin, _posInY, _widthArea, _height - _posInY - _margin);
    AddTextPDF(pdf, "(En lesiones con ausencia de función motora o sensorial solo en S4-5)", _width - _margin - 10, _posInY += 5, { width: _widthArea, align: 'right', fontSize: _xxsmallfontSize });

    _posInY += 5;
    var nPosY = _posInY + 6;
    var xPosFooter = wthCell * 1.5;
    AddTextPDF(pdf, "NIVELES NEUROLÓGICOS", xPosFooter, nPosY, { width: wthCell + 4, align: 'center', fontSize: _smallfontSize });
    AddTextPDF(pdf, "Pasos 1-6 para clasificación", xPosFooter, nPosY + 10, { width: wthCell, align: 'center', fontSize: _xxsmallfontSize });

    const nWthCell = wthCell - 12;
    var nPosY = _posInY;
    xPosFooter += wthCell * 2;
    AddTextPDF(pdf, "R", xPosFooter + nWthCell * 0.5, nPosY, { width: nWthCell, align: 'center', fontSize: _smallfontSize });
    AddTextPDF(pdf, "L", xPosFooter + nWthCell * 1.5, nPosY, { width: nWthCell, align: 'center' });

    nPosY += 1;
    AddTextPDF(pdf, "1. SENSORIAL", xPosFooter - 1, nPosY + 6, { width: wthCell, align: 'right' });
    pdf.rect(xPosFooter + 1, nPosY, nWthCell, hgtCell - 2);
    if (resultLevel.resRightSensory) AddTextPDF(pdf, resultLevel.resRightSensory, xPosFooter + nWthCell / 2, nPosY + 6, { width: wthCell, align: 'center' });
    pdf.rect(xPosFooter + nWthCell + 2, nPosY, nWthCell, hgtCell - 2);
    if (resultLevel.resLeftSensory) AddTextPDF(pdf, resultLevel.resLeftSensory, xPosFooter + nWthCell + nWthCell / 2, nPosY + 6, { width: wthCell, align: 'center' });

    nPosY += hgtCell;
    AddTextPDF(pdf, "2. MOTOR", xPosFooter - 1, nPosY + 6, { width: wthCell, align: 'right' });
    pdf.rect(xPosFooter + 1, nPosY, nWthCell, hgtCell - 2);
    if (resultLevel.resRightMotor) AddTextPDF(pdf, resultLevel.resRightMotor, xPosFooter + nWthCell / 2, nPosY + 6, { width: wthCell, align: 'center' });
    pdf.rect(xPosFooter + nWthCell + 2, nPosY, nWthCell, hgtCell - 2);
    if (resultLevel.resLeftMotor) AddTextPDF(pdf, resultLevel.resLeftMotor, xPosFooter + nWthCell + nWthCell / 2, nPosY + 6, { width: wthCell, align: 'center' });


    nPosY = _posInY;
    xPosFooter += wthCell * 3;
    AddTextPDF(pdf, "3. NIVEL NEUROLÓGICO DE LESIÓN (NLI)", xPosFooter - 1, nPosY + 6, { width: wthCell * 1.2, align: 'right' });
    pdf.rect(xPosFooter + 1, nPosY += 6, nWthCell, hgtCell - 2);
    if (resultLevel.neurologicalLevelOfInjury) AddTextPDF(pdf, resultLevel.neurologicalLevelOfInjury, xPosFooter + nWthCell / 2, nPosY + 6, { width: wthCell, align: 'center' });


    nPosY = _posInY;
    xPosFooter += wthCell * 4;
    AddTextPDF(pdf, "4. COMPLETO O INCOMPLETO?", xPosFooter - 1, nPosY + 6, { width: wthCell * 3, align: 'right' });
    pdf.rect(xPosFooter + 1, nPosY += 2, nWthCell, hgtCell - 2);
    if (resultLevel.completeIncomplete) AddTextPDF(pdf, resultLevel.completeIncomplete, xPosFooter + nWthCell / 2, nPosY + 6, { width: wthCell, align: 'center' });

    AddTextPDF(pdf, "Incompleto = Cualquier función sensorial o motora en S4-5", xPosFooter - 1, nPosY += 8, { width: wthCell * 3, align: 'right', fontSize: _xxsmallfontSize });
    nPosY += 2;
    AddTextPDF(pdf, "5. ESCALA DE DETERIORO DE ASIA (AIS)", xPosFooter - 1, nPosY + 6, { width: wthCell * 3, align: 'right', fontSize: _smallfontSize });
    pdf.rect(xPosFooter + 1, nPosY, nWthCell, hgtCell - 2);
    if (resultLevel.AsiaImpairmentScale) AddTextPDF(pdf, resultLevel.AsiaImpairmentScale, xPosFooter + nWthCell / 2, nPosY + 6, { width: wthCell, align: 'center' });



    nPosY = _posInY + 6;
    xPosFooter += wthCell * 2.5;
    AddTextPDF(pdf, "6. ZONA DE CONSERVACIÓN PARCIAL", xPosFooter, nPosY, { width: wthCell * 2, align: 'center' });
    AddTextPDF(pdf, "Nivel más caudal con cualquier inervación", xPosFooter, nPosY += 10, { width: wthCell, align: 'center', fontSize: _xxsmallfontSize });


    var nPosY = _posInY;
    xPosFooter = _width - _margin - nWthCell * 2 - 10;
    AddTextPDF(pdf, "R", xPosFooter + nWthCell * 0.5, nPosY, { width: nWthCell, align: 'center', fontSize: _smallfontSize });
    AddTextPDF(pdf, "L", xPosFooter + nWthCell * 1.5, nPosY, { width: nWthCell, align: 'center' });

    nPosY += 1;
    AddTextPDF(pdf, "SENSORIAL", xPosFooter - 1, nPosY + 6, { width: wthCell, align: 'right' });
    pdf.rect(xPosFooter + 1, nPosY, nWthCell, hgtCell - 2);
    if (resultLevel.RightSensoryZpp) AddTextPDF(pdf, resultLevel.RightSensoryZpp, xPosFooter + nWthCell / 2, nPosY + 6, { width: wthCell, align: 'center' });
    pdf.rect(xPosFooter + nWthCell + 2, nPosY, nWthCell, hgtCell - 2);
    if (resultLevel.LeftSensoryZpp) AddTextPDF(pdf, resultLevel.LeftSensoryZpp, xPosFooter + nWthCell + nWthCell / 2, nPosY + 6, { width: wthCell, align: 'center' });

    nPosY += hgtCell;
    AddTextPDF(pdf, "MOTOR", xPosFooter - 1, nPosY + 6, { width: wthCell, align: 'right' });
    pdf.rect(xPosFooter + 1, nPosY, nWthCell, hgtCell - 2);
    if (resultLevel.RightMotorZpp) AddTextPDF(pdf, resultLevel.RightMotorZpp, xPosFooter + nWthCell / 2, nPosY + 6, { width: wthCell, align: 'center' });
    pdf.rect(xPosFooter + nWthCell + 2, nPosY, nWthCell, hgtCell - 2);
    if (resultLevel.LeftMotorZpp) AddTextPDF(pdf, resultLevel.LeftMotorZpp, xPosFooter + nWthCell + nWthCell / 2, nPosY + 6, { width: wthCell, align: 'center' });
    ///
}

function AddNewPage(pdf: jsPDF) {
    pdf.addPage();
    _posInY = _margin;  // Restart height position
}