import { Image, Slider, SliderSingleProps } from "antd";
import OpenNotification from "../../../pages/Toast";
import { IPainMapValues } from "../../interfaces/Pain";

const marks: SliderSingleProps['marks'] = {
    0: '0',
    1: '1',
    2: '2',
    3: '3',
    4: '4',
    5: '5',
    6: '6',
    7: '7',
    8: '8',
    9: '9',
    10: {
        style: { color: '#f50' },
        label: <strong>10</strong>,
    },
};

const ScalePain = (
    { handlerNextClick, objRef, isEnabled, OnChangeSldr }: { handlerNextClick?: (_fnct: () => boolean) => void, objRef: IPainMapValues, isEnabled: boolean, OnChangeSldr?: (v: number) => void }
) => {

    const OnChangeSlide = (ev: number) => {
        objRef.scalePain = ev;
        if (OnChangeSldr) OnChangeSldr(ev);
    }

    if (handlerNextClick) {
        handlerNextClick(() => {
            if (objRef.scalePain === undefined)
                OpenNotification('Datos incompletos', 'Selecciona un valor en la escala de dolor', 'warn');
            return objRef.scalePain !== undefined;
        });
    }

    return (
        <div style={{ margin: '30px', marginBottom: '45px' }}>
            <Image src="content/scale_pain_emogi.svg" preview={false} />
            <Slider marks={marks} step={null} defaultValue={objRef.scalePain} style={{ margin: '7% 5% 3% 3%' }}
                onChange={OnChangeSlide} disabled={!isEnabled} max={10} tooltip={{ open: !isEnabled }} />
            <Image src="content/scale_pain_values.svg" preview={false} style={{ marginTop: '35px' }} />
        </div>
    )
}

export default ScalePain;