import { Button, Form, Input, Switch } from 'antd';
import { LoginOutlined } from '@ant-design/icons/lib';

import PublicLayout from '../../layout/public/Public';
import { Link } from 'react-router-dom';
import { useForm } from 'antd/es/form/Form';
import { useNavigateHome } from '../../utils/use-navigate-home';
import { IsValidEmail } from '../../data/General';
import { LogginUser } from '../../data/User';
import { IUser } from '../../interfaces/user';
import openNotification from '../Toast';

const { Item } = Form;

const SignIn = () => {
  //Borrar datos de sesion
  // localStorage.clear();


  const [form] = useForm();
  const navigateHome = useNavigateHome();

  const loggFunc = LogginUser;
  const LogginCompletion = (succes: number, _: IUser) => {
    if (succes === 1) {
      navigateHome();
      window.location.reload();//VALIDAR:....

    } else if (succes === -3) {
      openNotification('Problemas con el acceso', "Usuario y/o contraseña incorrecto", 'error');
    } else {
      openNotification('Problemas con el acceso', "¡Ocurrió un error, intentalo de nuevo!", 'error');
    }
  }

  const login = () => {
    form
      .validateFields()
      .then((e) => {
        if (!IsValidEmail(e.user)) return;

        loggFunc(e.user, e.password, LogginCompletion);
      })
      .catch((e) => console.error("erros", e));
  };

  return (
    <PublicLayout bgImg={`${window.origin}/content/login_background.png`}>
      <h4 className='mt-0 mb-1'>Iniciar Sesión</h4>

      <p className='text-color-200'>Captura tus datos de usuario</p>

      <Form form={form} layout='vertical' className='mb-4'>
        <Item name='user' rules={[{ required: true, message: <></> }]}>
          <Input placeholder='Usuario' type='email' />
        </Item>
        <Item name='password' rules={[{ required: true, message: <></> }]}>
          <Input placeholder='Contraseña' type='password' />
        </Item>

        <div className='d-flex align-items-center mb-4'>
          <Switch defaultChecked /> <span className='ml-2'>Recordarme</span>
        </div>

        <Button
          block={false}
          type='primary'
          onClick={login}
          htmlType='submit'
          icon={<LoginOutlined style={{ fontSize: '1.3rem' }} />} >
          Iniciar sesion
        </Button>
      </Form>
      <br />
      <p className='mb-1'>
        <a href='/#'>Olvidé mi contraseña!</a>
      </p>

      <p>
        No tienes cuenta? <Link to=''>Contactanos!</Link>
      </p>
    </PublicLayout>
  );
};

export default SignIn;
