import { Button, Steps, message } from "antd";
import { usePageData } from "../../../hooks/usePage";
import { IPageData } from "../../../interfaces/page";
import { useState } from "react";
import { LoadPatients } from "../../../data/Patient";
import InfoPatientPain from "./info-patien";

import './pain-patiene.scss'
import QuestionPain from "./question_pain";
import { LoadQuestion } from "../../Model/Question";
import ScalePain from "./scale-pain";
import { IPainMapValues } from "../../interfaces/Pain";
import { AddValoracionMapaDolor } from "../../Model/ValoracionByStudent";
import { useNavigate } from "react-router";
import { NewEmptyExpediente } from "../../../pages/history/ExpedienteQuestions";
import SOAPView from "./soap";
import { IPatient } from "../../../interfaces/patient";
import { DismissLoading, ShowLoading } from "../../../hooks/useHideLoader";

const pageData: IPageData = {
    fulFilled: false,
    breadcrumbs: [
        {
            title: 'Dashboards',
            route: 'home'
        },
        {
            title: 'home'
        }
    ]
};

const steps = [
    {
        id: 1,
        title: 'Paciente & Mapa de dolor',
        enabled: true,
        handlerNextClick: null
    },
    {
        id: 2,
        title: 'Questionario espalda',
        enabled: false,
        handlerNextClick: null
    },
    {
        id: 3,
        title: 'Questionario cuello',
        enabled: false,
        handlerNextClick: null
    },
    {
        id: 4,
        title: 'Escala del dolor EVA',
        enabled: true,
        handlerNextClick: null
    },
    {
        id: 5,
        title: 'Subjetivos, Objetivos Apreciativos, Planes',
        enabled: false,
        handlerNextClick: null
    },
];

const AddMapPain: React.FC = () => {
    usePageData(pageData)

    const history = useNavigate();

    LoadQuestion();
    const [patientsResult] = LoadPatients();
    const [current, setCurrent] = useState(0);
    const [_steps, setStepsList] = useState(steps.filter(o => !!o.enabled));
    const currentStep = _steps[current];


    const [valuesMapPain, _setValPainMap] = useState<IPainMapValues>({});

    const next = () => {
        if (!currentStep.handlerNextClick()) return;

        setCurrent(current + 1);
    };

    const prev = () => setCurrent(current - 1);
    const OnSave = () => {
        if (!currentStep.handlerNextClick()) return;

        ShowLoading();
        AddValoracionMapaDolor(valuesMapPain.patient.hasForm, valuesMapPain.patient.id, valuesMapPain.typePain, valuesMapPain.svgPainFront, valuesMapPain.svgPainBack,
            valuesMapPain.questions, valuesMapPain.scalePain, valuesMapPain.subjetivos, valuesMapPain.objapreciativo, valuesMapPain.planes, (success, idVal, idExp) => {
                DismissLoading();

                if (success > 0) {
                    message.success('El formulario se ha guardado correctamente!');
                    // setValPainMap({});
                    // setCurrent(0);

                    //Redirigir a historial
                    const tempExp = NewEmptyExpediente(valuesMapPain.patient);
                    tempExp.idValoracion = idVal;
                    tempExp.id = idExp;
                    if (!valuesMapPain.patient.hasForm)
                        history(`/vertical/history-expedientepage`, { state: [valuesMapPain.patient, tempExp] });
                    else
                        history(`/vertical/history-neurologia`, { state: { patient: valuesMapPain.patient, expediente: tempExp } });
                } else {
                    message.error('Ocurrió un error al guardar !');
                }
            })

    }

    const OnCheckPainType = (val) => {
        steps.find(o => o.id == 2).enabled = val == 1 || val == 3;
        steps.find(o => o.id == 3).enabled = val == 2 || val == 3;

        valuesMapPain.questions = [];

        const filterEnabled = steps.filter(o => !!o.enabled)
        setStepsList(Object.assign([], filterEnabled));
    }

    const OnChangePatient = (patn: IPatient) => {
        valuesMapPain.questions = [];
        valuesMapPain.patient = patn;
        valuesMapPain.subjetivos = '';
        valuesMapPain.objapreciativo = '';
        valuesMapPain.planes = '';

        //Resetear steps
        steps.find(o => o.id == 2).enabled = false;
        steps.find(o => o.id == 3).enabled = false;
        steps.find(o => o.id == 4).enabled = !valuesMapPain.patient.hasForm;
        steps.find(o => o.id == 5).enabled = !!valuesMapPain.patient.hasForm;

        // _setValPainMap(Object.assign({}, valuesMapPain));
        const filterEnabled = steps.filter(o => !!o.enabled)
        setStepsList(Object.assign([], filterEnabled));
    }

    const OnCallbackNextScale = (_fnct: () => boolean) => currentStep.handlerNextClick = _fnct;

    const items = _steps.map((item) => ({ key: item.title, title: item.title }));

    const contentStyle: React.CSSProperties = {
        textAlign: 'start',
        marginTop: 16,
    };

    return (
        <div >
            <Steps current={current} items={items} />
            <div style={contentStyle}>
                {currentStep.id == 1 && <InfoPatientPain patients={patientsResult} handlerCompletion={OnCallbackNextScale} OnCheckPainType={OnCheckPainType} objPainValues={valuesMapPain} OnChangePatient={OnChangePatient} />}
                {currentStep.id == 2 && <QuestionPain category={1} handlerCompletion={OnCallbackNextScale} objRef={valuesMapPain} />}
                {currentStep.id == 3 && <QuestionPain category={2} handlerCompletion={OnCallbackNextScale} objRef={valuesMapPain} />}
                {currentStep.id == 4 && <ScalePain handlerNextClick={OnCallbackNextScale} objRef={valuesMapPain} isEnabled={true} />}
                {currentStep.id == 5 && <SOAPView handlerCompletion={OnCallbackNextScale} objPainValues={valuesMapPain} />}
            </div>

            <div style={{ marginTop: 24 }}>
                {current < _steps.length - 1 && (
                    <Button type="primary" onClick={() => next()}>Siguiente</Button>
                )}
                {current === _steps.length - 1 && (
                    <Button type="primary" onClick={OnSave}>
                        Continuar con expediente
                    </Button>
                )}
                {current > 0 && (
                    <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
                        Anterior
                    </Button>
                )}
            </div>
        </div>
    );
};

export default AddMapPain;