import { IPatient } from '../interfaces/patient';
import { useFetchPageData } from '../hooks/usePage';
import { IResultRequest } from '../interfaces/Entities';
import { USER_INFO, _API } from './General';
import { SetPatientInAppointment } from './Appointment';
import { RequestData } from './RequestData';
import { mapUsers } from './User';
import { ETypeUser } from '../interfaces/user';

export const mapPatients: Map<number, IPatient> = new Map();

export function LoadPatients(idUser?: number): [Array<IPatient>, (data: Array<IPatient>) => void] {
    let _pts = Array.from(mapPatients.values());
    _pts.sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime());

    let maxUpdated = null;
    if (_pts.length > 0) maxUpdated = _pts[0].updatedAt;

    if (!idUser && USER_INFO.typeUser == ETypeUser.STUDENT) idUser = USER_INFO.id;

    let urlReq = `${_API}patient/GetPatientsStudent?`;
    if (USER_INFO.typeUser == ETypeUser.STUDENT) urlReq += `idUser=${idUser}&`;
    urlReq += `lastUpdated=${maxUpdated}`;

    const [dataResult, SetDataTemp] = useFetchPageData<IResultRequest<IPatient[]>>(urlReq);

    if (dataResult && dataResult.success == 1) {
        ValidAndSetPatients(dataResult.data);
    }

    const fnc = (resDta: Array<IPatient>): void => {
        SetDataTemp({ success: 1, message: "", data: resDta });
    }

    let listResult = Array.from(mapPatients.values());
    listResult = listResult.sort((a, b) => new Date(b.id).getTime() - new Date(a.id).getTime());

    if (idUser)
        listResult = listResult.filter(o => o.createdBy == idUser);

    return [listResult, fnc];
}




const ValidAndSetPatients = (data: IPatient[]) => {
    data.forEach(item => {
        if (!item.inUse)
            mapPatients.delete(item.id)
        else {
            item._user = mapUsers.get(item.createdBy);
            mapPatients.set(item.id, item);

            SetPatientInAppointment(item);
        }
    });
}

export const UpdateAndSavePatient = (patient: IPatient, toUpdate: boolean, completion: (result: number) => void) => {
    let params = {
        idUser: USER_INFO.id,
        name: patient.name,
        lastname: patient.lastname,
        birthDate: patient.birthDate,
        gender: patient.gender,
        phone: patient.phone,
        description: patient.description,
        email: patient.email
    }

    if (toUpdate) params['id'] = patient.id;

    let urlReq = `${_API}patient/${toUpdate ? 'UpdatePatient' : 'CreatePatient'}`;

    new RequestData(urlReq, 'POST')
        .SetParams(params)
        .SetCallback(response => {
            let success = response ? response.data : -100;
            completion(success);
        }).Request();
}

export const RemovePatientData = (idPatient: number, completion: (result: number) => void) => {
    let params = {
        id: idPatient,
        idUser: USER_INFO.id
    }

    let urlReq = `${_API}patient/RemovePatient`;
    new RequestData(urlReq, 'POST')
        .SetParams(params)
        .SetCallback(response => {
            let success = response ? response.data : -100;
            completion(success);
        }).Request();

}