import { IRoute } from '../interfaces/routing';

import SettingsPage from '../pages/settings/Settings';

import PatientsPage from '../pages/patients/Patients';
import DashboardPage from '../pages/dashboards/Dashboard';
import AppointmentsPage from '../pages/appointments/AppointmentsPage';
import UsersPage from '../pages/users/Users';
import DoctorProfilePage from '../pages/medic/DoctorsProfilePage';
import PatientProfilePage from '../pages/patients/PatientProfilePage';
import EventsCalendarPage from '../pages/services/EventsCalendarPage';
import EditAccountPage from '../pages/users/EditAccounPage';
import UserProfilePage from '../pages/users/UserProfilePage';
import AppointmentDetail from '../pages/appointments/AppointmentDetail';
import MedicalHistory from '../pages/history/MedicalHistory';
import ExpedientePage from '../pages/history/ExpedienteNew';
import ExpedienteBodyZone from '../pages/history/ExpedienteBody';
import ExpedienteList from '../pages/history/ExpedienteList/ExpedienteList';
import PDFViewerTest from '../data/TestPDF';
import PostExpedNeurologia from '../pages/history/PostExpedNeurologia';

export const defaultRoutes: IRoute[] = [
  {
    path: 'settings',
    component: SettingsPage
  },
  {
    path: 'patients',
    component: PatientsPage
  },
  {
    path: 'default-dashboard',
    component: DashboardPage
  },
  {
    path: 'appointments',
    component: AppointmentsPage
  },
  {
    path: 'users',
    component: UsersPage
  },
  {
    path: 'doctor-profile',
    component: DoctorProfilePage
  },
  {
    path: 'patient-profile',
    component: PatientProfilePage
  },
  {
    path: 'events-calendar',
    component: EventsCalendarPage
  },
  {
    path: 'edit-account',
    component: EditAccountPage
  },
  {
    path: 'user-profile',
    component: UserProfilePage
  },
  {
    path: 'apointment-detail',
    component: AppointmentDetail
  },
  {
    path: 'history-table',
    component: MedicalHistory
  },
  {
    path: 'history-expedientepage',
    component: ExpedientePage
  },
  {
    path: 'history-bodyzone',
    component: ExpedienteBodyZone
  },
  {
    path: 'history-neurologia',
    component: PostExpedNeurologia
  },
  {
    path: 'history-expedientelist',
    component: ExpedienteList
  },
  {
    path: 'pdf-viewer-test',
    component: PDFViewerTest
  }
];
