import { useState } from 'react';

import { Button, Modal } from 'antd';

import PatientForm from './PatientForm';
import { usePatients } from '../../hooks/usePatients';

import { IPatient } from '../../interfaces/patient';
import { UpdateAndSavePatient } from '../../data/Patient';
import OpenNotification from '../Toast';
import { DismissLoading, ShowLoading } from '../../hooks/useHideLoader';

const AddPatient = () => {
  const { addPatient } = usePatients();
  const [visible, setVisible] = useState(false);

  const handleClick = () => setVisible(!visible);

  const closeModal = () => setVisible(false);

  const handleAddPatient = (patient: IPatient) => {
    ShowLoading();
    UpdateAndSavePatient(patient, false, (result) => {
      DismissLoading();

      if (result > 0) {
        addPatient(patient);
        closeModal();

        OpenNotification('Agregado!', "Se creó un nuevo paciente");

        const hshs = window.location.hash.split('/')
        if (hshs.length > 0 && (hshs[hshs.length - 1] == 'patients' || hshs[hshs.length - 1] == 'home'))//Edit...
          window.location.reload();
      } else {
        OpenNotification('¡Error!', "Ocurrió un error al guardar", 'error');
      }
    })
  };

  return (
    <div className='add-patient'>
      <Button type='primary' onClick={handleClick}>
        <span className='icofont icofont-plus mr-2' style={{ fontSize: '1.3em' }} />
        Nuevo paciente
      </Button>
      <Modal
        open={visible}
        footer={null}
        onCancel={closeModal}
        destroyOnClose
        title={<h3 className='title'>Nuevo paciente</h3>}>
        <PatientForm onCancel={closeModal} onSubmit={handleAddPatient} />
      </Modal>
    </div>
  );
};

export default AddPatient;
