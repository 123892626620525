import jsPDF from "jspdf";
import { IAppointment } from "../../../interfaces/patient";
import { IPdfText } from "../../../data/Entities";
import { AddMarkerArrow, SvgStringToSvgArrows } from "../../../pages/history/svgBodyPain";
import { EArrPainTempName } from "../../interfaces/Pain";
import { STR_BODY_BACK, STR_BODY_FRONT, STR_IMG_SCALE_EMOGI, STR_IMG_SCALE_VALUES } from "../../Model/SvgImagePain";
import { select } from "d3";
import { GetQuestionByCategory } from "../../Model/Question";

const _margin = 25;
var _height = 450;
var _width = 380;
var _widthArea = _width;
var _posInY = _margin;
const _fontSize = 12;
const _lineSpacing = 8;
const _logoWidth = 250;
const _logoHeight = 36;
const _colorFont = "#000";
const _colorSecundaryFont = "#696969";
const _colorGray = "#c9c9c9";


export const BlobFormularioPDF = async (appointment: IAppointment) => {
    let pdf = await fnPDFCreate(appointment)
    let uri = pdf.output('blob')

    return uri;
}

export const SaveFormularioPDF = async (appointment: IAppointment) => {
    let pdf = await fnPDFCreate(appointment);
    pdf.save("Formulario_" + appointment.createdAt);
}

async function fnPDFCreate(appointment: IAppointment) {
    let patient = appointment._patient;
    const student = appointment._user;

    const METADATA = {
        title: 'Paciente: ' + patient.name,
        subject: 'Informacion del formulario',
        author: 'Tino 710',
        keywords: 'Dr. pedro reyes, formulario de paciente',
        creator: 'Dr. Admin'
    };

    const pdf = new jsPDF({
        orientation: "portrait",
        unit: "px",
        format: "letter",
    });

    _width = pdf.internal.pageSize.getWidth();
    _height = pdf.internal.pageSize.getHeight();

    _widthArea = (_width - _margin * 2)
    _posInY = _margin;

    const nameStudent = student ? (student.name + ' ' + (student.lastname ? student.lastname : '')) : ' - ';
    const infoPatient: Array<IPdfText> = [
        { label: "Información del paciente", value: null, posX: 0 },
        { label: "Nombre completo: ", value: patient.name, posX: _margin, val2: { label: "Correo eléctronico: ", value: patient.email, posX: _width / 2 } },
        {
            label: "Edad: ", value: "" + patient.age, posX: _margin,
            val2: { label: "Genero: ", value: patient.gender == 1 ? "Masculino" : "Femenino", posX: _width * 0.3 },
            val3: { label: "Teléfono: ", value: patient.phone, posX: _width * 0.6 }
        },
        { label: 'Agregado por: ', value: nameStudent, posX: _margin },
        { label: null, value: null, posX: 0 },
        { label: "Información del formulario", value: null, posX: 0 },
        {
            label: "Tipo de dolor: ", value: appointment.painType == 1 ? 'Espalda' : (appointment.painType == 2 ? 'Cuello' : (appointment.painType == 3 ? 'Ambos' : ' - ')), posX: _margin,
            val2: { label: "Fecha: ", value: appointment.createdAt, posX: _width / 2 }
        },
        { label: "Descripción: ", value: appointment.description, posX: _margin },
        { label: "Mapa de dolor de frente: ", value: null, posX: _margin, val2: { label: "Mapa de dolor de espalda: ", value: null, posX: _width / 2 } },
    ];


    // >> METADATA
    pdf.setProperties(METADATA);
    pdf.setFontSize(_fontSize)

    pdf.addImage("/logo_main.png", 'PNG', (_width - _logoWidth) / 2, _posInY, _logoWidth, _logoHeight);
    _posInY += _logoHeight + 10;

    // >> MARGIN LINES
    pdf.setDrawColor("#E0E0E0");
    pdf.line(_margin, _posInY, _width - _margin, _posInY);

    _posInY += 30;

    // >> Info patient
    infoPatient.forEach(item => {
        if (item.label)
            AddTextAndValuePDF(pdf, item.label, item.value, item.posX, _posInY, _widthArea);

        if (item.val2)
            AddTextAndValuePDF(pdf, item.val2.label, item.val2.value, item.val2.posX, _posInY, _widthArea);

        if (item.val3)
            AddTextAndValuePDF(pdf, item.val3.label, item.val3.value, item.val3.posX, _posInY, _widthArea);

        _posInY += _fontSize + _lineSpacing;
    });

    let imgPainSize = 180;
    let elementNeck: Element;
    if (appointment.svgPainMap1) {
        const imgSvgElement = GetSVGImageEelement(appointment.svgPainMap1, true);
        elementNeck = imgSvgElement.getElementById("assets_img_body_back_svg");

        await AddImageToPDF(pdf, imgSvgElement, { width: imgPainSize, height: imgPainSize, x: 0, y: _posInY })
    }

    if (appointment.svgPainMap2) {
        const imgSvgElement = GetSVGImageEelement(appointment.svgPainMap2, false);
        if (elementNeck) imgSvgElement.append(elementNeck);

        await AddImageToPDF(pdf, imgSvgElement, { width: imgPainSize, height: imgPainSize, x: _width / 2, y: _posInY })
    }


    _posInY += 200;//Image height

    // >> Cuestions.....
    AddNewPage(pdf);
    pdf.setFontSize(9);

    let questionBack = GetQuestionByCategory(1);
    let questionNeck = GetQuestionByCategory(2);

    if (appointment.questions && appointment.questions.length > 0) {
        appointment.questions.forEach(item => {
            const fnd = questionBack.find(o => o.id == item.id);
            if (fnd) fnd.selectedValue = item.value;
            const fnd2 = questionNeck.find(o => o.id == item.id);
            if (fnd2) fnd2.selectedValue = item.value;
        });
    }

    questionBack = questionBack.filter(p => !!p.selectedValue);
    questionNeck = questionNeck.filter(p => !!p.selectedValue);

    let middWidthArea = _widthArea / 2;
    if (questionBack.length > 0) {
        pdf.setFont(undefined, 'bold');
        AddTextAndValuePDF(pdf, "Cuestionario dolor de espalda", null, 0, _posInY, middWidthArea);
        _posInY += _fontSize * 2 + _lineSpacing;

        pdf.setFont(undefined, 'normal');
        for (let i = 0; i < questionBack.length; i++) {
            let item = questionBack[i];
            AddTextQuestionPDF(pdf, item.title, item.answers[item.selectedValue], _margin);
        }
    }

    //Agregar cuestionario en la otra columna
    _posInY = _margin;
    if (questionNeck.length > 0) {
        let posX = questionBack.length > 0 ? middWidthArea : 0;

        pdf.setFont(undefined, 'bold');
        AddTextAndValuePDF(pdf, "Cuestionario dolor de cuello", null, posX, _posInY, middWidthArea);
        _posInY += _fontSize * 2 + _lineSpacing;

        pdf.setFont(undefined, 'normal');
        for (let i = 0; i < questionNeck.length; i++) {
            let item = questionNeck[i];
            AddTextQuestionPDF(pdf, item.title, item.answers[item.selectedValue], posX + _margin);
        }
    }

    pdf.setFontSize(_fontSize);

    if (appointment.scalePain > -1) {
        //Agregar escala de dolor
        pdf.setFont(undefined, 'bold');
        AddTextAndValuePDF(pdf, "Escala de dolor", null, 0, _posInY += _lineSpacing, middWidthArea);
        _posInY += _fontSize;

        const svgScaleImg = AddSvgStringToImg(STR_IMG_SCALE_EMOGI);
        await AddImageToPDF(pdf, svgScaleImg, { width: _widthArea, height: 50, x: _margin, y: _posInY })

        //Agregar valor escala dolor:
        _posInY += 60;
        LineScalePain(pdf, _posInY, appointment.scalePain);

        _posInY += 20;
        const svgScaleImgValues = AddSvgStringToImg(STR_IMG_SCALE_VALUES);
        await AddImageToPDF(pdf, svgScaleImgValues, { width: _widthArea, height: 50, x: _margin, y: _posInY })
    }

    if (appointment.subjetivos || appointment.objsAprecs || appointment.planes) {
        AddTextAndInput(pdf, "Subjetivos", appointment.subjetivos, 0, _posInY, _widthArea);
        AddTextAndInput(pdf, "Objetivos apreciativos", appointment.objsAprecs, 0, _posInY, _widthArea);
        AddTextAndInput(pdf, "Planes", appointment.planes, 0, _posInY, _widthArea);
    }


    return pdf;
}

function AddTextAndValuePDF(pdf: jsPDF, label: string, value: string, margin: number, posY: number, width: number) {
    let posInX = margin + (_margin * 0.5);
    pdf.setTextColor(_colorFont);
    pdf.text(label, posInX, posY, { maxWidth: width });

    if (value) {
        pdf.setTextColor(_colorSecundaryFont);
        pdf.text(value, posInX + pdf.getTextWidth(label), posY, { maxWidth: width })
    }

    if (_posInY >= _height - _margin) {
        AddNewPage(pdf);  // Restart height position
    }
}

function AddTextQuestionPDF(pdf: jsPDF, question: string, response: string, posX: number) {
    let middlePage = _widthArea / 2;

    pdf.setTextColor(_colorFont);
    pdf.text(question, posX, _posInY, { maxWidth: middlePage });

    _posInY += _fontSize + _lineSpacing * 0.25;
    pdf.setTextColor(_colorSecundaryFont);
    pdf.text(response, posX, _posInY, { maxWidth: middlePage })

    _posInY += _fontSize + _lineSpacing;

    if (_posInY >= _height - _margin) {
        AddNewPage(pdf);
    }
}

async function AddImageToPDF(pdf: jsPDF, iconSvg: Element, output: { width: number, height: number, x: number, y: number }) {
    let { width, height } = { width: 1080, height: 1080 }

    let outerHTML = iconSvg.outerHTML;
    // const uriData = 'data:image/svg+xml;base64,' + window.btoa(outerHTML);
    let blob = new Blob([outerHTML], { type: 'image/svg+xml;charset=utf-8' });
    let URL = window.URL || window.webkitURL;
    let blobURL = URL.createObjectURL(blob);

    return new Promise((resolve, reject) => {
        let image = new Image();
        image.onload = function () {
            const canvas = document.createElement("canvas");
            [canvas.width, canvas.height] = [width, height];

            const ctx = canvas.getContext("2d");
            ctx.drawImage(image, 0, 0, width, height);

            const svgData = canvas.toDataURL();

            pdf.addImage(svgData, 'PNG', output.x, output.y, output.width, output.height);

            resolve(svgData);
        };

        image.onerror = (ev) => {
            console.log(ev);
            reject()
        }

        image.src = blobURL;
        // image.src = uriData;
    });
}

function GetSVGImageEelement(svgInner: string, isFront: boolean) {
    // //Quitar classes
    svgInner = svgInner.replaceAll(`class="line-pain"`, `stroke-width="5px" stroke-linecap="round" cursor="pointer"`)

    const tempObj = {};
    const _id = isFront ? EArrPainTempName.SVG_PAIN_FRONT : EArrPainTempName.SVG_PAIN_BACK;
    tempObj[_id] = SvgStringToSvgArrows(svgInner);


    const iconSvg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    iconSvg.setAttribute('xmlns', "http://www.w3.org/2000/svg")
    iconSvg.setAttribute('xmlns:xlink', "http://www.w3.org/1999/xlink")
    iconSvg.setAttribute('fill', '#FFF');
    iconSvg.setAttribute('id', "svg_body_container" + _id);
    iconSvg.setAttribute('viewBox', '0 0 400 400');
    iconSvg.setAttribute('width', '100%');
    iconSvg.setAttribute('height', '100%');
    iconSvg.setAttribute('preserveAspectRatio', 'xMidYMid meet');
    iconSvg.setAttribute('pointerEvents', 'none');

    const imgSvg = isFront ? STR_BODY_FRONT : STR_BODY_BACK;

    iconSvg.innerHTML = `
        <defs>
            <symbol viewBox="0 0 627.347 1492.862" overflow="invisible" preserveAspectRatio="xMidYMid meet" id="content_body_back_svg">
                ${imgSvg}
            </symbol>
        </defs>
        <g class="g-use-container">
            <use width='168' height='400' x=155 y=0 href='#content_body_back_svg'></use>
        </g>
        <g class="g-arrows">
            ${svgInner}
        </g>`;

    AddMarkerArrow(select(iconSvg).select("defs"), true);


    return iconSvg;
}

function AddSvgStringToImg(innerSvg: string) {
    const iconSvg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    iconSvg.setAttribute('xmlns', "http://www.w3.org/2000/svg")
    iconSvg.setAttribute('xmlns:xlink', "http://www.w3.org/1999/xlink")
    iconSvg.setAttribute('fill', '#FFF');
    iconSvg.setAttribute('viewBox', '0 0 1135 140');
    iconSvg.setAttribute('preserveAspectRatio', 'xMidYMid meet');
    iconSvg.setAttribute('pointerEvents', 'none');

    iconSvg.innerHTML = innerSvg;

    return iconSvg;
}

function LineScalePain(pdf: jsPDF, posY: number, valSelected: number) {
    const mrgn = _margin * 1.5;
    // pdf.setDrawColor("#cccccc");
    pdf.setDrawColor(_colorGray);
    pdf.setTextColor(_colorGray);
    pdf.setFillColor(_colorGray);
    pdf.line(mrgn, posY, _width - mrgn, posY, 'F');

    const totWidth = _width - mrgn * 2;
    const sec10 = totWidth / 10;
    for (let i = 0; i < 11; i++) {
        if (valSelected == i) {
            pdf.setDrawColor(_colorFont);
            pdf.setTextColor(_colorFont);
            pdf.setFillColor(_colorFont);
        }

        let posx = mrgn + (sec10 * i)
        pdf.circle(posx, posY, 3, 'F');

        pdf.text("" + i, posx, posY + 12, { maxWidth: 20, align: 'center' });

        if (valSelected == i) {//Resetear
            pdf.setDrawColor(_colorGray);
            pdf.setTextColor(_colorGray);
            pdf.setFillColor(_colorGray);
        }
    }


}

function AddTextAndInput(pdf: jsPDF, label: string, value: string, margin: number, posY: number, width: number) {
    let posInX = margin + (_margin * 0.5);
    const posYOrg = posY;
    pdf.setTextColor(_colorFont);
    pdf.text(label, posInX, posY, { maxWidth: width });
    const hgtRect = 60;

    posY += 8;
    //Draw rect
    pdf.line(_margin, posY, _width - _margin, posY);
    pdf.line(_margin, posY, _margin, posY + hgtRect);
    pdf.line(_width - _margin, posY, _width - _margin, posY += hgtRect);
    pdf.line(_margin, posY, _width - _margin, posY);

    if (value) {
        pdf.setTextColor(_colorSecundaryFont);
        pdf.text(value, _margin + 7, posYOrg + 20, { maxWidth: width - 5 })
    }

    posY += 15;
    _posInY = posY;
    if (_posInY >= _height - _margin) {
        AddNewPage(pdf);  // Restart height position
    }

}

function AddNewPage(pdf: jsPDF) {
    pdf.addPage();
    _posInY = _margin;  // Restart height position
}
