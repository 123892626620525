import { IPatient } from "../../interfaces/patient";

export interface IBtnTypePain {
    id: number,
    idStr: string,
    label: String,
    color: string
}

export interface IQuestion {
    id: number,
    title: string,
    answers: Array<string>,
    category: number,
    in_use: boolean,
    updated_at: string,
    selectedValue: number
}

export interface IPainMapValues {
    patient?: IPatient;
    typePain?: number;
    svgPainFront?: string;
    svgPainBack?: string;
    _svgPainFront?: Array<any>;
    _svgPainBack?: Array<any>;
    questions?: Array<{ id: number, value?: number }>;
    scalePain?: number;
    subjetivos?: string;
    objapreciativo?: string;
    planes?: string;
}

export enum EArrPainTempName {
    SVG_PAIN_FRONT = '_svgPainFront',
    SVG_PAIN_BACK = '_svgPainBack',
    SVG_PAIN_3SIDES = '_svgPAin3Sides'
}