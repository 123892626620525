import { IUser } from "./user";

export interface IPatient {
  id: number;
  name: string;
  lastname?: string;
  birthDate: string;
  description?: string;
  email: string;
  phone: string;
  createdAt?: string;
  updatedAt?: string;
  gender: number;
  idPhoto: number;
  age: number;
  inUse: boolean;
  createdBy?: number;
  updatedBy?: number;
  // status?: string;
  // img?: string;

  _user?: IUser;
  hasForm: boolean;
}

export interface IAppointment {
  id: number;
  idPatient: number;
  forDate: string;
  forTime?: string;
  painType: EPainType;
  svgPainMap1: string;
  svgPainMap2: string;
  questionBack?: string;
  questionNeck?: string;
  status: number;
  inUse: number;
  createdBy?: number;
  updatedBy?: number;
  createdAt?: string;
  updatedAt?: string;
  description?: string;
  questions?: IQuestionReq[];
  scalePain: number;
  subjetivos: string;
  objsAprecs: string;
  planes: string;

  _patient?: IPatient;
  _fromTo?: string;
  _user?: IUser;
}

export interface IBilling {
  billNo: number;
  billDate: string;
  patient: string;
  doctor: string;
  charges: number;
  tax: number;
  discount: number;
  total: number;
}

export interface IDepartment {
  img: string;
  title: string;
  desc: string;
  team: string[];
}


export enum EPainType {
  NONE = 0,
  BACK = 1,
  NECK = 2,
  BOTH = 3
}

export const mapPainType: Map<EPainType, string> = new Map([
  [EPainType.BACK, "Espalda"],
  [EPainType.NECK, "Cuello"],
  [EPainType.BOTH, "Ambos"],
]);

interface IQuestionReq {
  id: number;
  value: number;
}