import { useFetchPageData } from "../hooks/usePage";
import { IResultRequest } from "../interfaces/Entities";
import { IUser } from "../interfaces/user";
import { SetProfileImage, SetUserInfo, USER_INFO, _API } from "./General";
import { RequestData } from "./RequestData"

export const mapUsers: Map<number, IUser> = new Map();

export const LogginUser = (user: string, pass: string, completion: (succes: number, res: IUser) => void) => {

    let params = {
        user: user,
        password: pass
    }

    const url = _API + 'user/LoggIn';
    new RequestData(url)
        .SetParams(params)
        .SetCallback(res => {
            let success: number = res ? res.success : -100;
            let objInfo: IUser;
            if (success === 1) {
                objInfo = res.data;
                SetLocalUserData(objInfo);
            }

            completion(success, objInfo);
        }).Request();
}

export const SetLocalUserData = (user: IUser) => {
    SetUserInfo(user);
    localStorage.setItem("userInfo", JSON.stringify(user));
}

export const GetUsers = (): [Array<IUser>, (data: Array<IUser>) => void] => {
    let _pts = Array.from(mapUsers.values());
    _pts.sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime());

    let maxUpdated = null;
    if (_pts.length > 0) maxUpdated = _pts[0].updatedAt;

    let urlReq = `${_API}user/GetUsers?lastUpdated=${maxUpdated}`;
    const [dataResult, SetData] = useFetchPageData<IResultRequest<IUser[]>>(urlReq);

    if (dataResult && dataResult.success == 1) {
        ValidAndSetUsers(dataResult.data);
    }
    const fnc = (resDta: Array<IUser>): void => {
        SetData({ success: 1, message: "", data: resDta });
    }

    return [Array.from(mapUsers.values()), fnc];
}

export const ValidAndSetUsers = (data: IUser[]) => {
    data.forEach(item => {
        if (!item.inUse)
            mapUsers.delete(item.id)
        else {
            item.img = SetProfileImage(item.img);
            mapUsers.set(item.id, item);
        }
    });
}

export const UpdateAndSaveUser = (user: IUser, toUpdate: boolean, completion: (result: number) => void) => {
    let params = {
        idUser: USER_INFO.id,
        name: user.name,
        lastname: user.lastname,
        birthDate: user.birthDate,
        gender: user.gender,
        phone: user.phone,
        address: user.address,
        email: user.email
    }

    if (toUpdate) params['id'] = user.id;

    let urlReq = `${_API}user/${toUpdate ? 'UpdateUser' : 'CreateUser'}`;

    new RequestData(urlReq, 'POST')
        .SetParams(params)
        .SetCallback(response => {
            let success = response ? response.data : -100;
            completion(success);
        }).Request();
}

export const ChangeUserPassword = (idUser: number, newPass: string, oldPass: string, completion: (result: number) => void) => {
    let params = {
        idUser: idUser,
        idUserLogged: USER_INFO.id,
        newPassword: newPass,
        oldPassword: oldPass || ""
    }

    let urlReq = `${_API}user/ChangePassword`;
    new RequestData(urlReq, 'POST')
        .SetParams(params)
        .SetCallback(response => {
            let success = response ? response.data : -100;
            completion(success);
        }).Request();

}

export const RemoveUserData = (idUser: number, completion: (result: number) => void) => {
    let params = {
        id: idUser,
        idUser: USER_INFO.id
    }

    let urlReq = `${_API}user/RemoveUser`;
    new RequestData(urlReq, 'POST')
        .SetParams(params)
        .SetCallback(response => {
            let success = response ? response.data : -100;
            completion(success);
        }).Request();

}


export const RecoverPass = (email: number, completion: (result: number) => void) => {
    let params = {
        email: email
    }

    let urlReq = `${_API}email/SendEmail`;
    new RequestData(urlReq, 'POST')
        .SetParams(params)
        .SetCallback(response => {
            let success = response ? response.data : -100;
            completion(success);
        }).Request();

}