import { useFetchPageData } from "../hooks/usePage";
import { IResultRequest } from "../interfaces/Entities";
import { IAppointment, IPatient } from "../interfaces/patient";
import { ETypeUser } from "../interfaces/user";
import { USER_INFO, _API } from "./General";
import { mapPatients } from "./Patient";
import { RequestData } from "./RequestData";
import { mapUsers } from "./User";

export const mapAppointments: Map<number, IAppointment> = new Map();
var SetData: (data: IResultRequest<IAppointment[]>) => void;

export function LoadAppointments(): [Array<IAppointment>, (data: Array<IAppointment>) => void] {
    let _pts = Array.from(mapAppointments.values());
    _pts.sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime());

    let maxUpdated = null;
    if (_pts.length > 0) maxUpdated = _pts[0].updatedAt;

    let urlReq = `${_API}appointment/GetAppointments?lastUpdated=${maxUpdated}`;
    const [dataResult, SetDataTemp] = useFetchPageData<IResultRequest<IAppointment[]>>(urlReq);
    SetData = SetDataTemp;

    if (dataResult && dataResult.success == 1) {
        ValidAndSetAppointments(dataResult.data);
    }


    let listResult = Array.from(mapAppointments.values());
    listResult = listResult.sort((a, b) => new Date(b.forDate).getTime() - new Date(a.forDate).getTime());
    return [listResult, fnc];
}

const fnc = (resDta: Array<IAppointment>): void => {
    SetData({ success: 1, message: "", data: resDta });
}

export const ValidAndSetAppointments = (data: IAppointment[]) => {
    data.forEach(item => {
        if (!item.inUse)
            mapAppointments.delete(item.id)
        else {
            item._patient = mapPatients.get(item.idPatient);
            item._user = mapUsers.get(item.createdBy);
            mapAppointments.set(item.id, item);
            if (item.questions && typeof item.questions == 'string') item.questions = JSON.parse(item.questions as any);
        }
    });
}

export const SetPatientInAppointment = (patient: IPatient): void => {
    let fnd = Array.from(mapAppointments.values()).find(o => o.idPatient == patient.id);
    if (fnd) {
        mapAppointments.get(fnd.id)._patient = patient;
    }
};

export const UpdateAppointment = (appointment: IAppointment, completion: (result: number) => void) => {
    let params = {
        id: appointment.id,
        idUser: USER_INFO.id,
        idPatient: appointment.idPatient,
        forDate: appointment.forDate,
        painType: appointment.painType,
        description: appointment.description
    }

    let urlReq = `${_API}appointment/UpdateAppointment`;
    new RequestData(urlReq, 'POST')
        .SetParams(params)
        .SetCallback(response => {
            let success = response ? response.data : -100;
            completion(success);
        }).Request();
}

export const CreateAppointment = (appointment: IAppointment, completion: (result: number) => void) => {
    let params = {
        idUser: USER_INFO.id,
        idPatient: appointment.idPatient,
        forDate: appointment.forDate,
        painType: appointment.painType,
        description: appointment.description
    }

    let urlReq = `${_API}appointment/CreateAppointment`;
    new RequestData(urlReq, 'POST')
        .SetParams(params)
        .SetCallback(response => {
            let success = response ? response.data : -100;
            completion(success);
        }).Request();
}

export const RemoveAppointment = (appointment: IAppointment, completion: (result: number) => void) => {
    let params = {
        idUser: USER_INFO.id,
        id: appointment.id
    }

    let urlReq = `${_API}appointment/RemoveAppointment`;
    new RequestData(urlReq, 'POST')
        .SetParams(params)
        .SetCallback(response => {
            let success = response ? response.data : -100;
            completion(success);
        }).Request();
}

export function AppointmentByPatient(idPatient: number): Array<IAppointment> {
    let urlReq = `${_API}appointment/GetAppointmentByPatient?idPatient=${idPatient}`;
    const [dataResult] = useFetchPageData<IResultRequest<IAppointment[]>>(urlReq);

    let resData: IAppointment[] = [];

    if (dataResult && dataResult.success == 1) {
        resData = dataResult.data || [];
    }

    resData = resData.sort((a, b) => b.id - a.id);

    return resData;
}

export function LastPatientAppointment(idPatient: number) {
    let listData = Array.from(mapAppointments.values());
    listData = listData.sort((a, b) => b.id - a.id);

    return listData.find(o => o.idPatient == idPatient);
}

export function LoadAppointmentsV2(idUser?: number): [Array<IAppointment>, (data: Array<IAppointment>) => void] {
    let _pts = Array.from(mapAppointments.values());
    _pts.sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime());

    let maxUpdated = null;
    if (_pts.length > 0) maxUpdated = _pts[0].updatedAt;

    let urlReq = `${_API}formulario/GetFormulariosStudent?`;
    if (USER_INFO.typeUser == ETypeUser.STUDENT) urlReq += `idUser=${USER_INFO.id}&`;
    urlReq += `lastUpdated=${maxUpdated}`;

    const [dataResult, SetDataTemp] = useFetchPageData<IResultRequest<IAppointment[]>>(urlReq);
    SetData = SetDataTemp;

    if (dataResult && dataResult.success == 1) {
        ValidAndSetAppointments(dataResult.data);
    }


    let listResult = Array.from(mapAppointments.values());
    listResult = listResult.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());

    if (idUser) listResult = listResult.filter(o => o.createdBy == idUser);

    return [listResult, fnc];
}