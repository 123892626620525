import jsPDF from "jspdf";
import { EQuestionGroup } from "../IconPainEVA";
import { IPatient } from "../../../interfaces/patient";
import { SectionQuestion } from "../ExpedienteQuestions";
import { GetShortDescriptiveDate, _APP_ID } from "../../../data/General";
import { APP_IDENTIFIER } from "../../../interfaces/app-state";

const _margin = 25;
var _height = 450;
var _width = 380;
var _widthArea = _width;
var _posInY = _margin;
const _fontSize = 9;
const _fontSzeTitle = 10;
const _logoWidth = 144;
const _logoHeight = 22;
const _colorFont = "#000";
const _colorSecundaryFont = "#696969";
var _currentPage = 1;
const _hgtTextArea = 26;


export const BlobExpedienteNewPDF = async (listData: any) => {
    let pdf = await fnPDFCreate(listData)
    let uri = pdf.output('blob')

    return uri;
}

export const SaveExpedientePDF = async (listData: any) => {
    let pdf = await fnPDFCreate(listData);
    let patient: IPatient = listData[0];
    pdf.save("Expediente_pagina1_" + patient.name);
}

async function fnPDFCreate(listData: any) {
    let patient: IPatient = listData[0];
    const questionsHeader: EQuestionGroup[] = listData[1];
    const questionsPage1: SectionQuestion[] = listData[2];

    const METADATA = {
        title: 'Paciente: ' + patient.name,
        subject: 'Informacion del formulario',
        author: 'Tino 710',
        keywords: 'Dr. pedro reyes, formulario de paciente',
        creator: 'Dr. Admin'
    };

    const pdf = new jsPDF({
        orientation: "portrait",
        unit: "px",
        format: "letter",
    });

    _width = pdf.internal.pageSize.getWidth();
    _height = pdf.internal.pageSize.getHeight();

    _widthArea = (_width - _margin * 2)
    _posInY = _margin;


    // >> METADATA
    pdf.setProperties(METADATA);
    pdf.setFontSize(_fontSize)

    AddHeaderPdf(pdf, patient);

    _posInY += 20;//Image height

    pdf.setFont(undefined, 'normal');

    if (_APP_ID == APP_IDENTIFIER.APP_DISCAPACIDAD)
        questionsHeader[0].questions.splice(0, 1);


    for (let i = 0; i < questionsHeader.length; i++) {
        const item = questionsHeader[i];
        const wth = _widthArea / item.questions.length;

        if (item.title) {
            AddTitleText(pdf, item.title, 0, _posInY, _widthArea, false, _fontSzeTitle);
            _posInY += 15;
        }

        for (let j = 0; j < item.questions.length; j++) {
            const subitem = item.questions[j];

            let value = subitem.value;
            if (subitem.type == 'RADIO') value = subitem.options[+value].tag;
            const lbl = subitem.label + ': ';

            AddTextAndValuePDF(pdf, lbl, value, wth * j, _posInY, wth);
        }

        _posInY += 15;
    }

    pdf.line(_margin / 2, _posInY, _width - _margin, _posInY);
    _posInY += 15;

    const tempCurrentPage = pdf.getCurrentPageInfo().pageNumber;
    const currentPosY = _posInY;
    let pares = questionsPage1.filter(o => o.id % 2 == 1);
    let impares = questionsPage1.filter(o => o.id % 2 == 0);

    AddQuestionParInpar(pdf, pares, true);
    _posInY = currentPosY;
    _currentPage = tempCurrentPage;
    pdf.setPage(tempCurrentPage);
    AddQuestionParInpar(pdf, impares, false);

    return pdf;
}

function AddQuestionParInpar(pdf: jsPDF, questions: SectionQuestion[], isLeft: boolean) {
    const wth = _widthArea / 2 - 10;
    const posInitialX = isLeft ? 0 : wth + 5;

    for (let i = 0; i < questions.length; i++) {
        const item = questions[i];

        if (item.title) {
            AddTitleText(pdf, item.title, posInitialX, _posInY, wth, true);
            _posInY += 15;
        }

        for (let j = 0; j < item.rowInfo.length; j++) {
            const subitem = item.rowInfo[j];
            const hgTitlSub = AddTitleText(pdf, subitem.title, posInitialX, _posInY, wth, false, 9);
            _posInY += hgTitlSub + 2;

            const wthZ = wth / subitem.questions.length;
            for (let z = 0; z < subitem.questions.length; z++) {
                const elmnt = subitem.questions[z];
                const xWthZ = posInitialX + (wthZ * z);

                if (elmnt.type == 'TEXT_AREA') {
                    if (elmnt.label) {
                        _posInY += 8;
                        AddTitleText(pdf, elmnt.label || '', posInitialX, _posInY, wth, false, 9);
                        _posInY += 8;
                    } else if (z > 0) {
                        _posInY += 8;
                    }

                    AddTextAndValuePDF(pdf, '', elmnt.value, posInitialX, _posInY, wth);
                    _posInY += _hgtTextArea;
                } else if (elmnt.type == 'SHORT') {
                    let lblShort = elmnt.label || elmnt.hintText;
                    lblShort += ': ';
                    AddTextAndValuePDF(pdf, lblShort, elmnt.value, xWthZ, _posInY, wthZ);
                } else if (elmnt.type == 'CHECK') {
                    const arrValues = elmnt.value ? elmnt.value.split(',') : [];
                    let listValues = '';
                    arrValues.forEach((valChk, zx) => {
                        const fndOpChk = elmnt.options.find(o => o.id == valChk);
                        if (fndOpChk) listValues += (zx == 0 ? '' : ', ') + fndOpChk.tag;
                    });

                    AddTextAndValuePDF(pdf, '', listValues, posInitialX, _posInY, wth);

                } else if (elmnt.type == 'RADIO') {
                    let valRad = ' - ';
                    if (elmnt.value) valRad = elmnt.options.find(o => o.id == +elmnt.value).tag;
                    const lblRad = elmnt.label ? (elmnt.label + ': ') : '';
                    AddTextAndValuePDF(pdf, lblRad, valRad, xWthZ, _posInY, wthZ);
                } else if (elmnt.type == 'LARGE') {
                } else if (elmnt.type == 'SCALE_ICON') {
                    // console.log(elmnt)
                    const hgRes = AddTextAndValuePDF(pdf, elmnt.label, null, posInitialX, _posInY, wth);
                    _posInY += hgRes + 2;
                    AddTextAndValuePDF(pdf, 'Valor escala de dolor:  ', elmnt.value, (posInitialX + 8), _posInY, wth);
                    _posInY += 15;
                }
            }

            _posInY += 15;
        }
    }
}

function AddHeaderPdf(pdf: jsPDF, patient: IPatient) {
    pdf.addImage("/logo_main.png", 'PNG', _margin, _posInY - 5, _logoWidth, _logoHeight);

    const xAfterLogo = _logoWidth + 30;
    AddTitleText(pdf, "Nombre del paciente: " + (patient.name + (patient.lastname ? (' ' + patient.lastname) : '')), xAfterLogo, _posInY, _widthArea, true, _fontSzeTitle);
    AddTitleText(pdf, "Fecha de nacimiento: " + GetShortDescriptiveDate(patient.birthDate), xAfterLogo, _posInY + 10, _widthArea, true, _fontSzeTitle);
    const years = new Date().getFullYear() - new Date(patient.birthDate).getFullYear();
    const genreAge = (patient.gender == 1 ? 'Masculino, ' : 'Femenino, ') + years + " años";
    AddTitleText(pdf, genreAge, xAfterLogo, _posInY + 20, _widthArea, true, _fontSzeTitle);

    _posInY += _logoHeight + 10;

    // >> MARGIN LINES
    pdf.setDrawColor("#E0E0E0");
    pdf.line(_margin, _posInY, _width - _margin, _posInY);
}

function AddTitleText(pdf: jsPDF, label: string, posX: number, posY: number, width: number, textBold: boolean, fontSize?: number): number {
    fontSize = fontSize || 11;
    pdf.setFontSize(fontSize);
    if (textBold) pdf.setFont(undefined, 'bold');
    const hgt = AddTextAndValuePDF(pdf, label, null, posX, posY, width);
    pdf.setFontSize(9);
    pdf.setFont(undefined, 'normal');
    return hgt;
}


function AddTextAndValuePDF(pdf: jsPDF, label: string, value: string, posX: number, posY: number, width: number): number {
    let posInX = posX + (_margin * 0.5);
    pdf.setTextColor(_colorFont);
    pdf.text(label, posInX, posY, { maxWidth: width });

    if (value) {
        if (typeof value == 'number') value = '' + value;
        pdf.setTextColor(_colorSecundaryFont);
        pdf.text(value, posInX + pdf.getTextWidth(label), posY, { maxWidth: width })
    }

    if (_posInY >= _height - _margin) {
        AddNewPage(pdf);  // Restart height position
    }

    return pdf.getTextDimensions(label, { maxWidth: width }).h;
}


function AddNewPage(pdf: jsPDF) {
    if (_currentPage < pdf.getNumberOfPages())
        pdf.setPage(_currentPage + 1);
    else
        pdf.addPage();

    _currentPage = pdf.getCurrentPageInfo().pageNumber

    _posInY = _margin;  // Restart height position
}
