import { Card } from 'antd';

import ReactEcharts from 'echarts-for-react';

import {
  patientsGenderOptions,
  departmentsOptions,
  patientsAgeOptions
} from './charts/patients-options';

import { useFetchPageData, usePageData } from '../../hooks/usePage';

import { IPageData } from '../../interfaces/page';
import { _API } from '../../data/General';
import { ISummary } from '../../data/Entities';
import { fetchPatients } from '../../redux/patients/actions';
import { LoadPatients } from '../../data/Patient';
import { EPainType, IAppointment, IPatient } from '../../interfaces/patient';
import { LoadAppointments } from '../../data/Appointment';
import AppointmentsTable from '../appointments/AppointmentsTable';
import { useNavigate } from 'react-router-dom';


const pageData: IPageData = {
  fulFilled: false,
  breadcrumbs: [
    {
      title: 'Dashboards',
      route: 'default-dashboard'
    },
    {
      title: 'Default'
    }
  ]
};



const OnSummaryResult = (summary: any, patients: IPatient[], appointments: IAppointment[]): ISummary => {
  var _summary: ISummary = summary ? summary.data : { users_admin: 0, all_appointments: 0, allPatients: 0, pending_appointments: 0, allUsers: 0, patients_female: -1, patients_male: -1, users_receptionist: 0 };
  _summary.allPatients = _summary.patients_female + _summary.patients_male;
  _summary.allUsers = _summary.allPatients + _summary.users_admin + _summary.users_receptionist;

  patientsGenderOptions.series[0].data = [];
  if (_summary.patients_female > -1 && _summary.patients_male > -1) {
    patientsGenderOptions.series[0].data = [
      { value: _summary.patients_female, name: 'Mujeres' },
      { value: _summary.patients_male, name: 'Hombres' }
    ];
  }

  departmentsOptions.series[0].data = [];
  if (_summary.allUsers > 0) {
    departmentsOptions.series[0].data = [
      { value: 0, name: 'Espalda' },
      { value: 0, name: 'Cuello' },
      { value: 0, name: 'Ambos' }
    ];


    appointments.forEach(item => {
      if (item.painType == EPainType.BACK)
        departmentsOptions.series[0].data[0].value += 1;
      else if (item.painType == EPainType.BOTH)
        departmentsOptions.series[0].data[1].value += 1;
      else //Ambos
        departmentsOptions.series[0].data[2].value += 1;
    });

  }

  patientsAgeOptions.series[0].data = [];
  if (patients && patients.length > 0) {
    patientsAgeOptions.series[0].data = [
      { value: 0, name: "0-10" },
      { value: 0, name: "10-20" },
      { value: 0, name: "20-30" },
      { value: 0, name: "30-40" },
      { value: 0, name: "40+" }
    ]

    patients.forEach(item => {
      let agePos = Math.floor(item.age / 10);
      if (agePos > 4) agePos = 4;

      patientsAgeOptions.series[0].data[agePos].value += 1;
    });
  }

  return _summary;
}

fetchPatients()

const DashboardPage = () => {
  usePageData(pageData);

  const [patientsResult] = LoadPatients();
  const [appointments] = LoadAppointments();
  const [summary] = useFetchPageData<any>(_API + "user/DataSummary");

  const history = useNavigate();

  const patients = appointments.slice(0, 7);

  var _summary = OnSummaryResult(summary, patientsResult, appointments);

  const OnRowSelected = (appointment: IAppointment) => {
    history(`/vertical/patient-profile`, { state: appointment._patient });
  }

  return (
    <>
      <div className='row'>
        <div className='col-12 col-md-6 col-xl-3'>
          <Card style={{ background: 'rgba(251, 251, 251)' }} className='animated with-shadow'>
            <div className='row'>
              <div className='col-5'>
                <span
                  className='icofont icofont-first-aid-alt'
                  style={{ fontSize: 48, color: 'rgba(51, 108, 251, 0.5)' }}
                />
              </div>

              <div className='col-7'>
                <h6 className='mt-0 mb-1'>Citas</h6>
                <div className='count' style={{ fontSize: 20, color: '#336cfb', lineHeight: 1.4 }}>
                  {_summary.all_appointments}
                </div>
              </div>
            </div>
          </Card>
        </div>

        <div className='col-12 col-md-6 col-xl-3'>
          <Card style={{ background: 'rgba(251, 251, 251)' }} className='animated with-shadow'>
            <div className='row'>
              <div className='col-5'>
                <span
                  className='icofont icofont-wheelchair'
                  style={{ fontSize: 48, color: 'rgba(51, 108, 251, 0.5)' }}
                />
              </div>

              <div className='col-7'>
                <h6 className='mt-0 mb-1'>Pendientes</h6>
                <div className='count' style={{ fontSize: 20, color: '#336cfb', lineHeight: 1.4 }}>
                  {_summary.pending_appointments}
                </div>
              </div>
            </div>
          </Card>
        </div>

        <div className='col-12 col-md-6 col-xl-3'>
          <Card style={{ background: 'rgba(251, 251, 251)' }} className='animated with-shadow'>
            <div className='row'>
              <div className='col-5'>
                <span
                  className='icofont icofont-blood'
                  style={{ fontSize: 48, color: 'rgba(51, 108, 251, 0.5)' }}
                />
              </div>

              <div className='col-7'>
                <h6 className='mt-0 mb-1'>Usuarios</h6>
                <div className='count' style={{ fontSize: 20, color: '#336cfb', lineHeight: 1.4 }}>
                  {_summary.allUsers}
                </div>
              </div>
            </div>
          </Card>
        </div>

        <div className='col-12 col-md-6 col-xl-3'>
          <Card style={{ background: 'rgba(251, 251, 251)' }} className='animated with-shadow'>
            <div className='row'>
              <div className='col-5'>
                <span
                  className='icofont icofont-dollar-true'
                  style={{ fontSize: 48, color: 'rgba(51, 108, 251, 0.5)' }}
                />
              </div>

              <div className='col-7'>
                <h6 className='mt-0 mb-1'>Pacientes</h6>
                <div className='count' style={{ fontSize: 20, color: '#336cfb', lineHeight: 1.4 }}>
                  {_summary.allPatients}
                </div>
              </div>
            </div>
          </Card>
        </div>
      </div>

      {/* <Card title='Dr. Pedro Reyes'>
        <ReactEcharts className='chart-container container-h-400' option={hospitalOptions} />
      </Card> */}

      <div className='row'>
        <div className='col-12 col-md-4'>
          <Card title={'Edades de pacientes'}>
            {patientsAgeOptions.series[0].data.length > 0 &&
              <ReactEcharts className='chart-container container-h-300' option={patientsAgeOptions} lazyUpdate />
            }
          </Card>
        </div>

        <div className='col-12 col-md-4'>
          <Card title={'Generos'}>
            {/* FIXME: Checar como actualizar en tiempo real*/}
            {patientsGenderOptions.series[0].data.length > 0 &&
              <ReactEcharts className='chart-container container-h-300' option={patientsGenderOptions} />
            }
          </Card>
        </div>

        <div className='col-12 col-md-4'>
          <Card title={'Tipos de dolor'}>
            {departmentsOptions.series[0].data.length > 0 &&
              <ReactEcharts className='chart-container container-h-300' option={departmentsOptions} notMerge={true} />
            }
          </Card>
        </div>
      </div>

      <Card title='Ultimas citas' className='mb-0'>
        <AppointmentsTable data={patients} OnRowSelected={OnRowSelected} />
      </Card>
    </>
  );
};

export default DashboardPage;
