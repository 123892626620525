import { USER_INFO, _API } from "../../data/General";
import { RequestData } from "../../data/RequestData";

export function AddValoracionMapaDolor(
    hasForm: boolean, idPatient: number, painType: number, svgPainFront: string, svgPainBack: string, questions: Array<any>,
    scalePain: number, subjetivos: string, objsAprec: string, planes: string, completion: (success: number, idVal: number, idHistory: number) => void
) {
    if (scalePain === undefined) scalePain = -1;

    const params = {
        idUser: USER_INFO.id,
        idPatient: idPatient,
        painType: painType || 0,
        svgPainMap1: svgPainFront,
        svgPainMap2: svgPainBack,
        questions: JSON.stringify(questions),
        scalePain: scalePain,
        subjetivos: subjetivos,
        objsAprec: objsAprec,
        planes: planes,

    }

    let urlReq = `${_API}formulario/${hasForm ? 'AddItemFormulario' : 'CreateFormulario'}`;

    new RequestData(urlReq, 'POST')
        .SetParams(params)
        .SetCallback((response: { success: number, data: number[], message: string }) => {
            let success = response ? response.success : -100;
            let idVal = success > 0 ? response.data[0] : 0;
            let idExp = success > 0 ? response.data[1] : 0;
            completion(success, idVal, idExp);
        }).Request();
}