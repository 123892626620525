import { useState } from 'react';

import { usePageData } from '../../hooks/usePage';

import PatientsTable from './PatientsTable';

import { IPageData } from '../../interfaces/page';
import { IPatient } from '../../interfaces/patient';
import { LoadPatients, UpdateAndSavePatient } from '../../data/Patient';
import openNotification from '../Toast';
import { useNavigate } from 'react-router-dom';
import { LastPatientAppointment, LoadAppointments } from '../../data/Appointment';

const pageData: IPageData = {
  title: 'Pacientes',
  fulFilled: true,
  breadcrumbs: [
    {
      title: 'Principal',
      route: 'default-dashboard'
    },
    {
      title: 'Pacientes'
    }
  ]
};


const PatientsPage = () => {
  usePageData(pageData);

  const [patients, SetPatients] = LoadPatients();
  const [searchValue, setSearchValue] = useState('');

  const history = useNavigate();

  var filterList: IPatient[];
  if (searchValue === "") filterList = patients;
  else filterList = patients.filter(o => o.name.toLowerCase().includes(searchValue.toLowerCase()) || (o.lastname && o.lastname.toLowerCase().includes(searchValue.toLowerCase())));

  pageData.OnChangeSearch = (res) => {
    setSearchValue(res);
  }


  const OnEditPatient = (patient: IPatient) => {
    UpdateAndSavePatient(patient, true, (result) => {
      if (result > 0) {
        const editedPatients = patients.map((el) => el.id !== patient.id ? el : patient);
        SetPatients(editedPatients);

        openNotification('¡Actualizado!', "Se actualizó el paciente");
      } else {
        openNotification('¡Error!', "Ocurrió un error al actualizar", 'error');
      }
    });
  }

  const [_] = LoadAppointments();

  const OnAppointmentDetail = (patient: IPatient) => {
    let fndAppointment = LastPatientAppointment(patient.id);

    // FIXME: Validar "key"
    if (fndAppointment)
      history("/vertical/apointment-detail", { state: fndAppointment,/* key: 'PATIENT' */ });
  }

  return (
    <>
      <PatientsTable
        onAppointmentDetail={OnAppointmentDetail}
        onEditPatient={OnEditPatient}
        patients={filterList}
      />
    </>
  );
};

export default PatientsPage;
