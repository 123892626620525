import { GetShortDescriptiveDate, SetProfileImage, _APP_ID } from "../../data/General";
import { IPatient } from "../../interfaces/patient";
import { Avatar, Card, Space, Input, Radio, Button } from "antd";
import React, { useState } from 'react';
import { EQuestion, EQuestionGroup } from "./IconPainEVA";
import { NewEmptyExpediente, questionsMainProfile } from "./ExpedienteQuestions";
import { useNavigate } from "react-router";
import { APP_IDENTIFIER } from "../../interfaces/app-state";

const UserPreviewHeader = ({
    patient,
    enableBtnAdd
}: { patient: IPatient, enableBtnAdd: boolean }) => {
    let years = new Date().getFullYear() - new Date(patient.birthDate).getFullYear();
    const history = useNavigate();

    const userAvatar = SetProfileImage(null);

    const OnAddNew = () => {
        const layout = location.hash.split('/')[1];
        const tempExp = NewEmptyExpediente(patient)
        history(`/${layout}/history-expedientepage`, { state: [patient, tempExp], replace: true });
        // location.reload();//VALIDAR,,,,xwxw
    }

    return (
        <>
            <div>
                <div className='row mb-4'>
                    <div className='col-md-12 col-sm-12' style={{ display: 'flex' }}>
                        <div className='info stack'>
                            <Avatar src={userAvatar} size={100} />
                        </div>

                        <div className='div-user-info'>
                            <div className='d-flex align-items-center justify-content-between'>
                                <h5 className='mb-0 mt-0 mr-1'>{patient.name}</h5>
                            </div>
                            <div className='date-birth'>
                                <span className='link-icon icofont icofont-calendar' />
                                <h6>{GetShortDescriptiveDate(patient.birthDate)}</h6>
                            </div>
                            <h6 style={{ color: '#8f8f90' }}>{patient.gender == 1 ? 'Masculino' : 'Femenino'}, {years} años</h6>
                        </div>
                        {/* {(exp && exp.questionMain.length > 0 || !disabled) && (<Button type='default' className='ml-auto' onClick={OnAddNew}> */}
                        {enableBtnAdd && (<Button type='default' className='ml-auto' onClick={OnAddNew}>
                            Crear nuevo expediente
                        </Button>)}
                    </div>
                </div>
            </div>
        </>
    );
};

export const AddInputsProfile = () => {
    return (
        <div className='row mb-4' >
            <div className='col-md-12 col-sm-12'>
                <Card className='with-shadow'>{
                    questionsMainProfile.map((val, i) => {
                        if (_APP_ID == APP_IDENTIFIER.APP_DISCAPACIDAD && i == 0) {//Quitar campo: particular, en ValoracionDiscapacidad.com
                            const idx = val.questions.findIndex(o => o.id == 1);
                            if (idx > -1) val.questions.splice(idx, 1);
                        }

                        return <AddSectionInputsTitle question={val} key={"sect-quest-head" + i} />
                    })
                }</Card>
            </div>
        </div >)
}

const AddSectionInputsTitle = ({
    question
}: { question: EQuestionGroup }) => {

    const [quest, setQuest] = useState(question)

    const OnInputChange = (ev, item: EQuestion) => {
        item.value = ev.target.value;
        setQuest(Object.assign({}, question))
    }

    return (
        <>
            {quest.title && <h5>{quest.title}</h5>}
            {
                <Space>{
                    quest.questions.map((val, i) => {
                        if (val.type == 'SHORT') {
                            val.inputType = val.inputType || 'text';

                            return (
                                <React.Fragment key={"section-head-inpt-" + i}>
                                    <span>{val.label}</span>
                                    <Input type={val.inputType} name='' placeholder='' required onChange={(ev) => OnInputChange(ev, val)} value={val.value} disabled={val.disabled} />
                                </React.Fragment>
                            )
                        } else {//RADIO
                            return (
                                <AddRadioInfo tag={val} key={"section-head-rad-" + i} />
                            )
                        }
                    })
                }
                </Space>
            }
        </>
    )
}

const AddRadioInfo = ({
    tag
}: { tag: EQuestion }) => {
    const OnInputChange = (ev, qst) => {
        qst.value = ev.target.value;
    }

    return (
        <>
            <span>{tag.label}</span>
            <Radio.Group key={"rad-grp-stado"} buttonStyle="solid" onChange={(ev) => OnInputChange(ev, tag)} defaultValue={tag.value} disabled={tag.disabled}>
                {
                    tag.options.map((item, ii) => {
                        return (
                            <Radio.Button key={"rad-btn-" + 'idCard' + "-" + ii} value={item.id} checked={tag.value == item.id}>{item.tag}</Radio.Button>
                        );
                    })
                }
            </Radio.Group>
        </>
    )
}

export default UserPreviewHeader;