import { ReactNode } from 'react';

import { Table } from 'antd';

import { ColumnProps } from 'antd/es/table';
import { IAppointment, IPatient } from '../../../interfaces/patient';
import { IStudent } from '../../interfaces/Student';

const columns: ColumnProps<IAppointment>[] = [
    {
        key: 'name',
        dataIndex: '_patient',
        title: 'Nombre',
        sorter: (a, b) => a._patient && b._patient ? (a._patient.name > b._patient.name ? 1 : -1) : 1,
        render: (patient: IPatient) => <strong>{patient ? patient.name : ' - '} {patient && patient.lastname ? patient.lastname : ''}</strong>
    }, {
        key: 'email',
        dataIndex: '_patient',
        title: 'Email',
        sorter: (a, b) => a._patient && b._patient ? (a._patient.email > b._patient.email ? 1 : -1) : 1,
        render: (patient) => (
            <span className='nowrap' style={{ color: '#336cfb' }}>
                {patient?.email}
            </span>
        )
    }, {
        key: 'painType',
        title: 'Tipo de dolor',
        render: (appointment) => (
            <span className='nowrap' style={{ color: '#a5a5a5' }}>
                {appointment.painType == 1 ? 'Espalda' : (appointment.painType == 2 ? 'Cuello' : (appointment.painType == 3 ? 'Ambos' : 'SOAP'))}
            </span>
        )
    }, {
        key: 'createdAt',
        dataIndex: 'createdAt',
        sorter: (a, b) => (new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()),
        title: 'Fecha',
        render: (date) => (
            <span className='nowrap' style={{ color: '#a5a5a5' }}>
                {date}
            </span>
        )
    }, {
        key: 'user',
        dataIndex: '_user',
        title: 'Registrado por alumno',
        render: (user: IStudent) => (
            <span className='d-flex align-baseline nowrap' style={{ color: '#336cfb' }}>
                {user?.name}
            </span>
        )
    },


];

type Props = {
    data: IAppointment[];
    actions?: (appointment: IAppointment) => ReactNode;
};

const FormularioTable = ({ data, actions }: Props) => {
    const actionColumn: ColumnProps<IAppointment> = {
        key: 'actions',
        title: 'Acciones',
        render: actions
    };

    const displayedColumns = actions ? [...columns, actionColumn] : columns;

    return (
        <Table
            rowKey='id'
            dataSource={data}
            columns={displayedColumns}
            // onRow={(record) => {
            //     return {
            //         onClick: _ => OnRowSelected(record)
            //     };
            // }}
            pagination={{ hideOnSinglePage: true }}
        />
    );
};

export default FormularioTable;
