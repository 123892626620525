import { useState } from 'react';
import { Button, Card, Modal } from 'antd';

import { IPageData } from '../../interfaces/page';

import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import { usePageData } from '../../hooks/usePage';
import { LoadAppointments } from '../../data/Appointment';
import { LoadPatients } from '../../data/Patient';

const headerOptions = {
  left: 'prev,next today',
  center: 'title',
  right: 'dayGridMonth,dayGridWeek,dayGridDay'
};

const pageData: IPageData = {
  title: 'Calendario de citas',
  fulFilled: true,
  breadcrumbs: [
    {
      title: 'Principal',
      route: 'default-dashboard'
    },
    {
      title: 'Citas',
      route: 'appointments'
    },
    {
      title: 'Calendario de citas'
    }
  ]
};

const EventsCalendarPage = () => {
  usePageData(pageData);
  const [event, setEvent] = useState(null);
  const [modalVisibility, setModalVisibility] = useState(false);

  LoadPatients();
  const [appointments] = LoadAppointments();

  const randomColorStatus = (): string => {
    let color = ['#e9e165', '#f56565', '#4299e1', '#ed5564', '#e9e165', '#e9e165'];

    let pos = Math.floor(Math.random() * color.length);
    return color[pos];
  }

  const randomColorClass = (): string => {
    let color = ['event-error', 'event-pink', 'event-orange', 'event-green'];

    let pos = Math.floor(Math.random() * color.length);
    return color[pos];
  }


  ///Validar como usar cualquier tipo de interface que no sea el de calendar

  const events = [];
  appointments.forEach(item => {
    let description = item.description || "";

    events.push({
      title: 'Cita: ' + (item._patient ? item._patient.name : ""),
      color: randomColorStatus(),
      classNames: [randomColorClass()],
      start: new Date(item.forDate),
      // end: setDate(0, 3),
      desc: description
    });
  })

  const closeModal = () => setModalVisibility(false);

  const handleEventClick = (arg: any) => {
    setEvent(arg.event);
    setModalVisibility(true);
  };

  let modalBody, modalTitle, modalFooter;

  if (event) {
    modalBody = (
      <div className='d-flex flex-column'>
        <div className='event-time flex-column mb-4'>
          <h5 className='event-title m-0'>Fecha:</h5>
          <span>
            {event.start.toDateString()} {/*- to: {event.end.toDateString()} */}
          </span>
        </div>

        <div className='event-desc flex-column'>
          <h5 className='event-title m-0'>Descripción</h5>
          <span>{event.extendedProps.desc}</span>
        </div>
      </div>
    );

    modalTitle = (
      <div className='title-block p-0 m-0'>
        <h3 style={{ color: event.backgroundColor }} className='modal-title m-0'>
          {event.title}
        </h3>
      </div>
    );

    modalFooter = (
      <div className='d-flex justify-content-between modal-footer'>
        <Button onClick={closeModal} danger>
          Cerrar
        </Button>
        {/* <Button type='primary'>Change event</Button> */}
      </div>
    );
  }

  return (
    <>
      <Card className='mb-0'>
        <FullCalendar
          eventClick={handleEventClick}
          events={events}
          headerToolbar={headerOptions}
          initialView='dayGridMonth'
          plugins={[dayGridPlugin]}
          dayMaxEvents={true}
          weekends
        />
      </Card>

      <Modal
        title={modalTitle}
        footer={modalFooter}
        open={modalVisibility}
        onCancel={closeModal}
      >
        {modalBody}
      </Modal>
    </>
  );
};

export default EventsCalendarPage;
