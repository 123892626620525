import { Table } from 'antd';
import { ColumnProps } from 'antd/es/table';

interface iDisabilityDescription {
    id: number;
    values: string;
    name: string;
    description?: string;
}


const columnsBack: ColumnProps<iDisabilityDescription>[] = [
    {
        key: 'values',
        title: 'porcentajes ODI:',
        className: 'nowrap',
        align: 'left',
        colSpan: 3,
        render: (row) => <span className='text-align-left'>{row.values}</span>
    },
    {
        colSpan: 0,
        render: (row) => <span className='text-left'>{row.name}</span>
    },
    {
        colSpan: 0,
        render: (row) => <span className='text-left'>{row.description}</span>
    }
];

const columnsNeck: ColumnProps<iDisabilityDescription>[] = [
    {
        key: 'values',
        title: 'Categorías de nivel de incapacidad',
        colSpan: 2,
        align: 'left',
        render: (row) => <span className='text-align-left'>{row.values}</span>
    },
    {
        key: 'name',
        colSpan: 0,
        render: (row) => <span className='text-left'>{row.name}</span>
    }
];

type Props = {
    type: 'NECK' | 'BACK';
};

const listValuesBack: Array<iDisabilityDescription> = [
    { id: 1, values: "0% a 20%", name: "Mínimo de discapacidad", description: "No precisa tratamiento salvo consejos posturales y ejercicio." },
    { id: 2, values: "21% a 40%", name: "Discapacidad moderada", description: "Tratamiento conservador." },
    { id: 3, values: "41% a 60%", name: "Discapacidad grave", description: "Requiere estudio en profundidad." },
    { id: 4, values: "61% a 80%", name: "Paralizado", description: "Se requiere tratamiento activo." },
    { id: 5, values: "81% a 100%", name: "Máxima", description: "Postrado en cama. Evaluación cuidadosa" },
]


const listValuesNeck: Array<iDisabilityDescription> = [
    { id: 1, values: "0 - 4", name: "Ninguna incapacidad" },
    { id: 2, values: "5 - 14", name: "Incapacidad leve" },
    { id: 3, values: "15 - 24", name: "Incapacidad moderada" },
    { id: 4, values: "25 - 34", name: "Incapacidad severa" },
    { id: 5, values: "35 - 50", name: "Incapacidad total" }
];


const DisabilityTable = ({ type }: Props) => {

    const listDisability = type == 'BACK' ? listValuesBack : listValuesNeck;
    const columns = type == 'BACK' ? columnsBack : columnsNeck;

    return (
        <Table
            // className={tableClass}
            pagination={false}
            rowKey='id'
            dataSource={listDisability}
            columns={columns}
        />
    );
};

export default DisabilityTable;
