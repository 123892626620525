import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';

/*
const navigate = useNavigate();
and then replace history.push('/path') with navigate('/path')
Change history.replace('/path') with navigate('/path', { replace: true })
Want to use state in push / navigate do navigate('/path', { state: { name: 'Xyz' } })
*/

export function useNavigateHome() {
  const navigate = useNavigate();

  // return useCallback(() => navigate('/'), [history]);
  return useCallback(() => navigate('/'), []);
}
