import { Card, Button } from 'antd';
import { useLocation } from 'react-router-dom';
import { usePageData } from '../../hooks/usePage';
import { IPageData } from '../../interfaces/page';
import { IPatient } from '../../interfaces/patient';
import { useNavigate } from 'react-router-dom';

import './History.scss';
import UserPreviewHeader from './ExpedienteHeaderPatient';
import { SetExpedienteQuestions, listExpPage2 } from './ExpedienteQuestions';
import { AddCardRowItem } from './IconPainEVA';
import { IExpediente, IValueQuestion } from '../../interfaces/expediente';
import OpenNotification from '../Toast';
import { useEffect, useState } from 'react';
import { SetExpedienteInfo } from '../../data/Expediente';
import PageAction from '../../layout/components/page-action/PageAction';
import { SetScrollToTop } from './ExpUtils';
import SvgBodyPain, { SvgElementToString, SvgStringToSvgArrows } from './svgBodyPain';
import { select } from 'd3';
import { HistoryRedirect, IsHistoryDisabled, ShowMapPainDraw } from '../../data/General';
// import PDFViewerTest from '../../data/TestPDF';
import { SaveExpedientePage2PDF } from './print/PrintExpPage2';

const ID_IMGSVG = 'svg_triple_sides';

const pageData: IPageData = {
    title: 'Expediente',
    fulFilled: true,
    hasBack: true,
    breadcrumbs: [
        {
            title: 'Principal',
            route: 'default-dashboard'
        },
        // {
        //     title: 'Historial',
        //     route: 'historial'
        // },
        {
            title: 'Expediente médico 3'
        }
    ]
};


const UserPreview = ({
    patient
}: { patient: IPatient }) => {
    usePageData(pageData);

    return (
        <>
            <UserPreviewHeader patient={patient} enableBtnAdd={false} />
        </>
    )
};

const AddImageSvgPain = (
    { objPain, disabled }: { objPain: any, disabled: boolean }
) => {
    return (
        <div className='row mb-4'>
            <div className='col-md-10 col-sm-12'>
                <Card title='Marca con el simbolo adecuado las areas del cuerpo con las sensaciones descritas, se puede sobreponer sensaciones en una misma area' className='mb-0 svg-pain-map' >
                    <SvgBodyPain objRefArrowsList={objPain} imgPath='content/bodies.svg' imgIdentifier={ID_IMGSVG} isEnabled={!disabled} />
                </Card>
            </div>
        </div>)
}

const AddImageScalePain = () => {
    return (
        <div className='row mb-4'>
            <div className='col-md-10 col-sm-12'>
                <Card title='Escala numerica de dolor' className='mb-0 scala-numerica-body' >
                    <img src="content/icons/img_escala_eva.svg" alt="React Image" />
                </Card>
            </div>
        </div>)
}

const AddCards = () => {
    return <>{
        listExpPage2.map((cardInfo, i) => {
            const id = "card-" + i
            return (<Card title={cardInfo.title} className='with-shadow' key={id}>
                {cardInfo.subtitle && (<h4 className='subtitle-card'>{cardInfo.subtitle}</h4>)}
                {
                    cardInfo.rowInfo.map((item, j) => {
                        const idRowCd = "card-row-" + i + "-" + j;
                        return (
                            <div key={idRowCd + '-p'}>
                                <AddCardRowItem itemGRow={item} idCard={idRowCd} />
                            </div>
                        );
                    })
                }
            </Card>)
        })
    }</>
}

var _expedienteInfo: IExpediente = null;

const ExpedienteBodyZone = () => {
    const history = useNavigate();
    const location = useLocation();
    const tempResultData = location.state || {} as any;
    const patient = tempResultData.patient;
    const _disabledEdition = IsHistoryDisabled();
    const _showMapPainDrag = ShowMapPainDraw();

    _expedienteInfo = SetExpedienteQuestions(tempResultData.expediente, patient, _disabledEdition);

    const layout = location.pathname.split('/')[1];
    const [_loading, setLoading] = useState(false)
    const [_scrollStatus, setScrollStatus] = useState(false);

    const { pathname } = useLocation();

    useEffect(() => SetScrollToTop(), [pathname])

    if (!patient || (Object.keys(patient).length === 0 && patient.constructor === Object)) {
        history(HistoryRedirect());
        return null;
    }

    const [objPainValues] = useState({});
    if (!objPainValues[ID_IMGSVG]) {
        objPainValues[ID_IMGSVG] = _expedienteInfo.svgDrawPain ? SvgStringToSvgArrows(_expedienteInfo.svgDrawPain) : [];
        objPainValues[ID_IMGSVG] = objPainValues[ID_IMGSVG].map(item => select(item))
    }

    pageData.OnScroll = (isTop: boolean) => setScrollStatus(isTop);

    const OnNextPage = (objValues: any) => {
        if (_disabledEdition) {
            history(`/${layout}/history-neurologia`, { state: { patient: patient, expediente: _expedienteInfo }, replace: true });
            return;
        }

        const resultExpPage2: IValueQuestion[] = [];
        for (let i = 0; i < listExpPage2.length; i++) {
            const item = listExpPage2[i];
            for (let x = 0; x < item.rowInfo.length; x++) {
                const subItem = item.rowInfo[x];
                for (let z = 0; z < subItem.questions.length; z++) {
                    const valItem = subItem.questions[z];
                    if (!!valItem.value) {
                        resultExpPage2.push({ id: valItem.id, value: valItem.value })
                    } else {
                        // const msg = valItem.label ? "Rellena el campo: " + valItem.label : (subItem.title ? "Rellena el campo: " + subItem.title : "Rellena todos lo campos")
                        // OpenNotification('¡Información incompleta!', msg, 'error');
                        // return;
                    }
                }
            }
        }

        if (resultExpPage2.length == 0) {
            OpenNotification('¡Llenar todos los campos!', "Llena algunas de las cajas de texto", 'warn');
            return;
        }

        _expedienteInfo.questionPage2 = resultExpPage2;

        _expedienteInfo.svgDrawPain = SvgElementToString(objValues[ID_IMGSVG]);


        setLoading(true);
        SetExpedienteInfo(_expedienteInfo, (result) => {
            setLoading(false)
            if (result > 0) {
                _expedienteInfo.id = result;
                history(`/${layout}/history-neurologia`, { state: { patient: patient, expediente: _expedienteInfo }, replace: true });
            } else {
                OpenNotification('¡Error de actualización!', "Ocurrió un error al actualizar el expediente", 'error');
            }

        });
    }

    const Print = () => {
        SaveExpedientePage2PDF([patient, listExpPage2]);
    }

    return (
        <>
            {/* <PDFViewerTest OPT='EXP_PAGE2' data={[patient, listExpPage2]} /> */}

            <UserPreview patient={patient} />
            {_showMapPainDrag && <AddImageSvgPain objPain={objPainValues} disabled={_disabledEdition} />}
            <AddImageScalePain />
            <AddCards />

            <div className='elem-list justify-content-between'>
                <Button className='bg-color-success' onClick={Print} >
                    <span className='text-color-500'>Imprimir</span>
                </Button>
                <Button type='primary' className='ml-auto' onClick={() => OnNextPage(objPainValues)} loading={_loading}>
                    Siguiente
                </Button>
            </div>

            {_scrollStatus && <PageAction onClick={SetScrollToTop} icon='icofont-simple-up' type={'primary'} key="btn-up-scroll" />}
        </>
    )
}

export default ExpedienteBodyZone;