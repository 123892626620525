import { useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { ColumnProps } from 'antd/es/table';
import { Table, Button, Modal, Badge } from 'antd';
import { IPatient } from '../../../interfaces/patient';
import PatientForm from '../../../pages/patients/PatientForm';
import { IStudent } from '../../interfaces/Student';


type Props = {
  patients: IPatient[];
  onEditPatient?: (patient: IPatient) => void;
  showOptions?: boolean
};


const PatientsStudentTable = ({
  patients,
  onEditPatient = () => null,
  showOptions = true
}: Props) => {
  const history = useNavigate();

  const [patient, setPatient] = useState(null);
  const [visibility, setVisibility] = useState(false);

  const closeModal = () => setVisibility(false);
  const handleShowInfo = (patient: IPatient) => {
    const layout = location.hash.split('/')[1];
    history(`/${layout}/patient-profile`, { state: patient });
  };
  const handleEditPatient = (patient: IPatient) => {
    setPatient(patient);
    setVisibility(true);
  };

  const actions = (patient: IPatient) => (
    <div className='buttons-list nowrap'>
      <Button shape='circle' onClick={handleShowInfo.bind({}, patient)}>
        <span className='icofont icofont-external-link' />
      </Button>
      <Button onClick={handleEditPatient.bind({}, patient)} shape='circle' type='primary'>
        <span className='icofont icofont-edit-alt' />
      </Button>
    </div>
  );

  const columns: ColumnProps<IPatient>[] = [
    {
      key: 'name',
      dataIndex: 'name',
      title: 'Nombre',
      sorter: (a, b) => (a.name > b.name ? 1 : -1),
      render: (name, item) => {
        return (<><Badge color={item.gender == 1 ? "hwb(205 6% 9%)" : 'pink'} text=" " /><strong>{name + " " + (item.lastname ?? "")}</strong></>)
      }
    },
    {
      key: 'email',
      dataIndex: 'email',
      title: 'Correo electrónico',
      sorter: (a, b) => (a.email > b.email ? 1 : -1),
      render: (email) => <strong>{email}</strong>
    },
    {
      key: 'phone',
      dataIndex: 'phone',
      title: 'Telefono',
      render: (phone) => (
        <span className='d-flex align-baseline nowrap' style={{ color: '#336cfb' }}>
          <span className='icofont icofont-ui-cell-phone mr-1' style={{ fontSize: 16 }} />
          {phone}
        </span>
      )
    },
    {
      key: 'birthDate',
      dataIndex: 'birthDate',
      title: 'Fecha de nacimiento',
      render: (visit) => (
        <span className='nowrap' style={{ color: '#a5a5a5' }}>
          {visit}
        </span>
      )
    },
    {
      key: 'user',
      dataIndex: '_user',
      title: 'Paciente del alumno',
      render: (user: IStudent) => (
        <span className='d-flex align-baseline nowrap' style={{ color: '#336cfb' }}>
          {user?.name}
        </span>
      )
    },

  ];

  if (showOptions) {
    columns.push({
      key: 'actions',
      title: 'Opciones',
      render: actions
    });
  }

  const pagination = patients.length <= 10 ? false : {};

  return (
    <>
      <Table
        pagination={pagination}
        className='accent-header'
        rowKey='id'
        dataSource={patients}
        columns={columns} />

      <Modal
        open={visibility}
        footer={null}
        onCancel={closeModal}
        destroyOnClose={true}
        title={<h3 className='title'>Actualizar paciente</h3>} >
        <PatientForm
          submitText='Actualizar'
          onCancel={closeModal}
          onSubmit={onEditPatient}
          patient={patient}
        />
      </Modal>
    </>
  );
};

export default PatientsStudentTable;
