import { USER_INFO } from '../data/General';
import { IRoute } from '../interfaces/routing';
import { ETypeUser } from '../interfaces/user';
import ExpedienteBodyZone from '../pages/history/ExpedienteBody';
// import ExpedienteList from '../pages/history/ExpedienteList/ExpedienteList';
import ExpedientePage from '../pages/history/ExpedienteNew';
import PostExpedNeurologia from '../pages/history/PostExpedNeurologia';
import NotFound from '../pages/sessions/404';
import InternalError from '../pages/sessions/500';
import UserProfilePage from '../pages/users/UserProfilePage';
import DashboardDisc from './ui/DashboardDisc';
import DiscSignIn from './ui/Sign-in';
import FormularioDetail from './ui/formulario/FormularioDetail';
import FormularioPage from './ui/formulario/FormularioPage';
// import HistoryPage from './ui/history/History-page';
import AddPainPatient from './ui/pain-patient/map-pain';
import PatientProfileStud from './ui/patients/PatientProfileStud';
import PatientsStudent from './ui/patients/PatientsPage';
import EditAccountStudent from './ui/students/EditAccountStudent';
import StudentsPage from './ui/students/Students';

const webDiscapacidadRoutes: IRoute[] = [
    {
        path: 'page-404',
        component: NotFound
    },
    {
        path: 'page-500',
        component: InternalError
    },
    {
        path: 'home',
        component: DashboardDisc
    },
    {
        path: 'students',
        component: StudentsPage
    },
    {
        path: 'edit-student',
        component: EditAccountStudent
    },
    {
        path: 'user-profile',
        component: UserProfilePage
    },
    {
        path: 'login',
        component: DiscSignIn
    },
    {
        path: 'patients',
        component: PatientsStudent
    }, {
        path: 'formularios',
        component: FormularioPage
    },
    {
        path: 'formulario-detail',
        component: FormularioDetail
    },
    {
        path: 'patient-profile',
        component: PatientProfileStud
    },
    {
        path: 'user-profile',
        component: UserProfilePage
    },
    // {
    //     path: 'expedientes',
    //     component: HistoryPage
    // },
    // {
    //     path: 'history-expedientelist',
    //     component: ExpedienteList
    // },
    {
        path: 'history-expedientepage',
        component: ExpedientePage
    },
    {
        path: 'history-bodyzone',
        component: ExpedienteBodyZone
    },
    {
        path: 'history-neurologia',
        component: PostExpedNeurologia
    },
];

const webDiscapacidadRoutesStudent: IRoute[] = [
    {
        path: 'page-404',
        component: NotFound
    },
    {
        path: 'page-500',
        component: InternalError
    },
    {
        path: 'login',
        component: DiscSignIn
    },
    {
        path: 'home',
        component: AddPainPatient
    },
    {
        path: 'patients',
        component: PatientsStudent
    }, {
        path: 'formularios',
        component: FormularioPage
    },
    {
        path: 'formulario-detail',
        component: FormularioDetail
    },
    {
        path: 'patient-profile',
        component: PatientProfileStud
    },
    {
        path: 'user-profile',
        component: UserProfilePage
    },
    // {
    //     path: 'expedientes',
    //     component: HistoryPage
    // },
    // {
    //     path: 'history-expedientelist',
    //     component: ExpedienteList
    // },
    {
        path: 'history-expedientepage',
        component: ExpedientePage
    },
    {
        path: 'history-bodyzone',
        component: ExpedienteBodyZone
    },
    {
        path: 'history-neurologia',
        component: PostExpedNeurologia
    },
];


export function GetRoutesDiscapacidad() {
    if (USER_INFO.typeUser == ETypeUser.ADMIN || USER_INFO.typeUser == ETypeUser.ROOT)
        return webDiscapacidadRoutes;
    else
        return webDiscapacidadRoutesStudent;
}