

import { Button, Table } from 'antd';
import { ColumnProps, TableProps } from 'antd/es/table';

import { IAppointment, mapPainType } from '../../interfaces/patient';
import { useNavigate } from 'react-router-dom';

const columns: ColumnProps<IAppointment>[] = [
  {
    key: 'id',
    title: ' #',
    className: 'text-align-rigth',
    render: (_, __, i) => <span className='text-right text-color-200'>{(i + 1)}</span>
  },
  {
    key: 'forDate',
    title: 'Fecha de la cita',
    className: 'nowrap',
    sorter: (a, b) => a.forDate.localeCompare(b.forDate),
    render: (rowPatient) => <span className='text-align-left text-color-200'>{rowPatient.forDate}</span>
  },
  {
    key: 'painType',
    title: 'Tipo de dolor',
    sorter: (a, b) => a.painType - b.painType,
    render: (rowPatient) => <span className='text-left'>{mapPainType.get(rowPatient.painType)}</span>
  },
  {
    key: 'description',
    title: 'Descripción',
    sorter: (a, b) => a.description.localeCompare(b.description),
    render: (rowPatient) => <span className='text-left'>{rowPatient.description}</span>
  },
  {
    key: 'createdAt',
    title: 'Fecha de creación',
    sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
    render: (rowPatient) => <span className='text-align-left'>{rowPatient.createdAt}</span>
  },
];

type Props = {
  appointments: IAppointment[];
  pagination?: TableProps<any>['pagination'];
  type?: 'accent' | 'none';
};

const tableClasses = {
  accent: 'accent-header',
  none: ''
};

const AppointmentByPatientTable = ({ appointments, pagination = false, type = 'none' }: Props) => {

  const history = useNavigate();

  const handleSelectAppointment = (appointment: IAppointment) => {
    history("/vertical/apointment-detail", { state: appointment });
  }

  const actions = (appointment: IAppointment) => (
    <div className='buttons-list nowrap'>
      <Button onClick={handleSelectAppointment.bind({}, appointment)} shape='circle' type='primary'>
        <span className='icofont icofont-external-link' />
      </Button>
    </div>
  );

  if (columns.findIndex(o => o.key == 'actions') == -1) {
    columns.push({
      key: 'actions',
      title: '⚙️',
      render: actions
    });
  }

  const tableClass = tableClasses[type];

  return (
    <Table
      className={tableClass}
      pagination={pagination}
      rowKey='id'
      dataSource={appointments}
      columns={columns}
    />
  );
};

export default AppointmentByPatientTable;
