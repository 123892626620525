import React, { ChangeEvent, useState } from 'react';

import { useFormik } from 'formik';
import { Button, DatePicker, DatePickerProps, Divider, Form, FormInstance, Input, Select } from 'antd';

import ImageLoader from '../../layout/components/patients/ImageLoader';

import { usePageData } from '../../hooks/usePage';

import { IPageData } from '../../interfaces/page';
import { USER_INFO, SetProfileImage } from '../../data/General';
import moment from 'moment';
import { ETypeUser, IUser } from '../../interfaces/user';
import { useNavigate, useLocation } from 'react-router-dom';
import { ChangeUserPassword, RemoveUserData, SetLocalUserData, UpdateAndSaveUser } from '../../data/User';
import * as Yup from 'yup';
import { hasErrorFactory } from '../../utils/hasError';
import openNotification from '../Toast';

const pageData: IPageData = {
  title: 'Editar cuenta',
  fulFilled: true,
  hasBack: true,
  breadcrumbs: [
    {
      title: 'Principal',
      route: 'default-dashboard'
    },
    {
      title: 'Usuarios',
      route: 'users'
    },
    {
      title: 'Editar cuenta'
    }
  ]
};

const FormItem = Form.Item;
const Option = Select.Option;

const UserAvatar = ({ src, className = null }) => {
  return (
    <div className={`avatar-wrapper ${className}`}>
      <ImageLoader src={src} size={100} />
    </div>
  );
};

const userSchema = Yup.object().shape({
  birthDate: Yup.string().required(),
  email: Yup.string().required(),
  gender: Yup.number().required(),
  name: Yup.string().required(),
  phone: Yup.string().required()
});

const AccountForm = ({ user }) => {
  const [submitted, setSubmitted] = useState({ ...user });
  const {
    values
    , setValues
    , handleBlur
    , errors
    , touched
    , isValid
  } = useFormik<IUser>({
    validationSchema: userSchema,
    onSubmit: (values) => setSubmitted(values),
    initialValues: { ...user }
  });

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const onChangeDate: DatePickerProps['onChange'] = (_, dateString) => {
    values.birthDate = dateString;
  };


  const handleSelectChange = (name) => (value) => {
    setValues({ ...values, [name]: value });
  };

  const hasChanged = Object.keys(values).some((key) => values[key] !== submitted[key]);
  const dateFormat = 'YYYY-MM-DD';
  const dateNow = moment().format(dateFormat);

  const history = useNavigate();

  //Update and Create user
  const handleSubmit = () => {
    if (values.gender !== 1 && values.gender !== 0) {
      openNotification('¡Error!', "Selecciona el genero del usuario", 'error');
      return;
    }

    let toUpdateUser = values.id && values.id > 0;

    UpdateAndSaveUser(values, toUpdateUser, (result) => {
      if (result > 0) {
        openNotification(toUpdateUser ? '¡Actualizado!' : '¡Agregado!', "Se " + toUpdateUser ? "actualizó" : "agregó" + " el usuario");

        if (!toUpdateUser) {
          values.id = result;
          history(-1);
        } else {
          if (values.id === USER_INFO.id) {//Actualizar usuario logueado
            SetLocalUserData(values);
          }
        }
      } else {
        openNotification('¡Error!', "Ocurrió un error al guardar", 'error');
      }
    });
  }

  //Remove user..
  const handlerRemoveUser = () => {
    RemoveUserData(values.id, (result) => {
      if (result > 0) {
        openNotification('¡Eliminado!', "Se eliminó el usuario");
        history(-1);
      } else {
        openNotification('¡Error!', "Ocurrió un error al eliminar", 'error');
      }
    })
  }

  const hasError = hasErrorFactory(touched, errors);
  let canDelete = (values.id && values.id > 0) && values.id != USER_INFO.id;

  return (
    <Form layout='vertical'>
      <FormItem label='Nombre'>
        <Input
          name='name'
          onBlur={handleBlur}
          onChange={handleChange}
          placeholder='Nombre'
          required
          defaultValue={values.name}
          className={hasError('name')} />
      </FormItem>

      <FormItem label='Apellidos'>
        <Input
          name='lastname'
          onChange={handleChange}
          defaultValue={values.lastname}
          placeholder='Apellidos'
        />
      </FormItem>

      <FormItem label='Correo electrónico'>
        <Input
          name='email'
          onChange={handleChange}
          defaultValue={values.email}
          placeholder='Correo electrónico'
          onBlur={handleBlur}
          className={hasError('email')}
          required />
      </FormItem>

      <div className='row'>
        <div className='col-md-6 col-sm-12'>
          <FormItem label='Fecha de nacimiento'>
            <DatePicker
              aria-required
              name='birthDate'
              className={'ant-input ' + hasError('birthDate')}
              placeholder='Fecha de nacimiento'
              onChange={onChangeDate}
              defaultValue={values.birthDate ? moment(values.birthDate, dateFormat) : null as any}
              format={dateFormat}
              onBlur={handleBlur}
              disabledDate={d => !d || d.isAfter(dateNow)} />
          </FormItem>
        </div>
        <div className='col-md-6 col-sm-12'>
          <FormItem label='Genero'>
            <Select
              onChange={handleSelectChange('gender')}
              defaultValue={values.gender === 1 ? 'Masculino' : (values.gender === 0 ? 'Femenino' : null)}
              placeholder='Genero'
              dropdownClassName='gender'
              onBlur={handleBlur}
              className={hasError('gender')}
              aria-required>
              <Option value={1}>Masculino</Option>
              <Option value={0}>Femenino</Option>
            </Select>
          </FormItem>
        </div>
      </div>

      <FormItem label='Teléfono'>
        <Input
          type='number'
          name='phone'
          onChange={handleChange}
          defaultValue={values.phone}
          placeholder='Teléfono'
          onBlur={handleBlur}
          className={hasError('phone')}
          required />
      </FormItem>

      <FormItem label='Dirección'>
        <Input.TextArea name="address" rows={4} defaultValue={values.address} onChange={handleChange} placeholder='Dirección' />
      </FormItem>

      <div className='elem-list justify-content-between'>
        <Button disabled={!isValid || !hasChanged} className='bg-color-success' onClick={handleSubmit}>
          <span className='text-color-500'>Guardar Cambios</span>
        </Button>

        {canDelete &&
          (<Button ghost danger className='ml-auto' onClick={handlerRemoveUser}>
            Eliminar cuenta
          </Button>)
        }
      </div>
    </Form >
  );
};

const emptyPass = {
  id: 0,
  password: "",
  newPassword: "",
  confirmPassword: ""
};

interface iPass {
  id: number;
  password: string;
  newPassword: string;
  confirmPassword: string;
}




const PasswordForm = ({ user }: { user: IUser }) => {
  const formRef = React.useRef() as React.MutableRefObject<FormInstance>;

  const handleSubmit = () => {
    let values: iPass = formRef.current.getFieldsValue();

    if (values.newPassword !== values.confirmPassword) {
      openNotification('¡Contraseña!', "las contraseñas no coinciden", 'warn');
      return;
    }

    if (values.newPassword === "" || values.confirmPassword === "") {
      openNotification('¡Contraseña!', "Contraseña no valida", 'warn');
      return;
    }

    ChangeUserPassword(user.id, values.newPassword, values.password, (result) => {
      if (result > 0) {
        openNotification('¡Contraseña!', "Se actualizó la contraseña");
        values.password = ""
      } else if (result == -3) {
        openNotification('¡Contraseña!', "Contraseña no coincide con el anterior", 'warn');
      } else {
        openNotification('¡Contraseña!', "Ocurrió un error al actualizar la contraseña", 'error');
      }

      formRef.current.resetFields();
    });
  }

  return (
    <Form layout='vertical' ref={formRef}>
      <FormItem
        label='Contraseña actual'
        name='password'
        initialValue={emptyPass.password}>
        <Input.Password
          name='password'
          placeholder='Contraseña actual' />
      </FormItem>

      <div className='row'>
        <div className='col-md-6 col-sm-12'>
          <FormItem
            name='newPassword'
            label='Nueva contraseña'
            initialValue={emptyPass.newPassword}
            rules={[{ required: true, message: 'ingresa la nueva contraseña' }]} >
            <Input.Password placeholder='Nueva contraseña' name='newPassword' />
          </FormItem>
        </div>

        <div className='col-md-6 col-sm-12'>
          <FormItem
            name='confirmPassword'
            label='Confirmar contraseña'
            initialValue={emptyPass.confirmPassword}
            rules={[
              {
                required: true,
                message: 'Confirma tu contraseña!'
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('newPassword') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject('Las contraseñas no coinciden!');
                }
              })
            ]} >
            <Input.Password name='confirmPassword' placeholder='Confirmar contraseña' />
          </FormItem>
        </div>
      </div>

      <Button type='primary' onClick={handleSubmit}>Guardar cambios</Button>
    </Form>
  );
};

const userDefault = {
  address: null,
  birthDate: null,
  description: null,
  email: null,
  gender: -1,
  inUse: true,
  lastname: null,
  name: null,
  phone: null,
  typeUser: ETypeUser.ADMIN
} as IUser;

const EditAccountPage = () => {
  const location = useLocation();
  let user = location.state as IUser;

  usePageData(pageData);

  if (!user) user = userDefault;


  let showPassForm = !!user.id;

  return (
    <div className='stack' style={{ maxWidth: 690, margin: '0 auto' }}>
      <UserAvatar className='mt-0' src={SetProfileImage(user.img as string)} />
      <AccountForm user={user} />

      <Divider />

      {showPassForm && (<PasswordForm user={user} />)}
    </div>
  );
};

export default EditAccountPage;
