
import { Route, Routes, Navigate } from 'react-router-dom';

import ConfigProvider from 'antd/es/config-provider';

import NotFound from '../pages/sessions/404';
import '../App.scss';
import { publicWebRoutes } from './public-web-routes';

const LayoutRoutes = ({ routes }) => {
    return (<Routes>
        {routes.map((route, index) => (
            <Route key={index} path={`/${route.path}`} element={<route.component />} />
        ))}

        <Route path='*' element={<Navigate replace to='/public/page-404' />} />
    </Routes>);
}

const DefaultRoutes = () => <LayoutRoutes routes={publicWebRoutes} />;

const App_pubMedular = () => {
    return (
        <ConfigProvider
            theme={{
                token: { fontFamily: "'Lato', sans-serif" },
            }}
        >
            <Routes>

                <Route path='/' element={<Navigate replace={true} to='/public/home' />} />
                {/* <Route path='/public/*' element={<DefaultRoutes />} /> */}
                {/* <Route path='/public/*' element={<PublicLayout transparent ><DefaultRoutes /></PublicLayout>} /> */}
                <Route path='/public/*' element={<DefaultRoutes />} />


                <Route path='*' element={<NotFound />} />

            </Routes>
        </ConfigProvider>
    );
};

export default App_pubMedular;
