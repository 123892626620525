import { useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { ColumnProps } from 'antd/es/table';
import { Table, Button, Modal } from 'antd';

import { IPatient } from '../../interfaces/patient';

import PatientForm from './PatientForm';

type Props = {
  patients: IPatient[];
  onEditPatient?: (patient: IPatient) => void;
  onAppointmentDetail?: (patient: IPatient) => void;
  showOptions?: boolean
};


const PatientsTable = ({
  patients,
  onEditPatient = () => null,
  onAppointmentDetail = () => null,
  showOptions = true
}: Props) => {
  const history = useNavigate();

  const [patient, setPatient] = useState(null);
  const [visibility, setVisibility] = useState(false);

  const closeModal = () => setVisibility(false);
  const handleShowInfo = (patient: IPatient) => {
    const layout = location.hash.split('/')[1];
    console.log(location.hash)
    history(`/${layout}/patient-profile`, { state: patient });
  };
  const handleDeletePatient = (patient) => onAppointmentDetail(patient);
  const handleEditPatient = (patient: IPatient) => {
    setPatient(patient);
    setVisibility(true);
  };

  const actions = (patient: IPatient) => (
    <div className='buttons-list nowrap'>
      <Button shape='circle' onClick={handleShowInfo.bind({}, patient)}>
        <span className='icofont icofont-external-link' />
      </Button>
      <Button onClick={handleEditPatient.bind({}, patient)} shape='circle' type='primary'>
        <span className='icofont icofont-edit-alt' />
      </Button>
      <Button onClick={handleDeletePatient.bind({}, patient)} shape='circle' danger>
        <span className='icofont icofont-heartbeat' />
      </Button>
    </div>
  );

  const columns: ColumnProps<IPatient>[] = [
    {
      key: 'name',
      dataIndex: 'name',
      title: 'Nombre',
      sorter: (a, b) => (a.name > b.name ? 1 : -1),
      render: (name, item) => <strong>{name + " " + (item.lastname ? item.lastname : "")}</strong>
    },
    {
      key: 'email',
      dataIndex: 'email',
      title: 'Correo electrónico',
      sorter: (a, b) => (a.email > b.email ? 1 : -1),
      render: (email) => <strong>{email}</strong>
    },
    {
      key: 'gender',
      dataIndex: 'gender',
      title: 'Genero',
      render: (id) => (
        <span className='nowrap' style={{ color: '#a5a5a5' }}>
          {id === 1 ? 'Masculino' : (id === 0 ? 'Femenino' : null)}
        </span>
      )
    },
    {
      key: 'phone',
      dataIndex: 'phone',
      title: 'Telefono',
      render: (phone) => (
        <span className='d-flex align-baseline nowrap' style={{ color: '#336cfb' }}>
          <span className='icofont icofont-ui-cell-phone mr-1' style={{ fontSize: 16 }} />
          {phone}
        </span>
      )
    },
    {
      key: 'birthDate',
      dataIndex: 'birthDate',
      title: 'Fecha de nacimiento',
      render: (visit) => (
        <span className='nowrap' style={{ color: '#a5a5a5' }}>
          {visit}
        </span>
      )
    },
    {
      key: 'age',
      dataIndex: 'age',
      title: 'Edad',
      sorter: (a, b) => a.age - b.age,
      render: (age) => (
        <span className='nowrap' style={{ color: '#a5a5a5' }}>
          {age}
        </span>
      )
    },
  ];

  if (showOptions) {
    columns.push({
      key: 'actions',
      title: 'Opciones',
      render: actions
    });
  }

  const pagination = patients.length <= 10 ? false : {};

  return (
    <>
      <Table
        pagination={pagination}
        className='accent-header'
        rowKey='id'
        dataSource={patients}
        columns={columns} />

      <Modal
        open={visibility}
        footer={null}
        onCancel={closeModal}
        destroyOnClose={true}
        title={<h3 className='title'>Actualizar paciente</h3>} >
        <PatientForm
          submitText='Actualizar'
          onCancel={closeModal}
          onSubmit={onEditPatient}
          patient={patient}
        />
      </Modal>
    </>
  );
};

export default PatientsTable;
