
import { Route, Routes, Navigate } from 'react-router-dom';

import ConfigProvider from 'antd/es/config-provider';
import VerticalLayout from './layout/vertical/Vertical';
import HorizontalLayout from './layout/horizontal/Horizontal';

import NotFound from './pages/sessions/404';
import { defaultRoutes, sessionRoutes } from './routing';
import './App.scss';

import SignIn from './pages/sessions/Sign-in';
import { SetUserInfo, USER_INFO, _DATABASE_NAME } from './data/General';

const LayoutRoutes = ({ routes }) => {

  return (
    <Routes>
      {routes.map((route, index) => (
        // <Route key={index} path={layout.length > 0 ? `/${layout}/${route.path}` : `/${route.path}`} element={<route.component />}/>
        <Route key={index} path={`/${route.path}`} element={<route.component />} />
      ))}

      <Route path='*' element={<Navigate replace to='/public/page-404' />} />
    </Routes>
  );
}

const DefaultRoutes = () => <LayoutRoutes routes={defaultRoutes} />;

const SessionRoutes = () => <LayoutRoutes routes={sessionRoutes} />;

const App = () => {

  if (!USER_INFO) {
    let strUser = localStorage.getItem("userInfo");
    if (strUser) SetUserInfo(JSON.parse(strUser));
  }

  if (!USER_INFO) {//Validar inicio de sesión
    return (
      <SignIn />
    );
  }

  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: "'Lato', sans-serif",
        },
      }}
    >
      <Routes>

        <Route path='/' element={<Navigate replace={true} to='/vertical/default-dashboard' />} />

        <Route path='/public/*' element={<SessionRoutes />} />
        <Route path='/horizontal/*' element={<HorizontalLayout><DefaultRoutes /></HorizontalLayout>} />
        <Route path='/vertical/*' element={<VerticalLayout><DefaultRoutes /></VerticalLayout>} />
        <Route path='*' element={<NotFound />} />

      </Routes>
    </ConfigProvider>
  );
};

export default App;
