import { useState } from "react";
import { LoadPatients } from "../../data/Patient";
import { usePageData } from "../../hooks/usePage";
import { IPageData } from "../../interfaces/page";
import { IPatient } from "../../interfaces/patient";
import HistoryTable from "./HistoryTable";

const pageData: IPageData = {
    title: 'Historial médico',
    fulFilled: false,
    breadcrumbs: [
        {
            title: 'Principal',
            route: 'default-dashboard'
        },
        {
            title: 'Expediente'
        }
    ]
};


const MedicalHistory = () => {
    usePageData(pageData);

    const [patients] = LoadPatients();
    const [searchValue, setSearchValue] = useState('');


    var filterList: IPatient[];
    if (searchValue === "") filterList = patients;
    else filterList = patients.filter(o => o.name.toLowerCase().includes(searchValue.toLowerCase()) || (o.lastname && o.lastname.toLowerCase().includes(searchValue.toLowerCase())));

    pageData.OnChangeSearch = (res) => {
        setSearchValue(res);
    }

    return (
        <>
            <HistoryTable
                patients={filterList}
                OnSelectRow={null}
            />
        </>
    );
}

export default MedicalHistory;