import jsPDF from "jspdf";
import { IAppointment } from "../interfaces/patient";
import { IPdfText } from "./Entities";

const _margin = 25;
var _height = 450;
var _width = 380;
var _widthArea = _width;
var _posInY = _margin;
const _fontSize = 12;
const _lineSpacing = 8;
const _logoWidth = 250;
const _logoHeight = 36;
const _colorFont = "#000";
const _colorSecundaryFont = "#696969";


export const InitPDF = async (appointment: IAppointment) => {
    let pdf = await fnPDFCreate(appointment)
    // let uri = pdf.output("datauristring")
    let uri = pdf.output('blob')

    // pdf.save("pdf_test");
    // pdfViewerUpdate(uri);

    return uri;
}

export const SavePDF = async (appointment: IAppointment) => {
    let pdf = await fnPDFCreate(appointment);
    pdf.save("Cita_" + appointment.forDate);
}

async function fnPDFCreate(appointment: IAppointment) {
    let patient = appointment._patient;

    const METADATA = {
        title: 'Paciente: ' + patient.name,
        subject: 'Informacion de cita',
        author: 'Tino 710',
        keywords: 'Dr. pedro reyes, cita de paciente',
        creator: 'Dr. Admin'
    };

    const pdf = new jsPDF({
        orientation: "portrait",
        unit: "px",
        format: "letter",
    });

    _width = pdf.internal.pageSize.getWidth();
    _height = pdf.internal.pageSize.getHeight();

    _widthArea = (_width - _margin * 2)
    _posInY = _margin;

    const infoPatient: Array<IPdfText> = [
        { label: "Información del paciente", value: null, posX: 0 },
        { label: "Nombre completo: ", value: patient.name, posX: _margin },
        { label: "Correo eléctronico: ", value: patient.email, posX: _margin },
        { label: "Teléfono: ", value: patient.phone, posX: _margin },
        { label: "Edad: ", value: "" + patient.age, posX: _margin, val2: { label: "Genero: ", value: patient.gender == 1 ? "Masculino" : "Femenino", posX: _width / 2 } },
        { label: null, value: null, posX: 0 },
        { label: "Información de la cita", value: null, posX: 0 },
        {
            label: "Fecha: ", value: appointment.createdAt, posX: _margin, val2: {
                label: "Tipo de dolor: ", value: appointment.painType == 1 ? 'Espalda' : (appointment.painType == 2 ? 'Cuello' : 'Ambos'), posX: _width / 2
            }
        },
        { label: "Descripción: ", value: appointment.description, posX: _margin },
        { label: "Mapa de dolor de frente: ", value: null, posX: _margin, val2: { label: "Mapa de dolor de espalda: ", value: null, posX: _width / 2 } },
    ];


    // >> METADATA
    pdf.setProperties(METADATA);
    pdf.setFontSize(_fontSize)

    pdf.addImage("/logo_main.png", 'PNG', (_width - _logoWidth) / 2, _posInY, _logoWidth, _logoHeight);
    _posInY += _logoHeight + 10;

    // >> MARGIN LINES
    pdf.setDrawColor("#E0E0E0");
    pdf.line(_margin, _posInY, _width - _margin, _posInY);

    _posInY += 30;

    // >> Info patient
    infoPatient.forEach(item => {
        if (item.label)
            AddTextAndValuePDF(pdf, item.label, item.value, item.posX, _posInY, _widthArea);

        if (item.val2)
            AddTextAndValuePDF(pdf, item.val2.label, item.val2.value, item.val2.posX, _posInY, _widthArea);

        _posInY += _fontSize + _lineSpacing;
    });

    let imgPainSize = 180;
    let elementNeck: Element;
    if (appointment.svgPainMap1) {
        const imgSvgElement = GetSVGImageEelement(appointment.svgPainMap1);
        elementNeck = imgSvgElement.getElementById("assets_img_body_back_svg");

        await AddImageToPDF(pdf, imgSvgElement, { width: imgPainSize, height: imgPainSize, x: 0, y: _posInY })
    }

    if (appointment.svgPainMap2) {
        const imgSvgElement = GetSVGImageEelement(appointment.svgPainMap2);
        if (elementNeck) imgSvgElement.append(elementNeck);

        await AddImageToPDF(pdf, imgSvgElement, { width: imgPainSize, height: imgPainSize, x: _width / 2, y: _posInY })
    }


    _posInY += 200;//Image height

    // >> Cuestions.....
    AddNewPage(pdf);
    pdf.setFontSize(9);

    const questionBack: Array<any> = appointment.questionBack ? JSON.parse(appointment.questionBack) : [];
    const questionNeck: Array<any> = appointment.questionNeck ? JSON.parse(appointment.questionNeck) : [];

    let middWidthArea = _widthArea / 2;
    if (questionBack.length > 0) {
        pdf.setFont(undefined, 'bold');
        AddTextAndValuePDF(pdf, "Cuestionario dolor de espalda", null, 0, _posInY, middWidthArea);
        _posInY += _fontSize * 2 + _lineSpacing;

        pdf.setFont(undefined, 'normal');
        for (let i = 0; i < questionBack.length; i++) {
            let item = questionBack[i];
            AddTextQuestionPDF(pdf, item.q, item.v, _margin);
        }
    }

    //Agregar cuestionario en la otra columna
    _posInY = _margin;
    if (questionNeck.length > 0) {
        let posX = questionBack.length > 0 ? middWidthArea : 0;

        pdf.setFont(undefined, 'bold');
        AddTextAndValuePDF(pdf, "Cuestionario dolor de cuello", null, posX, _posInY, middWidthArea);
        _posInY += _fontSize * 2 + _lineSpacing;

        pdf.setFont(undefined, 'normal');
        for (let i = 0; i < questionNeck.length; i++) {
            let item = questionNeck[i];
            AddTextQuestionPDF(pdf, item.q, item.v, posX + _margin);
        }
    }

    pdf.setFontSize(_fontSize)

    return pdf;
}

function AddTextAndValuePDF(pdf: jsPDF, label: string, value: string, margin: number, posY: number, width: number) {
    margin += _margin;
    pdf.setTextColor(_colorFont);
    pdf.text(label, margin, posY, { maxWidth: width });

    if (value) {
        pdf.setTextColor(_colorSecundaryFont);
        pdf.text(value, margin + pdf.getTextWidth(label), posY, { maxWidth: width })
    }

    if (_posInY >= _height - margin) {
        AddNewPage(pdf);  // Restart height position
    }
}

function AddTextQuestionPDF(pdf: jsPDF, question: string, response: string, posX: number) {
    let middlePage = _widthArea / 2;

    pdf.setTextColor(_colorFont);
    pdf.text(question, posX, _posInY, { maxWidth: middlePage });

    _posInY += _fontSize + _lineSpacing * 0.25;
    pdf.setTextColor(_colorSecundaryFont);
    pdf.text(response, posX, _posInY, { maxWidth: middlePage })

    _posInY += _fontSize + _lineSpacing;

    if (_posInY >= _height - _margin) {
        AddNewPage(pdf);
    }
}

async function AddImageToPDF(pdf: jsPDF, iconSvg: Element, output: { width: number, height: number, x: number, y: number }) {
    let { width, height } = { width: 1080, height: 1080 }

    let outerHTML = iconSvg.outerHTML;
    // const uriData = 'data:image/svg+xml;base64,' + window.btoa(outerHTML);
    let blob = new Blob([outerHTML], { type: 'image/svg+xml;charset=utf-8' });
    let URL = window.URL || window.webkitURL;
    let blobURL = URL.createObjectURL(blob);

    return new Promise((resolve, reject) => {
        let image = new Image();
        image.onload = function () {
            const canvas = document.createElement("canvas");
            [canvas.width, canvas.height] = [width, height];

            const ctx = canvas.getContext("2d");
            ctx.drawImage(image, 0, 0, width, height);

            const svgData = canvas.toDataURL();

            pdf.addImage(svgData, 'PNG', output.x, output.y, output.width, output.height);

            resolve(svgData);
        };

        image.onerror = (ev) => {
            console.log(ev);
            reject()
        }

        image.src = blobURL;
        // image.src = uriData;
    });
}

function GetSVGImageEelement(svgInner: string) {
    const iconSvg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    iconSvg.setAttribute('xmlns', "http://www.w3.org/2000/svg")
    iconSvg.setAttribute('xmlns:xlink', "http://www.w3.org/1999/xlink")
    iconSvg.setAttribute('fill', '#FFF');
    iconSvg.setAttribute('viewBox', '0 0 400 400');
    iconSvg.setAttribute('width', '400');
    iconSvg.setAttribute('height', '400');
    iconSvg.setAttribute('preserveAspectRatio', 'xMidYMid meet');

    //Quitar classes
    svgInner = svgInner.replaceAll(`class="line-pain"`, `stroke-width="5px" stroke-linecap="round" cursor="pointer"`)

    iconSvg.innerHTML = svgInner;

    return iconSvg;
}

function AddNewPage(pdf: jsPDF) {
    pdf.addPage();
    _posInY = _margin;  // Restart height position
}
