import { IPageData } from './page';
import { IAppSettings } from './settings';
import { IPatient } from './patient';

export interface IAppState {
  pageData: IPageData;
  settings: IAppSettings,
  patients: IPatient[]
}

export enum APP_IDENTIFIER {
  DR_ADMIN = 1, // DEVELOPMENT = 1,
  APP_MEDULAR = 2,
  APP_DISCAPACIDAD = 3
}