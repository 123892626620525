import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { _API } from "../../data/General";

export function useFetchRequestData<T>(
    url: string,
    initialState: T = null,
    callback?: (res: T) => any
): [T, (data: T) => void] {
    const [data, setData] = useState<T>(initialState);
    const dispatch = useDispatch();

    useEffect(() => {
        async function getData() {
            const result = await axios.get(url);
            return result.data;
        }

        getData()
            .then((resData) => {
                if (callback) {
                    callback(resData);
                }

                setData(resData);
            })
            .catch(console.error);
        // }, [url, dispatch, callback]);
    }, [url, dispatch]);

    return [data, setData];
}