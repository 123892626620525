import { EQuestion } from "./IconPainEVA";

export function SetScrollToTop() {
    const dvMain = document.getElementsByClassName("app-container").item(0);
    if (!dvMain) return;
    const elmScrl = dvMain.getElementsByClassName("main-content").item(0);
    elmScrl.scrollTo(0, 0);
}

const VAL_IMC_DESCRIPTION = [
    "Delgadez severa",
    "Delgadez moderada",
    "Delgadez aceptable",
    "Peso normal",
    "Sobrepeso",
    "Obesidad tipo I",
    "Obesidad tipo II",
    "Obesidad tipo III(obesidad mórbida)",
    "Obesidad tipo IV o extrema"
]

export function IMCDescription(val: any): string {
    const nVal = +val;
    if (nVal < 16) return VAL_IMC_DESCRIPTION[0];
    if (nVal >= 16 && nVal < 17) return VAL_IMC_DESCRIPTION[1];
    if (nVal >= 17 && nVal < 18.5) return VAL_IMC_DESCRIPTION[2];
    if (nVal >= 18.5 && nVal < 25) return VAL_IMC_DESCRIPTION[3];
    if (nVal >= 25 && nVal < 30) return VAL_IMC_DESCRIPTION[4];
    if (nVal >= 30 && nVal < 35) return VAL_IMC_DESCRIPTION[5];
    if (nVal >= 35 && nVal < 40) return VAL_IMC_DESCRIPTION[6];
    if (nVal >= 40 && nVal < 50) return VAL_IMC_DESCRIPTION[7];
    if (nVal >= 50) return VAL_IMC_DESCRIPTION[8];

}

export function GetIMC(_peso: EQuestion, _talla: EQuestion): string {
    const imc = (+_peso.value) / (Math.pow((+_talla.value / 100), 2));
    return "" + (Math.round(imc));
}

export function GetIMCDescription(resImcs: any): string {
    return "► " + IMCDescription(resImcs);
}