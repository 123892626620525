import { useState } from 'react';
import { IPageData } from '../../../interfaces/page';
import { usePageData } from '../../../hooks/usePage';
import { LoadPatients, UpdateAndSavePatient } from '../../../data/Patient';
import { IPatient } from '../../../interfaces/patient';
import OpenNotification from '../../../pages/Toast';
import { LoadAppointmentsV2 } from '../../../data/Appointment';
import PatientsStudentTable from './PatientsStudentTable';
import { GetStudents } from '../../Model/Student';
import { useLocation } from 'react-router';
import { IStudent } from '../../interfaces/Student';

const pageData: IPageData = {
  title: 'Pacientes',
  fulFilled: true,
  breadcrumbs: [
    {
      title: 'Principal',
      route: 'default-dashboard'
    },
    {
      title: 'Pacientes'
    }
  ]
};


const PatientsStudent = () => {
  usePageData(pageData);

  const location = useLocation();
  const studentInfo = location.state as IStudent;
  const idStudent = studentInfo?.id || 0;

  GetStudents();
  const [patients, SetPatients] = LoadPatients(idStudent);
  const [searchValue, setSearchValue] = useState('');

  var filterList: IPatient[];
  if (searchValue === "") filterList = patients;
  else filterList = patients.filter(o => o.name.toLowerCase().includes(searchValue.toLowerCase()) || (o.lastname && o.lastname.toLowerCase().includes(searchValue.toLowerCase())));

  pageData.OnChangeSearch = (res) => {
    setSearchValue(res);
  }


  const OnEditPatient = (patient: IPatient) => {
    UpdateAndSavePatient(patient, true, (result) => {
      if (result > 0) {
        const editedPatients = patients.map((el) => el.id !== patient.id ? el : patient);
        SetPatients(editedPatients);

        OpenNotification('¡Actualizado!', "Se actualizó el paciente");
      } else {
        OpenNotification('¡Error!', "Ocurrió un error al actualizar", 'error');
      }
    });
  }

  const [_] = LoadAppointmentsV2();

  return (
    <>
      <PatientsStudentTable
        onEditPatient={OnEditPatient}
        patients={filterList}
      />
    </>
  );
};

export default PatientsStudent;
