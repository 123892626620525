import { useFetchRequestData } from "../APPValDiscapacidad/Model/DispatchRequest";
import { IResultRequest } from "../interfaces/Entities";
import { IExpediente } from "../interfaces/expediente";
import { ETypeUser } from "../interfaces/user";
import { getRowById, iDBStore, setRows } from "./DB";
import { USER_INFO, _API } from "./General";
import { mapPatients } from "./Patient";
import { RequestData } from "./RequestData";
import { mapUsers } from "./User";



export function LoadExpediente(idPaciente: number, completion: (resulData: IExpediente, success: number) => void) {
    let urlReq = `${_API}expediente/GetClientExpediente?idPaciente=${idPaciente}`;

    new RequestData(urlReq)
        .SetCallback(response => {
            let resulData: IExpediente = null;
            let success = response && response.success ? response.success : -100;

            if (response && response.data) {
                resulData = response.data;
                resulData.questionMain = JSON.parse(response.data.questionMain);
                resulData.questionPage1 = JSON.parse(response.data.questionPage1);
                resulData.questionPage2 = JSON.parse(response.data.questionPage2);
                resulData.svgDrawPain = response.data.svgDrawPain || "";
                resulData.ISNCSCI = JSON.parse(response.data.ISNCSCI);
            }

            completion(resulData, success);
        }).Request();
}

export function SetExpedienteInfo(expInfo: IExpediente, completion: (res: number) => void) {
    let urlReq = `${_API}expediente/SetClientExpediente`;
    expInfo.questionMain = expInfo.questionMain || [];
    expInfo.questionPage1 = expInfo.questionPage1 || [];
    expInfo.questionPage2 = expInfo.questionPage2 || [];
    expInfo.ISNCSCI = expInfo.ISNCSCI || [];

    const params = {
        idPatient: expInfo.idPatient,
        questionMain: JSON.stringify(expInfo.questionMain),
        questionPage1: JSON.stringify(expInfo.questionPage1),
        questionPage2: JSON.stringify(expInfo.questionPage2),
        svgDrawPain: expInfo.svgDrawPain || "",
        ISNCSCI: JSON.stringify(expInfo.ISNCSCI),
        idUser: USER_INFO.id
    }

    if (expInfo.id)
        params['id'] = expInfo.id;

    new RequestData(urlReq, 'POST')
        .SetParams(params)
        .SetCallback(response => {
            let resulData: number = response ? response.success : -100;
            completion(resulData);
        }).Request();
}

export function LoadExpedientesByPacient(idPaciente: number, completion: (resulData: IExpediente[], success: number) => void) {
    let urlReq = `${_API}expediente/GetClientExpedientes?idPaciente=${idPaciente}`;

    new RequestData(urlReq)
        .SetCallback(response => {
            let resulData: IExpediente[] = [];
            let success = response && response.success ? response.success : -100;

            if (response && response.data) {
                response.data.forEach((item: any) => {
                    const objData = item;
                    objData.idPatient = idPaciente;
                    objData.questionMain = JSON.parse(item.questionMain);
                    objData.questionPage1 = JSON.parse(item.questionPage1);
                    objData.questionPage2 = JSON.parse(item.questionPage2);
                    objData.svgDrawPain = item.svgDrawPain || "";
                    objData.ISNCSCI = JSON.parse(item.ISNCSCI);


                    resulData.push(objData);
                });
            }

            completion(resulData, success);
        }).Request();
}

const mapExpientes: Map<number, IExpediente> = new Map();

export function LoadExpedientes(param?: { idStudent?: number, idPatient?: number, idValoracion?: number }): [Array<IExpediente>, (data: Array<IExpediente>) => void] {
    param = param || {};
    let students = Array.from(mapExpientes.values());
    students.sort((a, b) => new Date(b.UpdatedAt).getTime() - new Date(a.UpdatedAt).getTime());

    let maxUpdated = null;
    if (students.length > 0) maxUpdated = students[0].UpdatedAt;

    let urlReq = `${_API}expediente/GetFormExpedientes?`;
    if (USER_INFO.typeUser == ETypeUser.STUDENT) urlReq += `idStudent=${USER_INFO.id}&`;
    urlReq += `lastUpdated=${maxUpdated}`;

    const [_, SetData] = useFetchRequestData<IResultRequest<IExpediente[]>>(urlReq, { data: students as any, success: 1, message: null }, (reqResult) => {
        if (reqResult && reqResult.success > 0 && reqResult.data.length > 0) {
            ValidAndSetExpediente(reqResult.data);
        }
    });

    //Corregir esto
    //Validar el primero si tiene informacion del paciente
    if (!mapExpientes.values().next().value?._patient) ValidAndSetExpediente([...mapExpientes.values()]);


    const fnc = (resDta: Array<IExpediente>): void => {
        SetData({ success: 1, message: "", data: resDta });
    }

    let listResult = Array.from(mapExpientes.values());
    if (param.idStudent) listResult = listResult.filter(o => o.CreatedBy == param.idStudent);

    //Obtener lista por paciente
    if (param.idPatient) listResult = listResult.filter(o => o.idPatient == param.idPatient);

    //Obtener expediente por formulario & es solo uno por appointment
    if (param.idValoracion) listResult = listResult.filter(o => o.idValoracion == param.idValoracion);

    return [listResult, fnc];
}

export const ValidAndSetExpediente = (data: IExpediente[]) => {
    data.forEach(item => {
        if (!item.InUse)
            mapExpientes.delete(item.id)
        else {
            const objData = item;
            if (typeof item.questionMain == 'string') {
                objData.questionMain = JSON.parse(item.questionMain as any);
                objData.questionPage1 = JSON.parse(item.questionPage1 as any);
                objData.questionPage2 = JSON.parse(item.questionPage2 as any);
                objData.svgDrawPain = item.svgDrawPain || "";
                objData.ISNCSCI = JSON.parse(item.ISNCSCI as any);
            }

            if (!objData.questionMain) objData.questionMain = [];
            if (!objData.questionPage1) objData.questionPage1 = [];
            if (!objData.questionPage2) objData.questionPage2 = [];
            if (!objData.svgDrawPain) item.svgDrawPain = "";
            if (!objData.ISNCSCI) objData.ISNCSCI = [];

            objData._patient = mapPatients.get(item.idPatient);
            objData._user = mapUsers.get(item.CreatedBy);

            mapExpientes.set(objData.id, objData);
        }
    });
}

export function RemoveExpediente(idExp: number, completion: (result: number) => void) {
    let params = {
        idUser: USER_INFO.id,
        id: idExp
    }

    let urlReq = `${_API}expediente/RemoveExpediente`;
    new RequestData(urlReq, 'POST')
        .SetParams(params)
        .SetCallback(response => {
            let success = response ? response.data : -100;
            completion(success);
        }).Request();
}

export function GetExpedienteByIdFormulario(idForm: number): IExpediente {
    let listResult = Array.from(mapExpientes.values());
    listResult = listResult.filter(o => o.idValoracion == idForm);
    return listResult.length > 0 ? listResult[0] : null;
}


/**
 * Manage DB.
 * Expediente
 */

export var _expStore: iDBStore = {
    name: "expediente",
    key: "id",
    fieldKeys: []
}
const _ID_CURRENT_ROW = 777;

export async function SetCurrentExpediente(exp: IExpediente) {
    const valTemp: { id: number, exp: IExpediente } = { id: _ID_CURRENT_ROW, exp: exp };
    setRows(_expStore.name, [valTemp]);
}

export async function GetCurrentExpediente(): Promise<any> {
    return getRowById(_expStore.name, _ID_CURRENT_ROW);
}