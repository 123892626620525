import ExpedienteNeurologia from "../ExpedienteNeurologia";

import './valoracion.scss';

const ValoracionMedular = () => {
    return (
        <div className='valoracion-medular'>
            <ExpedienteNeurologia isPrintable={true} />
        </div>
    );
};

export default ValoracionMedular;