import { IMethod } from "./Entities";

export class RequestData {

    private params: any;
    private url: string;
    private method: keyof IMethod;
    private callback: (resData: any, error: Error) => void;
    private hasContentType: boolean = false;

    constructor(url: string, method: keyof IMethod = 'GET') {
        this.url = url;
        this.method = method;
    }

    public SetParams(params: any): RequestData {
        this.params = params || {};
        return this;
    }

    public SetUrl(url: string): RequestData {
        this.url = url;
        return this;
    }

    public SetMethod(method: keyof IMethod): RequestData {
        this.method = method;
        return this;
    }

    public SetHasContentType(hasContentType: boolean): RequestData {
        this.hasContentType = hasContentType;
        return this;
    }

    public SetCallback(callback: (resData: any, error: Error) => void): RequestData {
        this.callback = callback;
        return this;
    }

    /**
     * Ejecutar la petición de forma asyncrona
     */
    public Request(): void {
        if (!this.url) {
            console.error("No se configuro la URL del servicio");
            return;
        }

        let request = this.SetHeader();

        fetch(request)
            .then((response: any) => {
                if (!response.ok) {
                    if (this.callback) {
                        let error = { name: "consulta", message: "(" + response.status + ") " + response.statusText };
                        this.callback(null, error);
                    }
                }
                else {
                    return response.json();
                }
            }).then(result => {
                if (result && this.callback) {
                    this.callback(result, null);
                }
            }).catch(e => {
                if (this.callback) {
                    this.callback(null, e);
                }

                //@ts-ignore
                if (typeof window !== 'undefined' && window.webkit) { // para dispositivos iOS imprimir siempre el error
                    console.error({ stack: e.stack, message: e.message, name: e.name, requestURL: this.url });
                } else if (e.stack !== "TypeError: Failed to fetch") {
                    console.error(e.stack);
                }

            });
    }

    private SetHeader(): Request {
        if (this.method === 'GET' && this.params)
            this.url += this.ParamsGET();

        let headers = {} as any;
        if (this.hasContentType)
            headers['Content-type'] = "application/json; charset=UTF-8";

        return new Request(this.url, {
            method: this.method,
            body: this.method === "POST" ? JSON.stringify(this.params) : null,
            cache: "no-cache",
            headers: headers
        });
    }

    private ParamsGET(): string {
        var concatStr = "?";
        var i = 0;
        for (var k in this.params) {
            if (i > 0) concatStr += "&";
            concatStr += k + "=" + this.params[k];
            i++;
        }

        return encodeURI(concatStr);
    }




}