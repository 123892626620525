import { useState } from 'react';

import { Input } from 'antd';

import './Search.scss';

const Search = ({ OnSearchResult }: { OnSearchResult?: (value: string) => void }) => {
  const [text, setText] = useState('');

  const OnTextChange = (value) => {
    setText(value.target.value);
    if (OnSearchResult) OnSearchResult(value.target.value);
  }

  return (
    <Input
      value={text}
      className='app-search'
      onChange={OnTextChange}
      placeholder='Buscar . . .'
      suffix={<span className='icofont icofont-search' />} />
  );
};

export default Search;
