import { Button, Popover } from "antd";

import './BtnChooseStyle.scss';
import { useState } from "react";

export enum EChooseNumNeuro {
    FIRST = 1,
    ALL = 2
}

export type EInputValue = {
    option: EChooseNumNeuro,
    value?: any
}

const BtnChooseOptionNeuro: React.FC<{ btnOpts: EInputValue, nInput: number, identifier: number, OnChooseBtn: (opt: EInputValue, nInput: number, identifier: number) => void, disabled: boolean }> = ({
    btnOpts,
    nInput,
    identifier,
    OnChooseBtn,
    disabled
}: { btnOpts: EInputValue, nInput: number, identifier: number, OnChooseBtn: (opt: EInputValue, nInput: number, identifier: number) => void, disabled: boolean }) => {
    if (disabled) return <Button>{btnOpts.value}</Button>

    const [isSharePopoverVisible, setIsSharePopoverVisible] = useState(false);
    const handlePopoverVisibleChange = () => {
        setIsSharePopoverVisible(!isSharePopoverVisible);
    };

    const hide = (opt: any) => {
        btnOpts.value = opt;
        OnChooseBtn(btnOpts, nInput, identifier);
        setIsSharePopoverVisible(false);
    };

    return (
        <Popover
            content={
                <>
                    <div className="dv-opt-neuro"><a onClick={() => hide("0")}>0</a><a onClick={() => hide("0*")}>0*</a></div>
                    <div className="dv-opt-neuro"><a onClick={() => hide("1")}>1</a><a onClick={() => hide("1*")}>1*</a></div>
                    <div className="dv-opt-neuro"><a onClick={() => hide("2")}>2</a>{btnOpts.option == EChooseNumNeuro.ALL && <a onClick={() => hide("2*")}>2*</a>}</div>
                    {btnOpts.option == EChooseNumNeuro.ALL && <div className="dv-opt-neuro"><a onClick={() => hide("3")}>3</a><a onClick={() => hide("3*")}>3*</a></div>}
                    {btnOpts.option == EChooseNumNeuro.ALL && <div className="dv-opt-neuro"><a onClick={() => hide("4")}>4</a><a onClick={() => hide("4*")}>4*</a></div>}
                    {btnOpts.option == EChooseNumNeuro.ALL && <div className="dv-opt-neuro"><a onClick={() => hide("5")}>5</a></div>}
                    <div className="dv-opt-neuro"><a onClick={() => hide("NT")}>NT</a><a onClick={() => hide("NT*")}>NT*</a></div>
                </>
            }
            title="Choose option"
            trigger="click"
            open={isSharePopoverVisible}
            onOpenChange={handlePopoverVisibleChange}
        >
            <Button>{btnOpts.value}</Button>
        </Popover>
    );
};

export default BtnChooseOptionNeuro;