import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { DismissLoading, ShowLoading } from "../../hooks/useHideLoader";
import PageAction from "../../layout/components/page-action/PageAction";
import { IPatient } from "../../interfaces/patient";
import { usePageData } from "../../hooks/usePage";
import { IPageData } from "../../interfaces/page";
import UserPreviewHeader from "./ExpedienteHeaderPatient";
import { SetScrollToTop } from "./ExpUtils";
import OpenNotification from "../Toast";
import { SetExpedienteInfo } from "../../data/Expediente";
import ExpedienteNeurologia from "./ExpedienteNeurologia";
import { IExpediente } from "../../interfaces/expediente";
import { HistoryRedirect } from "../../data/General";

const pageData: IPageData = {
    title: 'Expediente',
    fulFilled: true,
    hasBack: true,
    breadcrumbs: [
        {
            title: 'Principal',
            route: 'default-dashboard'
        },
        // {
        //     title: 'Historial',
        //     route: 'historial'
        // },
        {
            title: 'Expediente médico 3'
        }
    ]
};

// var _expedienteInfo: IExpediente;

const UserPreview = ({
    patient
}: { patient: IPatient }) => {
    usePageData(pageData);


    return (
        <>
            <UserPreviewHeader patient={patient} enableBtnAdd={false} />
        </>
    )
};

const PostExpedNeurologia = () => {
    const history = useNavigate();
    const location = useLocation();
    const tempInfo = location.state;
    const patient = tempInfo?.patient ?? null;
    const expedienteInfo = tempInfo?.expediente ?? null;;

    const [_scrollStatus, setScrollStatus] = useState(false);

    const urlRedirect = HistoryRedirect();

    if (!patient || (Object.keys(patient).length === 0 && patient.constructor === Object)) {
        history(urlRedirect);
        return null;
    }

    const { pathname } = useLocation();

    useEffect(() => SetScrollToTop(), [pathname])
    pageData.OnScroll = (isTop: boolean) => setScrollStatus(isTop);

    const OnNextPage = (expInf: IExpediente) => {
        const _totalRghMotor = document.getElementById("RightMotorTotal");//Validar si se ha calculado.
        if (!expInf.ISNCSCI || !_totalRghMotor.innerText) {
            OpenNotification("Información incompleta", "Calcular datos ISNCSCI", 'warn')
            return;
        }

        ShowLoading();
        SetExpedienteInfo(expInf, (result) => {
            DismissLoading();

            if (result > 0) {
                expInf.id = result;
                history(urlRedirect);
            } else {
                OpenNotification('¡Error de actualización!', "Ocurrió un error al actualizar el expediente", 'error');
            }

        });
    }

    return (
        <div className='neurologia-content'>
            <UserPreview patient={patient} />

            <ExpedienteNeurologia _expediente={expedienteInfo} OnSave={OnNextPage} isPrintable={true}></ExpedienteNeurologia>

            {_scrollStatus && <PageAction onClick={SetScrollToTop} icon='icofont-simple-up' type={'primary'} key="btn-up-scroll" />}
        </div>
    )
}

export default PostExpedNeurologia;