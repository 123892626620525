import {
  ADD_PATIENT,
  AddPatientAction,
  DELETE_PATIENT,
  DeletePatientAction,
  EDIT_PATIENT,
  EditPatientAction,
  SET_PATIENTS,
  SetPatientAction
} from './types';

import { IPatient } from '../../interfaces/patient';
import axios from 'axios';
import { _API } from '../../data/General';

const mapPatients: Map<number, IPatient> = new Map();

export const setPatients = (patients: IPatient[]): SetPatientAction => ({
  type: SET_PATIENTS,
  payload: patients
});

export const addPatient = (patient: IPatient): AddPatientAction => ({
  type: ADD_PATIENT,
  payload: patient
});

export const deletePatient = (id: number): DeletePatientAction => ({
  type: DELETE_PATIENT,
  id
});

export const editPatient = (patient: IPatient): EditPatientAction => ({
  type: EDIT_PATIENT,
  payload: patient
});

export const fetchPatients = (): any => {
  return dispatch => {
    let _pts = Array.from(mapPatients.values());
    _pts.sort((a, b) => new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime());


    const patientsUrl = _API + 'patient/GetPatients?lastUpdated=null';

    axios
      .get<{ success: number, data: IPatient[] }>(patientsUrl)
      .then(res => res.data)
      .then((data) => {
        if (data.success == 1) {
          ValidAndSetPatients(data.data);

          dispatch(setPatients(Array.from(mapPatients.values())));
        }
      })
      .catch(_ => console.error('Server connections error'));
  };
};

const ValidAndSetPatients = (data: IPatient[]) => {
  data.forEach(item => {
    if (!item.inUse)
      mapPatients.delete(item.id)
    else {
      mapPatients.set(item.id, item);
    }
  });
}
