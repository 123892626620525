import { notification } from "antd";
import { ArgsProps } from "antd/es/notification/interface";

export const OpenNotification = (title: string, body: string, type: 'success' | 'warn' | 'error' = 'success') => {
    let configNtf: ArgsProps = {
        message: title,
        description: body,
        duration: 3,
        placement: 'bottomRight'
    }

    if (type === 'success')
        notification.success(configNtf);
    else if (type === 'warn')
        notification.warning(configNtf);
    else if (type === 'error')
        notification.error(configNtf)
    else
        notification.open(configNtf);
};

export default OpenNotification;