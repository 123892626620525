import { useState } from 'react';

import PageAction from '../../../layout/components/page-action/PageAction';

import { usePageData } from '../../../hooks/usePage';

import { IPageData } from '../../../interfaces/page';
import { useLocation, useNavigate } from 'react-router-dom';
import { IStudent } from '../../interfaces/Student';
import { GetStudents } from '../../Model/Student';
import StudentsTable from './StudentsTable';
import { LoadPatients } from '../../../data/Patient';

const pageData: IPageData = {
  title: 'Alumnos',
  fulFilled: false,
  // OnChangeSearch: OnSearchResult,
  breadcrumbs: [
    {
      title: 'Principal',
      route: 'home'
    },
    {
      title: 'Alumnos'
    }
  ]
};


const StudentsPage = () => {
  usePageData(pageData);
  const history = useNavigate();
  const location = useLocation();/// New v6

  const [users] = GetStudents();
  LoadPatients();
  const [searchValue, setSearchValue] = useState('');

  var filterList: IStudent[];
  if (searchValue === "") filterList = users;
  else filterList = users.filter(o => o.name.toLowerCase().includes(searchValue.toLowerCase())
    || (o.lastname && o.lastname.toLowerCase().includes(searchValue.toLowerCase()))
    || (o.matricula.toString().includes(searchValue)));

  pageData.OnChangeSearch = (res) => {
    setSearchValue(res);
  }

  const layout = location.pathname.split('/')[1];
  const OnAddNew = () => history(`/${layout}/edit-student`);


  const handleGoToProfile = (student: IStudent) => {
    const layout = location.pathname.split('/')[1];
    history(`/${layout}/user-profile`, { state: student });
  };

  const handleGoToEdit = (student: IStudent) => {
    const layout = location.pathname.split('/')[1];
    history(`/${layout}/edit-student`, { state: student });
  }

  const handleGoPatients = (student: IStudent) => {
    const layout = location.pathname.split('/')[1];
    history(`/${layout}/patients`, { state: student });
  }

  const handleGoFormularios = (student: IStudent) => {
    const layout = location.pathname.split('/')[1];
    history(`/${layout}/formularios`, { state: student });
  }

  return (
    <>
      <StudentsTable
        onViewProfile={handleGoToProfile}
        onEditUser={handleGoToEdit}
        onViewPatients={handleGoPatients}
        onViewForms={handleGoFormularios}
        users={filterList} />

      <PageAction onClick={OnAddNew} icon='icofont-contact-add' />

    </>
  );
};

export default StudentsPage;
