
import { Button, Card, Form, Input, Modal } from 'antd';

import { useFormik } from 'formik';

import { IPageData } from '../../interfaces/page';

import { usePageData } from '../../hooks/usePage';

import AppointmentByPatientTable from './AppointmentByPatientTable';
import { useNavigate, useLocation } from 'react-router-dom';
import { IPatient } from '../../interfaces/patient';
import { AppointmentByPatient } from '../../data/Appointment';
import { RemovePatientData } from '../../data/Patient';
import OpenNotification from '../Toast';
import { createContext } from 'react';
import { DismissLoading, ShowLoading } from '../../hooks/useHideLoader';

const pageData: IPageData = {
  title: 'Información del paciente',
  fulFilled: true,
  hasBack: true,
  breadcrumbs: [
    {
      title: 'Principal',
      route: 'default-dashboard'
    },
    {
      title: 'Pacientes',
      route: 'patients'
    },
    {
      title: 'Información del paciente'
    }
  ]
};

const FormItem = Form.Item;


const ProfileForm = ({ patient }: { patient: IPatient }) => {
  const { values } = useFormik({
    initialValues: { ...patient },
    onSubmit: () => null
  });

  return (
    <Form layout='vertical'>
      <div className='row'>
        <div className='col-md-4 col-sm-12'>
          <FormItem label='Nombre completo'>
            <Input defaultValue={values.name + ' ' + (values.lastname ?? '')} placeholder='Nombre completo' readOnly />
          </FormItem>
        </div>
        <div className='col-md-4 col-sm-12'>
          <FormItem label='Correo electrónico'>
            <Input defaultValue={values.email} placeholder='Correo electrónico' readOnly />
          </FormItem>
        </div>
        <div className='col-md-4 col-sm-12'>
          <FormItem label='Teléfono'>
            <Input defaultValue={values.phone} placeholder='Teléfono' readOnly />
          </FormItem>
        </div>
      </div>

      <div className='row'>
        <div className='col-md-4 col-sm-12'>
          <FormItem label='Fecha de nacimiento | Edad'>
            <Input defaultValue={values.birthDate + ' (' + values.age + ' Años)'} placeholder='Age' readOnly />
          </FormItem>
        </div>
        <div className='col-md-4 col-sm-12'>
          <FormItem label='Genero'>
            <Input defaultValue={values.gender === 1 ? 'Masculino' : (values.gender == 0 ? 'Femenino' : null)} placeholder='Genero' readOnly />
          </FormItem>
        </div>
        <div className='col-md-4 col-sm-12'>
          <FormItem label='Ultima actualización'>
            <Input defaultValue={values.updatedAt} placeholder='Ultima actualización' readOnly />
          </FormItem>
        </div>
      </div>

      {values.description && <div className='row'>
        <div className='col-md-6 col-sm-12'>
          <FormItem label='Descripción'>
            <Input.TextArea rows={4} defaultValue={values.description} placeholder='Descripción' readOnly />
          </FormItem>
        </div>
      </div>}

    </Form >
  );
};

const PatientProfilePage = () => {
  usePageData(pageData);

  const location = useLocation();
  const patient = location.state as IPatient;
  const history = useNavigate();

  if (!patient)
    return (<></>);

  const appointments = AppointmentByPatient(patient.id);

  //Remove patient..
  const OnDeletePatient = async () => {
    const confirmed = await modal.confirm(config);
    if (confirmed) {
      ShowLoading();

      RemovePatientData(patient.id, (result) => {
        DismissLoading();

        if (result > 0) {
          OpenNotification('¡Eliminado!', "Se eliminó el paciente");
          history(-1);
        } else {
          OpenNotification('¡Error!', "Ocurrió un error al eliminar", 'error');
        }
      });
    }
  }

  //Modal config
  const [modal, contextHolder] = Modal.useModal();
  const ReachableContext = createContext<IPatient | null>(null);

  const config = {
    title: 'Eliminar paciente!',
    content: (
      <>
        <ReachableContext.Consumer>{(obj) => `Deseas eliminar el paciente: ${obj.name + ' ' + (obj.lastname ? obj.lastname : '')} ?`}</ReachableContext.Consumer>
      </>
    ),
  };

  return (
    patient && (
      <ReachableContext.Provider value={patient}>
        <div className='row mb-4'>
          <div className='col-md-2 col-sm-0'></div>

          <div className='col-md-12 col-sm-12'>
            <div className='header mb-3'>
              {/* <h6 className='mt-0 mb-3'>Photo</h6>
              <Avatar src={patient.img as string} size={100} className='mr-4' /> */}
            </div>

            <div className='info stack'>
              <ProfileForm patient={patient} />
              {/* <Button type='primary'>Save Changes</Button> */}
            </div>
          </div>
        </div>

        <Card title='Historial de citas' className='mb-0'>
          <AppointmentByPatientTable appointments={appointments} />
        </Card>

        <div className='elem-list justify-content-between' style={{ 'marginTop': "15px" }}>
          <Button ghost danger className='ml-auto' onClick={OnDeletePatient}>
            Eliminar paciente
          </Button>

          {contextHolder}
        </div>
      </ReachableContext.Provider>
    )
  );
};

export default PatientProfilePage;
